import styled from "@emotion/styled";

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 0;
`;

export default Video;
