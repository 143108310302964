import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem, hexToRgba } from "@/lib/functions";

const IconWrapper = styled.div`
  position: relative;
  max-width: ${pxToRem(150)};

  svg {
    width: 100%;
    fill: ${({ circle }) => (circle ? "#038daf" : "#7dc4d6")};
  }

  ${({ circle, columns }) =>
    circle &&
    css`
      max-width: inherit;
      margin-bottom: ${pxToRem(20)};
      width: ${columns > 3 ? 80 : 60}%;
      padding-bottom: ${columns > 3 ? 80 : 60}%;
      padding-left: 40px;
      padding-right: 40px;
      background-color: ${hexToRgba("#038daf", 0.1)};
      border-radius: 50%;

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100%;
        padding: 30%;
        fill: ${circle ? "#038daf" : "#7dc4d6"};
      }
    `}
`;

export default IconWrapper;
