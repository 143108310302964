import styled from "@emotion/styled";
import theme from "@/styles";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";
import NavLink from "./NavLink";

const NavItem = styled.li`
  width: 100%;
  text-align: left;
  padding: 20px 0;
  border-bottom: 1px solid #efefef;

  ${({ last }) =>
    last &&
    css`
      border-bottom: 0;
    `}

    ${({ open }) =>
    open &&
    css`
        border-bottom: 4px solid ${theme.colors.primary};

        ${NavLink} {
          color: ${theme.colors.primary};
          opacity: 1;
        }
      `}

  ${mq.lg(css`
    width: auto;
    padding: .7rem 2rem;
    text-align: center;
    border-bottom: none;
  `)}
`;

export default NavItem;
