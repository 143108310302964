import React from "react";
import TechnologyWrapper from "./components/TechnologyWrapper";
import LazyImage from "../../../LazyImage/LazyImage";
import { P } from "../../../GlobalStyles/GlobalStyles";
import TechnologyInnerWrapper from "./components/TechnologyInnerWrapper";
import ImageWrapper from "./components/ImageWrapper";
import Link from 'next/link';

const Technology = ({
  image,
  bodytext,
  name,
  shadow,
  square,
  filter,
  resetMargin,
  imagelink,
  style
}) => {
  return (
    <TechnologyWrapper
      shadow={shadow}
      useFilter={filter ? filter : undefined}
      resetMargin={resetMargin}
      style={style}
      square={square}
    >
      {square ? (
        <>
          {imagelink && imagelink.imagelink ? (
            <Link href={imagelink.imagelink}>
              <a target="_blank">
                <TechnologyInnerWrapper>
                  <LazyImage isSVG autoSize={true} lazy={true} image={image} />
                  {name && <P>{name}</P>}
                  {bodytext && <P>{bodytext}</P>}
                </TechnologyInnerWrapper>
              </a>
            </Link>
              ) : (
                <TechnologyInnerWrapper>
                  <LazyImage isSVG autoSize={true} lazy={true} image={image} />
                  {name && <P>{name}</P>}
                  {bodytext && <P>{bodytext}</P>}
              </TechnologyInnerWrapper>
              )}
        </>
      ) : (
        <>
          <ImageWrapper>
          {imagelink && imagelink.imagelink ? (
            <Link href={imagelink.imagelink}>
              <a target="_blank">
                <LazyImage
                  isSVG
                  resetKey={image}
                  autoSize={true}
                  lazy={true}
                  image={image}
                />
              </a>
            </Link>
            ) : (
              <LazyImage
                isSVG
                resetKey={image}
                autoSize={true}
                lazy={true}
                image={image}
              />
            )}
          </ImageWrapper>
          {name && <P>{name}</P>}
          {bodytext && <P>{bodytext}</P>}
        </>
      )}
    </TechnologyWrapper>
  );
};

Technology.defaultProps = {
  filter: true,
  imageSquare: true
};

export default Technology;
