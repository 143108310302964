import styled from "@emotion/styled";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const Pointer = styled.span`
  &:before,
  &:after {
    transform: translateX(-50%) scale(${({ active }) => (active ? 1 : 0)});
  }
  &:before {
    transition: transform 0.3s ease .2s;
    transform-origin: center bottom;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f3f3f3;
    border-top: 10px solid transparent;
    bottom: 0;
    content: "";
    height: 0;
    position: absolute;
    width: 0;
    z-index: 4;
    left: 50%;
    bottom: calc(-${pxToRem(25)} - 8px);
  }

  &:after {
    transition: transform 0.3s ease .2s;
    transform-origin: center bottom;
    position: absolute;
    content: "";
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${theme.colors.primary};
    border-top: 12px solid transparent;
    z-index: 3;
    bottom: 0;
    left: 50%;
    bottom: calc(-${pxToRem(25)} - 4px);
  }
`;

export default Pointer;
