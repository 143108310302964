import styled from "@emotion/styled";

const TextWrapper = styled.div`
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: left;
`;

export default TextWrapper;
