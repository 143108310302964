import React from "react";
import SVG from "react-inlinesvg";
import SocialLinksWrapper from "./components/SocialLinksWrapper";
import IconWrapper from "./components/IconWrapper";
import Section from "../Section/Section";
import { P } from "../GlobalStyles/GlobalStyles";

const SocialLinks = ({ items, header }) => {
  return (
    <Section>
      <SocialLinksWrapper>
        <P as="div">{header}</P>
        <div style={{ display: "flex" }}>
          {items &&
            items.map((item, index) => {
              return (
                <IconWrapper target="_blank" rel="noopener noreferrer" href={item.link} title={item.icon} key={index}>
                  <SVG src={`/static/svg/${item.icon}.svg`} />
                </IconWrapper>
              );
            })}
        </div>
      </SocialLinksWrapper>
    </Section>
  );
};

export default SocialLinks;
