import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import withSection from "../Section/withSection";
import { P, Subheadline } from "../GlobalStyles/GlobalStyles";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import LazyImage from "../LazyImage/LazyImage";
import { VideoWrapper } from "./Textpic";

const TextpicWrapper = styled.div`
  display: block;

  ${P} {
    margin-bottom: 20px;
  }

  ${({ imageorient, imagecols }) =>
    [17, 25, 18, 26].indexOf(imageorient) > -1 &&
    imagecols > 1 &&
    css`
      display: flex;
      flex-wrap: wrap;

      ${mq.xl(css`
        display: grid;
        grid-template-areas: none;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;

        ${TextWrapper} {
          padding: 0 ${pxToRem(40)};
        }
      `)}

      ${[17, 25].indexOf(imageorient) > -1 &&
      css`
          ${TextWrapper} {
            grid-column: 1 / ${imagecols};
            grid-row: 1 / 1;
            padding-left: 0;
          }

          ${ImageWrapper}, ${VideoWrapper} {
            grid-column: ${imagecols} / 13;
            grid-row: 1 / 1;
            /* padding-left: ${pxToRem(20)}; */
          }
        `}

      ${[18, 26].indexOf(imageorient) > -1 &&
      css`
          ${TextWrapper} {
            grid-column: ${imagecols} / 13;
            grid-row: 1 / 1;
          }

          ${ImageWrapper}, ${VideoWrapper} {
            grid-column: 1 / ${imagecols};
            grid-row: 1 / 1;
          }
        `}
    `}
`;

const ImageWrapper = styled.div`
  text-align: center;
  ${({ imageorient }) =>
    imageorient < 3 &&
    css`
      grid-area: imageTop;
      margin-bottom: ${pxToRem(20)};
    `}

  ${({ imageorient }) =>
    imageorient >= 8 &&
    imageorient <= 10 &&
    css`
      grid-area: imageBottom;
      margin-top: ${pxToRem(20)};
    `}
`;

const TextWrapper = styled.div`
  grid-area: textArea;
`;



const Text = ({
  colPos,
  assets,
  header,
  subheader,
  image,
  imageorient,
  imagecols,
  bodytext,
  header_position,
  header_layout,
  layout
}) => {
  const CustomHeadline = `h${header_layout == 0 ? 2 : header_layout}`;

  return (
    <TextpicWrapper
      imageorient={imageorient}
      imagecols={imagecols}
      layout={layout}
    >
      {(image !== 0 || assets !== 0) && (
        <ImageWrapper imageorient={imageorient}>
          <LazyImage image={image[0] || assets[0]} />
        </ImageWrapper>
      )}
      <TextWrapper>
      {subheader && (
      <Subheadline
            as="div"
            bold
            style={{ marginBottom:"0", textTransform: "uppercase" }}
        >
            {subheader}
        </Subheadline>
      )}
        <CustomHeadline
          dangerouslySetInnerHTML={{ __html: header }}
          style={{ textAlign: header_position || "center" }}
        />
        <P as="div" dangerouslySetInnerHTML={{ __html: bodytext }} />
      </TextWrapper>
    </TextpicWrapper>
  );
};

export default withSection(Text);
