import styled from "@emotion/styled";

type PanelProps = {
  status?: Boolean
  opened?: Boolean
  contentHeight?: number

}

const Panel = styled.div`
  max-height: 100%;
  transition: max-height 0.3s ease-in-out;

  ${({ status, opened, contentHeight }: PanelProps) => status && `max-height: ${opened ? contentHeight : 0}px; overflow: hidden;`}`;

export default Panel;
