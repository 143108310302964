import React, { useState, useEffect } from "react";
import theme from "@/styles";
import Section from "../Section/Section";
import { fetchData } from "@/lib/fetch";
import mq from "@/lib/mediaQueries";

import SVG from "react-inlinesvg";
import css from "@emotion/css";
import { pxToRem } from "@/lib/functions";
import Rating from "./components/Rating";
import Seal from "./components/Seal/Seal";

const Kununu = props => {
  const [kununuStars, setKununuStars] = useState(4.3);
  const [kununuCount, setKununuCount] = useState(160);

  useEffect(() => {
    fetchData({
      url: "/api/kununu",
      method: "GET",
    }).then((res) => {
      setKununuStars(res.stars);
      setKununuCount(res.count);
    }, (res) => {
      console.log("error fetch", res.message);
    });
  }, []);

  return (
    <Section
      backgroundColor="#f3f3f3"
      style={{ marginBottom: 0, display: "flex", flexDirection: "row" }}
      headerStyle={{ marginBottom: 10, color: theme.colors.primary }}
      {...props}
    >
      <div css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              ${mq.md(css`
                flex-direction: row;
              `)}
            `}>
        <div css={css`
              display: flex;
              flex-direction: column;
              ${mq.md(css`
                margin-right: 50px;
              `)}
            `}>
          <SVG
            css={css`
              width: ${pxToRem(170)};
              fill: ${theme.colors.secondary};
              margin: ${pxToRem(20)} auto 0 auto;
            `}
            src="/static/svg/logo-kununu-weiss.svg"
          />
          <Rating rating={kununuStars}></Rating>
          <div
            css={css`
              text-align: center;
            `}
          >
            <small
              style={{
                textAlign: "center",
                color: theme.colors.secondary,
                marginTop: -5
              }}
            >
              {kununuCount} Bewertungen
            </small>
          </div>
        </div>
        <div>
          <Seal
            file="top-kununu-logo.svg"></Seal>
        </div>
        
      </div>
    </Section>
  );
};

export default Kununu;
