import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ListItem from "./ListItem";

import mq from "@/lib/mediaQueries";


const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1rem;
  flex-wrap: wrap;

  ${mq.lg(css`
    padding: 0;
  `)}

  ${({ horizontal }) =>
    horizontal &&
    css`
      flex-direction: row;
      justify-content: center;
      padding: 0 1rem;

      ${ListItem} {
        padding: 0.5rem; 

        ${mq.lg(css`
          padding: 1rem;
        `)}
      }
    `}
`;

export default ListWrapper;
