import styled from "@emotion/styled";
import { css } from "@emotion/react";

type IconWrapperProps = {
  arrow?: Boolean
  arrowColor?: string
  opened?: Boolean
  plus?: Boolean
  plusOpacity?: number
  icon?: Boolean
}

const IconWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 30px;
  height: 38px;
  text-align: center;

  ${({ arrow }) =>
    arrow &&
    css`
      display: flex;
    `}

  svg {
    width: 20px;
    height: 20px;
    fill: black;
    ${({ arrowColor }) =>
      arrowColor &&
      css`
        fill: ${arrowColor};
      `}
    display: block;
    transform: rotateX(${({ opened }: IconWrapperProps) => (opened ? 180 : 0)}deg);
    transition: all 0.3s;
    flex-shrink: 0;
  }

  ${({ plus, plusOpacity }) =>
    plus &&
    css`
      display: flex;
      background: rgba(0, 0, 0, ${plusOpacity ? plusOpacity : 0.15});
    `}
`;

export default IconWrapper;
