import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import { between } from "polished";

const DownloadModuleContent = styled.div`

& .u-add-padding {
  padding:0;
}

div {
  font-size: ${between("18px", "20px")};
  word-break: break-all;
}



${mq.lg(css`
& .u-add-padding {
  padding:0 ${pxToRem(20)};
  &-right {
    padding:0 ${pxToRem(20)} 0 0;
  }
}

div {
  font-size:  ${pxToRem(24)};
  word-break: normal;
}

display:flex;
flex-direction:row;
  `)}
`;


export default DownloadModuleContent;