import styled from "@emotion/styled";
import theme from "@/styles";
import { H3 } from "../../GlobalStyles/GlobalStyles";

const Price = styled(H3)`
  font-weight: 800;
  color: ${theme.colors.primary};
`;

export default Price;
