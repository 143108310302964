import styled from "@emotion/styled";

const RatingWrapper = styled.div`
    text-align: center;
    margin: 1rem 1rem 0 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

export default RatingWrapper;