import React from "react";
import Section from "../Section/Section";
import { pxToRem } from "@/lib/functions";
import styled from "@emotion/styled";
import Button from "../Button/Button";
import { H3, P } from "../GlobalStyles/GlobalStyles";
import DownloadModuleType from "./components/DownloadModuleType"
import DownloadModuleWrapper from "./components/DownloadModuleWrapper"
import DownloadModuleContent from "./components/DownloadModuleContent"
import DownloadModuleInfoWrapper from "./components/DownloadModuleInfoWrapper"




const DownloadModule = ({ items, header, bodytext, button }) => {
    return (
        <Section as="div" className="u-reset-padding">
            {header && (
                <H3 as="h2">
                    {header}
                </H3>
            )}

            {bodytext && (
                <P as="div" style={{ textAlign: "center", color: "black" }} dangerouslySetInnerHTML={{ __html: bodytext }}></P>
            )}

            {items &&
                items.map((item, index) => {
                    return (

                        <DownloadModuleWrapper key={index}>
                            <DownloadModuleInfoWrapper>
                                <DownloadModuleType filetype={item.filetype}></DownloadModuleType>
                                <DownloadModuleContent>
                                    <div className="u-add-padding" style={{ fontWeight: "800",}}>{item.title}</div>
                                    <div className="u-add-padding-right">
                                        ({item.size})
                                    </div>
                                </DownloadModuleContent>
                            </DownloadModuleInfoWrapper>
                            <Button type="secondary" className="u-mobile-full-width u-nowrap" href={item.file} absoluteUrl={true} icon="download" hoverPosition="down" style={{ marginTop: "0" }} download={true}>
                                {button}
                            </Button>

                        </DownloadModuleWrapper>
                    );
                })}
        </Section>
    )
}
export default DownloadModule;