import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import TeaserWrapper from "./TeaserWrapper";
import ImageWrapper from "./ImageWrapper";

const TeaserOuterWrapper = styled.section`
  position: relative;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ horizontal }) =>
    !horizontal &&
    css`
      margin: 20px;

      ${mq.lg(css`
        margin: 0;
      `)}
    `}

  ${({ horizontal, reverse }) =>
    horizontal &&
    css`
      flex-direction: column;

      ${TeaserWrapper} {
        box-shadow: none;
      }

      ${mq.xl(css`
        flex-direction: ${reverse ? "row-reverse" : "row"};
        margin: ${pxToRem(60)} 0;

        ${TeaserWrapper} {
          box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
        }
      `)}
    `}

  ${({ reverse }) =>
    reverse &&
    css`
      ${mq.xl(css`
        ${ImageWrapper} {
          width: 60%;
        }
      `)}
    `}
`;

export default TeaserOuterWrapper;
