import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rgba } from "polished";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import theme from "@/styles";

const IconWrapper = styled.div`
  position: absolute;
  width: ${pxToRem(100)};
  height: ${pxToRem(80)};
  bottom: -${pxToRem(45)};
  right: ${pxToRem(100)};
  mask-image: url(${({ icon }) => `/static/svg/${icon}.svg`});
  mask-repeat: no-repeat;
  mask-position: 100% 10%;
  background: linear-gradient(
      ${({ iconColor }) => rgba(theme.colors[iconColor], 1)},
      ${({ iconColor }) => theme.colors[iconColor]}
    ),
    url(${({ iconBackground }) => iconBackground});
  background-size: cover;
  
  ${mq.sm(css`
    bottom: ${pxToRem(0)};
  `)}

  ${mq.lg(css`
    bottom: 0;
    width: ${pxToRem(150)};
    height: ${pxToRem(120)};
    left: -${pxToRem(50)};
  `)}

  ${mq.xxl(css`
    width: ${pxToRem(220)};
    height: ${pxToRem(200)};
    bottom: -${pxToRem(60)};
    left: -${pxToRem(70)};
  `)}
`;

export default IconWrapper;
