import { useEffect, useState } from "react";

const useLanguage = lang => {
  const [language, setLanguage] = useState(lang);

  const changeLanguage = async (language = "de") => {
    setLanguage(language);
  };

  useEffect(() => {
    const languageRegex = /^\/(en|it|cz|hu)/;
    const langString = languageRegex.exec(window.location.pathname);

    if (langString) {
      const lang = langString[1];
      setLanguage(lang);
    } else {
      setLanguage("de");
    }
  }, []);

  return { changeLanguage, language };
};

export default useLanguage;
