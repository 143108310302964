import React from "react";
import Media from "react-media";
import Section from "../Section/Section";
import Grid from "../Grid/Grid";
import JobCard from "../JobCard/JobCard";
import { breakpoints } from "@/lib/mediaQueries";

const JobPreview = props => {
  return (
    <Section {...props}>
      <Grid columns={3}>
        {props.items && <Media
          defaultMatches={props.isMobile}
          query={{ maxWidth: breakpoints.sm }}
        >
          {matches =>
            matches
              ? props.items &&
              props.items.slice(0, 3).map((item, index) => {
                return (
                  <JobCard
                    key={index}
                    sectionBackground={props.background}
                    {...item}
                  />
                );
              })
              : props.items &&
              props.items.map((item, index) => {
                return (
                  <JobCard
                    key={index}
                    sectionBackground={props.background}
                    {...item}
                  />
                );
              })
          }
        </Media>}
      </Grid>
    </Section>
  );
};

export default JobPreview;
