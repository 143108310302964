import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";

const ImageWrapper = styled.div`
  width: 100%;
  padding: ${pxToRem(20)};
  height: ${pxToRem(80)};
  text-align: center;

  img {
      height: 100%;
  }
`;

export default ImageWrapper;
