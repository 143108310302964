import React, { useState } from "react";
import dynamic from "next/dynamic";
import Carousel, { Modal, ModalGateway } from "react-images";
import ImageSliderWrapper from "./components/ImageSliderWrapper";
import Section from "../Section/Section";
import { breakpoints } from "@/lib/mediaQueries";
import ImageWrapper from "./components/ImageWrapper";
import ContentWrapper from "./components/ContentWrapper";
import TextWrapper from "./components/TextWrapper";
import { P, H4 } from "../GlobalStyles/GlobalStyles";
import LazyImage from "../LazyImage/LazyImage";
import Link from 'next/link';

const Swiper = dynamic(() => import("../Swiper/Swiper"));

const swiperSettings = {
  overflow: "true",
  breakpointsInverse: true,
  showNavigation: false,
  showPagination: true,
  slidesOffsetAfter: 200,
  slidesPerView: 1,
  spaceBetween: 25,
  paginationSettings: {
    color: "#e42131",
    visibleOnDesktop: true
  },
  breakpoints: {
    [breakpoints.sm]: {
      centeredSlides: false,
      slidesPerView: 1
    },
    [breakpoints.md]: {
      slidesPerView: 2
    },
    [breakpoints.lg]: {
      slidesPerView: 3
    }
  }
};

const ConditionalLink = ({ url, children }) =>
  url ? <Link href={url}><a style={{ textDecoration: 'none' }}>{children}</a></Link> : children;



const Image = image => {
  return (
    <ConditionalLink url={image.url}>
      <>
        <ImageWrapper>
          <ContentWrapper>
            {image && typeof image.image === "string" ? (
              <LazyImage image={image} />
            ) : (
                <LazyImage image={image.image} />
              )}
          </ContentWrapper>
        </ImageWrapper>
        {image.name && (
          <P
            style={{
              textDecoration: 'none',
              color: '#000',
              fontWeight: 600,
              marginTop: 10,
              marginBottom: 0
            }}
          >
            {image.name}
          </P>
        )}
      </>
    </ ConditionalLink>
  );
};

const Text = ({ header, bodytext }) => {
  return (
    <TextWrapper>
      <H4 style={{ fontWeight: 600, textAlign: "left" }}>{header}</H4>
      <P>{bodytext}</P>
    </TextWrapper>
  );
};

const ImageSlider = ({ header, items, variant, backgroundImage, style }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (item, index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const images = items.map(item => {
    if (typeof item.image === "string") {
      return {
        src: item.image,
        width: item.width,
        height: item.height,
      };
    }
    if (typeof item.image === "object") {
      return {
        src: item.image.image,
        width: item.image.width,
        height: item.image.height,
        srcSet: item.image.responsive,
        url: item.url,
      }
    }
  });


  return (
    <Section
      header={header}
      backgroundImage={{ image: backgroundImage }}
      style={style}
    >
      <ImageSliderWrapper>
        <Swiper
          onClick={openLightbox}
          settings={swiperSettings}
          items={items}
          component={variant === "text" ? Text : Image}
        />
        {!images[0].url && <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={images.map(x => {
                  return {
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  };
                })}
              />
            </Modal>
          ) : null}
        </ModalGateway>}
      </ImageSliderWrapper>
    </Section>
  );
};

export default ImageSlider;
