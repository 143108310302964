import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";

const StickyContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -20px;
  padding: 0;

  h4,
  a,
  p {
    flex: 1;
    text-align: center;
  }

  p {
    margin-bottom: 0;
    margin-left: 10px;
    font-weight: 800;
    color: black;
    text-transform: uppercase;
  }

  a {
    margin-top: 0;
    margin-right: 0;
    display: flex;
    padding-left: 20px;
    padding-right: 40px;

    svg {
      right: 20px;
    }
  }

  .btn_wrapper {
    text-align: center;
  }

  ${mq.lg(css`
    .btn_wrapper {
      text-align: left;
    }
    h4 {
      text-align: left;
      margin-left: 20px;

    }
    a {
      display: inline-flex;
      padding: ${pxToRem(13)} ${pxToRem(50)}  ${pxToRem(13)} ${pxToRem(30)};

      svg {
        right: 35px;
      }
    }
  `)}

  ${mq.xxl(css`
    max-width: 85%;
  `)}
`;

export default StickyContent;
