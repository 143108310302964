import React, { useState } from "react";
import SVG from "react-inlinesvg";
import LanguageOptionsWrapper from "./components/LanguageOptionsWrapper";
import LanguageSelectWrapper from "./components/LanguageSelectWrapper";
import StyledSelect from "./components/StyledSelect";
import useLangugage from "@/lib/useLanguage";
import { withRouter } from 'next/router';

const availableLanguages = ["de", "en"];


function LanguageSelect({ meta }) {
  const { language, changeLanguage } = useLangugage();
  const [isOpen, setIsOpen] = useState(false);
  const { translations } = meta;


  return (
    <LanguageSelectWrapper
      className={`language-switch-wrapper ${isOpen ? 'is-open' : ''}`}
      aria-label={`${language == 'en' ? 'Select Language' : 'Sprachauswahl'}`}
    >
      <span
        onClick={() => setIsOpen(!isOpen)}
      >
        {language}
        {isOpen ? <SVG width={15} src="/static/svg/chevron-up.svg" /> : <SVG width={15} src="/static/svg/chevron-down.svg" />}
      </span>

      <LanguageOptionsWrapper className="language-options-wrapper">
        {availableLanguages.map((lang, i) => (
          <li key={lang}>
            <StyledSelect
              onClick={() => changeLanguage(lang)}
              href={translations[i] ? translations[i].navUrl : `/${lang === "de" ? "" : lang}`}
              lang={lang}
              className={lang == language ? 'is-current' : ''}
              key={i}
            >
              {lang}
            </StyledSelect>
          </li>
        ))}
      </LanguageOptionsWrapper>

    </LanguageSelectWrapper>
  );

}
export default withRouter(LanguageSelect)
