import styled from "@emotion/styled";

const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background: grey;
    overflow: hidden;

    img {
        position: absolute;
    }
`

export default ImageWrapper;