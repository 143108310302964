import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import withSection from "../Section/withSection";
import { P, Subheadline } from "../GlobalStyles/GlobalStyles";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import VizSensor from 'react-visibility-sensor';
import dynamic from 'next/dynamic';

const Lottie = dynamic(() =>
  import('@alfonmga/react-lottie-light-ts').then((mod) => mod.Lottie)
)

const TextpicWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-areas:
    "imageTop"
    "textArea"
    "imageBottom";

  ${({ imageorient, imagecols }) =>
    [17, 25, 18, 26].indexOf(parseInt(imageorient)) > -1 &&
    imagecols > 1 &&
    css`
      display: flex;
      flex-wrap: wrap;

      ${mq.xl(css`
        display: grid;
        grid-template-areas: none;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;

        ${TextWrapper} {
          padding: 0 ${pxToRem(40)};
        }
      `)}

      ${[17, 25].indexOf(imageorient) > -1 &&
      css`
          ${TextWrapper} {
            grid-column: 1 / ${imagecols};
            grid-row: 1 / 1;
            padding-left: 0;
          }

          ${ImageWrapper} {
            grid-column: ${imagecols} / 13;
            grid-row: 1 / 1;
            /* padding-left: ${pxToRem(20)}; */
            margin-bottom: ${pxToRem(40)};
          }
        `}

      ${[18, 26].indexOf(imageorient) > -1 &&
      css`
          ${TextWrapper} {
            grid-column: ${imagecols} / 13;
            grid-row: 1 / 1;
          }

          ${ImageWrapper} {
            grid-column: 1 / ${imagecols};
            grid-row: 1 / 1;
            margin-bottom: ${pxToRem(40)};
          }
        `}
    `}
`;

const ImageWrapper = styled.div`
  text-align: center;
  position: relative;
  ${({ imageorient }) =>
    imageorient < 3 &&
    css`
      grid-area: imageTop;
      margin-bottom: ${pxToRem(20)};
    `}

  ${({ imageorient }) =>
    imageorient >= 8 &&
    imageorient <= 10 &&
    css`
      grid-area: imageBottom;
      margin-top: ${pxToRem(20)};
    `}
`;

const TextWrapper = styled.div`
  grid-area: textArea;
`;

const Textpic = (props) => {
  const {
    colPos,
    assets,
    header,
    subheader,
    animation,
    loop,
    imageorient,
    imagecols,
    bodytext,
    header_position,
    header_layout,
    layout,
    id
  } = props;


  const [playingState, setPlayingState] = useState("paused");
  const CustomHeadline = `h${header_layout == 0 ? 2 : header_layout}`;


  const defaultOptions = {
    loop,
    autoplay: false,
    path: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <TextpicWrapper
      imageorient={parseInt(imageorient)}
      imagecols={parseInt(imagecols)}
      layout={parseInt(layout)}
    >
      {(animation !== 0) && (
        <VizSensor partialVisibility onChange={(isVisible) => { setPlayingState(isVisible ? "playing" : "paused") }}>
          <ImageWrapper imageorient={parseInt(imageorient)}>
            <Lottie config={defaultOptions} playingState={playingState} />
          </ImageWrapper>
        </VizSensor>
      )}
      <TextWrapper>
        <CustomHeadline
          dangerouslySetInnerHTML={{ __html: header }}
          style={{ textAlign: header_position || "center" }}
        />
        <P as="div" dangerouslySetInnerHTML={{ __html: bodytext }} />
      </TextWrapper>
    </TextpicWrapper>
  );
};

export default withSection(Textpic);
