import ImageSlider from "../ImageSlider/ImageSlider";
import Section from "../Section/Section";
import { FormattedMessage } from "react-intl";
import Button from "../Button/Button";
import { H2, H3, P } from "../GlobalStyles/GlobalStyles";
import React from "react";

import css from "@emotion/css";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";
import Stage from "../Stage/Stage";
import selectComponent from "@/lib/selectComponent";
import ReferenceDetailWrapper from "../ReferenceDetail/components/ReferenceDetailWrapper";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";

const removeFromContent = ["mask_socialbuttons", "newsform_formAction"];

const NewsDetail = (props) => {
  if (!props.item) return null;

  const { item, isMobile, ctaLink = "#" } = props;
  const { content, categories, datetime } = item;
  const date = new Date(datetime);
  return (
    <>
      <Stage
        {...item}
        imageOnly
        isMobile={isMobile}
        image={item.stageImage}
        header={item.title}
        subHeader={`${date.toLocaleDateString()}`}
      />
      {content &&
        content
          .filter((module) => module.CType === "mask_socialbuttons")
          .map((module, index) => {
            return (
              <ErrorBoundary key={index}>
                {selectComponent(module, isMobile, { optionalKey: index })}
              </ErrorBoundary>
            );
          })}
      <Section style={{ marginTop: 0 }}>
        <ReferenceDetailWrapper>
          <FormattedMessage
            id="button.back.overview"
            defaultMessage="Back to overview"
          >
            {(message) => (
              <>
                <Button
                  href={ctaLink}
                  type="blank"
                  iconColor={theme.colors.primary}
                  iconPosition="before"
                  light
                >
                  {message}
                </Button>
              </>
            )}
          </FormattedMessage>
          <hr
            css={css`
              margin-top: ${pxToRem(30)};
              margin-bottom: ${pxToRem(30)};
            `}
          />
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2  project-infos">
              <div
                css={css`
                  background-color: ${theme.colors.primary};
                  color: ${theme.colors.white};
                  display: inline-block;
                  text-transform: uppercase;
                  text-align: center;
                  padding: ${pxToRem(3)} ${pxToRem(20)};
                  font-weight: 800;

                  &.cat-2 {
                    background-color: ${theme.colors.secondary};
                  }

                  &.cat-3 {
                    background-color: ${theme.colors.greyLight};
                  }
                `}
                className={`cat${
                  categories && categories.length > 0
                    ? `-${categories[0].uid}`
                    : ""
                }`}
              >
                <span
                  css={css`
                    font-size: 0.75rem;
                  `}
                />
                {categories && categories.length > 0
                  ? categories[0].title
                  : "Allgemein"}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2  project-infos">
              <H2 as="h1" style={{ textAlign: "left" }}>
                {item.name}
              </H2>
              <div>
                {content &&
                  content
                    .filter(
                      (module) => !removeFromContent.includes(module.CType)
                    )
                    .map((module, index) => {
                      return (
                        <div
                          key={index}
                          css={css`
                            margin-bottom: 0;
                            section {
                              padding: 0 !important;
                            }
                          `}
                        >
                          <ErrorBoundary>
                            {selectComponent(module, isMobile, {
                              optionalKey: index,
                            })}
                          </ErrorBoundary>
                        </div>
                      );
                    })}
              </div>
            </div>
            {content &&
              content
                .filter((module) => module.CType === "newsform_formAction")
                .map((module, index) => {
                  return (
                    <div
                      key={index}
                      css={css`
                        order: 2;
                      `}
                    >
                      <ErrorBoundary>
                        {selectComponent(module, isMobile, {
                          optionalKey: index,
                        })}
                      </ErrorBoundary>
                    </div>
                  );
                })}
          </div>
        </ReferenceDetailWrapper>
      </Section>
    </>
  );
};

export default NewsDetail;
