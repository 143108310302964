import React from "react";
import Media from "react-media";
import Container from "../Container/Container";
import Button from "../Button/Button";
import SlideContainer from "./components/SlideContainer";
import { H1, H2, H3, H4, P, Subheadline } from "../GlobalStyles/GlobalStyles";
import ImageWrapper from "./components/ImageWrapper";
import IconWrapper from "./components/IconWrapper";
import LazyImage from "../LazyImage/LazyImage";
import { breakpoints } from "@/lib/mediaQueries";

const Slide = ({
  image,
  logo,
  gradienttop,
  gradientbottom,
  customer,
  backgroundimage,
  header,
  subheader,
  cta,
  ctatype,
  ctalink,
  icon,
  imageOnly,
  isMobile
}) => {
  return (
    <>
      <SlideContainer
        gradient={
          gradienttop || gradientbottom
            ? { from: gradienttop, to: gradientbottom || gradienttop }
            : false
        }
        backgroundImage={backgroundimage && backgroundimage.image}
        backgroundImageWebp={backgroundimage && backgroundimage.webp}
        imageOnly={imageOnly}
      >
        <Container>
          {imageOnly && (
            <ImageWrapper>
              <LazyImage image={image} layout="intrinsic" />
            </ImageWrapper>
          )}

          {!imageOnly && (
            <Media
              defaultMatches={isMobile}
              query={{ minWidth: breakpoints.md }}
            >
              {matches =>
                matches ? (
                  <div className="row">
                    <div className="col-7">
                      {image && (
                        <ImageWrapper>
                          <LazyImage image={image} layout="fill" objectFit="contain" />
                        </ImageWrapper>
                      )}
                    </div>
                    <div className="col-5" style={{ alignSelf: "center" }}>
                      <H3 as="h2" style={{ textAlign: "left", color: "white" }}>
                        {header}
                      </H3>
                      <Subheadline
                        as="div"
                        dangerouslySetInnerHTML={{ __html: subheader }}
                        style={{ color: "white", fontWeight: 300 }}
                      />
                      {cta && (
                        <Button href={ctalink} type={ctatype}>
                          {cta}
                        </Button>
                      )}
                    </div>
                  </div>
                ) : (
                  <ImageWrapper>
                    <LazyImage image={image} layout="intrinsic" />
                  </ImageWrapper>
                )
              }
            </Media>
          )}

          {customer && (
            <IconWrapper>
              <embed src={customer.logoslide} alt={header} />
            </IconWrapper>
          )}

          {logo && (
            <IconWrapper stage>
              <img src={logo} alt={header} />
            </IconWrapper>
          )}
        </Container>
      </SlideContainer>
    </>
  );
};

export default Slide;
