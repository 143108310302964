import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import theme from "@/styles";

const TechnologyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colors.greyLighter};
  cursor: pointer;
  justify-content: flex-start;
  transition: all 0.3s ease-in-out;
  margin: 0 ${pxToRem(10)};

  ${({ square }) =>
    square &&
    css`
      padding-bottom: 100%;
    `}

  ${mq.lg(css`
    margin: 0;
  `)}

  ${({ useFilter }) =>
    useFilter &&
    css`
      filter: grayscale(100%);
    `}

  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
      padding: ${pxToRem(20)} ${pxToRem(30)};

      p {
        width: 100%;
        color: #555555;
        text-align: left;
      }
    `}

  &:hover {
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
    filter: none;
  }

  ${({ resetMargin }) =>
    resetMargin &&
    css`
      margin: 0 ${pxToRem(10)};
    `}
`;

export default TechnologyWrapper;
