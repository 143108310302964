import styled from "@emotion/styled";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const PaginationWrapper = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    padding: 0;
    flex-direction: row;
    background: ${theme.colors.white};

    li {
      list-style-type: none;
      border: 1px solid #efefef;
      margin: 0;
      padding:0;
      min-height:${pxToRem(40)};
      display:flex;
      align-items:center;

      &:first-of-type {
        border-right: 0;
      }
      &:last-of-type {
        border-left: 0;
      }

      a {
        color: ${theme.colors.secondary};
        text-decoration: none;
        padding: ${pxToRem(15)} ${pxToRem(15)};
        line-height:0;
        cursor: pointer;
      }

      &.disabled {
        background: ${theme.colors.greyLighter};
        opacity: 0.5;
        pointer-events: none;
      }

      &.previous,
      &.next {

        a {
          font-size: 0.875rem;
          padding: ${pxToRem(15)} ${pxToRem(20)};
          color: ${theme.colors.black};
        }
      }

      &.active {
        background-color: ${theme.colors.secondary};
        a {
          font-weight: bold;
          color: ${theme.colors.white};
        }
      }
    }
  }
`;

export default PaginationWrapper;
