import StageWrapper from './components/StageWrapper';
import Button from '../Button/Button';
import Container from '../Container/Container';
import { H2, P } from "../GlobalStyles/GlobalStyles";
import { Lottie } from '@alfonmga/react-lottie-light-ts';
import animationData from '@/static/animation/flaschenpost.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const Thanks = ({ header, subheader, items }) => {
  return (
    <StageWrapper>
      <Container>
        <Lottie width="320" config={defaultOptions} />
        {header && <H2>{header}</H2>}
        {subheader && <P>{subheader}</P>}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {items.map(({ buttontext, buttonlink }, index) =>
            <Button key={index} href={buttonlink} type="primary">
              {buttontext}
            </Button>
          )}
        </div>
      </Container>
    </StageWrapper>
  )
}

export default Thanks;