import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";

const StickyLayerFlyout = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  right: ${pxToRem(60)};
  width: 0;
  height: ${pxToRem(60)};
  overflow: hidden;
  background: black;
  transition: width 0.3s ease-in-out, height 0.1s ease-in-out 0.3s;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);

  div {
    transition: opacity 0.6s ease-in-out 0.4s;
    overflow: hidden;
    opacity: 0;
  }

  ${({ open }) =>
    open &&
    css`
      width: ${pxToRem(220)};
      padding: 15px 20px;
      height: ${pxToRem(100)};

      div {
        opacity: 1;
      }
    `}
`;

export default StickyLayerFlyout;
