import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";

const SocialTeaserContent = styled.div`
  padding: ${pxToRem(20)};

  & > p {
    height: 4.6em;
    margin: 0;
    overflow: hidden;
    color: #555555;
    font-weight: 400;
  }
`;

export default SocialTeaserContent;
