import styled from "@emotion/styled";

const StyledSVG = styled.svg`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 70%;
    fill: white;
    opacity: 0.9;
`

export default StyledSVG;