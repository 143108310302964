import React from "react";
import ReactRating from "react-rating";
import SVG from "react-inlinesvg";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import RatingWrapper from "./components/RatingWrapper";
import RatingSymbol from "../../../Kununu/components/RatingSymbol";
import { P } from "../../../GlobalStyles/GlobalStyles";
import { injectIntl } from "react-intl";

const Rating = ({ ratingType, rating, count, ratingsText }) => {
  return (
    <RatingWrapper>
      <SVG
        css={css`
          fill: #989898;
          margin: ${pxToRem(10)} auto 0 auto;
        `}
        src={`/static/svg/logo-${ratingType}-weiss.svg`}
      />
      <div>
        <P as="div">
          <span
            css={css`
              margin-right: 5px;
            `}
          >
            {rating}
          </span>
          <ReactRating
            emptySymbol={
              <RatingSymbol size={12} color={"#ffffff"} spaceBetween={"2px"} />
            }
            fullSymbol={
              <RatingSymbol
                size={12}
                color={"#ffffff"}
                spaceBetween={"2px"}
                full
              />
            }
            readonly
            quiet
            initialRating={rating}
          />
        </P>
      </div>
      <small>{count} {ratingsText}
      </small>
    </RatingWrapper>
  );
};

export default Rating;
