import styled from "@emotion/styled";
import theme from "@/styles";
import Section from "../../Section/Section";
import { pxToRem } from "@/lib/functions";

const PricingWrapper = styled.div`
  ul {
    text-align: left;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  ul.price__list {

    li:first-of-type {
      border-top: 1px solid ${theme.colors.greyLighter};
    }
    li:last-of-type {
      border-bottom: none;
    }

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: ${pxToRem(10)} 0;
      border-bottom: 1px solid ${theme.colors.greyLighter};

      .price {
        float: right;
        clear: right;
      }

      .price__text {
        color: ${theme.colors.primary};
        font-weight: 600;
        font-size: 1.25rem;
      }
    }
  }
`;

export default PricingWrapper;
