import React, { useState } from "react";
import Media from "react-media";
import PropTypes from "prop-types";
import InfoSlideBoxWrapper from "./components/InfoSlideBoxWrapper";
import InfoSlide from "./components/InfoSlide/InfoSlide";
import { H2 } from "../GlobalStyles/GlobalStyles";
import FlyoutContainer from "../Flyout/FlyoutContainer";
import Grid from "../Grid/Grid";
import Container from "../Container/Container";
import TextWrapper from "./components/TextWrapper";
import { breakpoints } from "@/lib/mediaQueries";
import InfoSlideBoxOuterWrapper from "./components/InfoSlideBoxOuterWrapper";

const InfoSlideBoxes = ({ content, header, isMobile }) => {
  const [indexState, setIndexState] = useState(-1);
  const items = content[0];

  return (
    <InfoSlideBoxOuterWrapper>
      {header && <H2>{header}</H2>}
      <InfoSlideBoxWrapper>
        {items.map((item, index) => {
          return (
            <InfoSlide
              key={index}
              gradient={item.gradient}
              backgroundImg={item.image.image}
              title={item.header}
              index={index}
              indexState={indexState}
              setIndexState={setIndexState}
              opened={indexState === index ? true : false}
              items={items}
              isMobile={isMobile}
            />
          );
        })}
      </InfoSlideBoxWrapper>
      <Media query={{ minWidth: breakpoints.lg }} defaultMatches={!isMobile}>
        {matches =>
          matches && (
            <FlyoutContainer
              open={indexState >= 0}
              indexState={indexState}
              borderColor="#cc1e2a"
              borderWidth={10}
            >
              <Container>
                <Grid columns={2}>
                  {items.map(
                    (item, index) =>
                      indexState >= 0 &&
                      item.subItems.length > 0 &&
                      item.subItems.map((subItem, subIndex) => {
                        return (
                          <TextWrapper
                            key={subIndex}
                            showItems={index === indexState}
                          >
                            <H2 as="h4">{subItem.header}</H2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: subItem.bodytext
                              }}
                            />
                          </TextWrapper>
                        );
                      })
                  )}
                </Grid>
              </Container>
            </FlyoutContainer>
          )
        }
      </Media>
    </InfoSlideBoxOuterWrapper>
  );
};

InfoSlideBoxes.propTypes = {
  items: PropTypes.array.isRequired,
  header: PropTypes.string
};

InfoSlideBoxes.defaultProps = {
  items: []
};

export default InfoSlideBoxes;
