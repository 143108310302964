import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";

const CardList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  padding: 0;

  ${mq.lg(css`
    justify-content: flex-start;
  `)}
`;

export default CardList;
