import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { H3 } from "../../GlobalStyles/GlobalStyles";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const ImageTitle = styled(H3)`
  position: absolute;
  display: none;
  bottom: ${pxToRem(20)};
  color: white;
  text-transform: uppercase;
  z-index: 2;
  left: 20px;
  right: 20px;
  margin: 0;

  ${mq.md(css`
    display: block;
    bottom: ${pxToRem(40)};
  `)}

  ${mq.xxl(css`
    bottom: ${pxToRem(60)};
  `)}
`;

export default ImageTitle;
