import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem, hexToRgba } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import theme from "@/styles";

const IconWrapper = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  

  ${mq.md(css`
    background-size: ${pxToRem(100)};
  `)}

  ${mq.lg(css`
    background-size: ${pxToRem(150)};
  `)}

  ${({ iconPosition }) =>
    iconPosition === "center" &&
    css`
      ${mq.lg(css`
        background-position: 100% 60%;
      `)}
    `}

  ${({ iconPosition }) =>
    iconPosition === "bottom" &&
    css`
      ${mq.lg(css`
        left: ${pxToRem(-32)};
        background-position: 100% 100%;
      `)}
    `}



    mask-image: url(${({ icon }) => icon ? `/static/svg/${icon}.svg` : ''});
    mask-size: auto ${pxToRem(60)};
    mask-repeat: no-repeat;
    mask-position: 100% 0%;
    background-size: 100%;

    .no-webp & {
      background: linear-gradient(
        ${({ iconColor }) => hexToRgba(theme.colors[iconColor])},
        ${({ iconColor }) => theme.colors[iconColor]}
      ),
      url(${({ backgroundImage }) => backgroundImage});
    }

    .webp & {
      background: linear-gradient(
        ${({ iconColor }) => hexToRgba(theme.colors[iconColor])},
        ${({ iconColor }) => theme.colors[iconColor]}
      ),
      url(${({ backgroundImageWebp }) => backgroundImageWebp});
    }

    html {
      background: none !important;
    }
  
  ${mq.md(css`
    mask-size: ${pxToRem(100)};
  `)}

  ${mq.lg(css`
    mask-size: ${pxToRem(150)};
  `)}

  ${({ iconPosition }) =>
    iconPosition === "center" &&
    css`
      ${mq.lg(css`
        mask-position: 100% 60%;
      `)}
    `}

  ${({ iconPosition }) =>
    iconPosition === "bottom" &&
    css`
      ${mq.lg(css`
        left: ${pxToRem(-32)};
        mask-position: 100% 100%;
      `)}
    `}
`;

export default IconWrapper;
