import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const DownloadModuleWrapper = styled.div`
padding:${pxToRem(20)};
margin:${pxToRem(20)} 0; 
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-between;
box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.08);
${mq.lg(css`
    flex-direction:row;
  `)}
`;


export default DownloadModuleWrapper;