import React from "react";
import QuoteWrapper from "./components/QuoteWrapper";
import Container from "../Container/Container";
import { H4, Subheadline } from "../GlobalStyles/GlobalStyles";

const Quote = ({ quote, quoteTitle}) => {
  return (
    <QuoteWrapper>
      <Container>
        <H4 as="p">
          {quote}
        </H4>
        <Subheadline as="p">{quoteTitle}</Subheadline>
      </Container>
    </QuoteWrapper>
  );
};

export default Quote;
