import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ImageWrapper from "./ImageWrapper";
import mq from "@/lib/mediaQueries";

const SlideWrapper = styled.div`
  padding: 0px 20px;

  ${({ backgroundImage, backgroundImageWebp, gradient }) =>
    backgroundImage
      ? css`
          .no-webp & {
            background-image: url(${backgroundImage});
            background-size: cover;
            background-position: top;
          }

          .webp & {
            background-image: url(${backgroundImageWebp});
            background-size: cover;
            background-position: top;
          }
        `
      : css`
          background-image: linear-gradient(
            to bottom,
            ${gradient.from},
            ${gradient.to}
          );
        `};

  flex: 1;
  transition: flex 0.6s ease-in-out;
  position: relative;
  overflow: hidden;

  ${mq.lg(css`
    &:hover {
      flex: 6;

      ${ImageWrapper} {
        margin-left: 0;
      }
    }
  `)}

  ${mq.xl(css`
    &:hover {
      ${ImageWrapper} {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }
  `)}
`;

export default SlideWrapper;
