import React from "react";
import { css } from "@emotion/react";
import SVG from "react-inlinesvg";
import ContactWrapper from "./components/ContactWrapper";
import Title from "../Title";
import { P } from "../../../GlobalStyles/GlobalStyles";

const Contact = ({ location, phone, email, icon }) => {
  return (
    <ContactWrapper icon={icon}>
      <div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {icon === "koeln" ? (
            <SVG src="/static/svg/koeln.svg" />
          ) : (
            <SVG src="/static/svg/berlin.svg" />
          )}
          <Title>{location}</Title>
        </div>
        <P style={{ margin: 0 }}>
          {phone} <br />
          {email}
        </P>
      </div>
    </ContactWrapper>
  );
};

export default Contact;
