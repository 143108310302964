import React from "react";
import PropTypes from "prop-types";
import FlyoutWrapper, {
  BorderTriangle,
  FlyoutBorder
} from "./components/FlyoutWrapper";

const FlyoutContainer = ({
  children,
  open,
  indexState,
  borderColor,
  borderWidth
}) => {
  return (
    open && (
      <FlyoutWrapper open={open} indexState={indexState}>
        <FlyoutBorder borderColor={borderColor} borderWidth={borderWidth}>
          <BorderTriangle borderColor={borderColor} borderWidth={borderWidth} />
          {children}
        </FlyoutBorder>
      </FlyoutWrapper>
    )
  );
};

FlyoutContainer.defaultProps = {
  borderColor: "#333333",
  borderWidth: 17
};

FlyoutContainer.propTypes = {
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string
};

export default FlyoutContainer;
