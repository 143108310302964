import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import Container from "../../Container/Container";

const StageWrapper = styled.section`
  background: #f4f4f4;
  position: relative;
  overflow: hidden;

  ${Container} {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    margin-top: -${pxToRem(100)};
  }

  ${mq.lg(css`
    flex-direction: column;
    padding: ${pxToRem(10)} 20px ${pxToRem(10)} 20px;
    min-height: ${pxToRem(450)};

    ${Container} {
      margin-top: ${pxToRem(40)};
    }
  `)}

  ${mq.xxl(css`
    min-height: ${pxToRem(550)};

    ${Container} {
      padding: 0 20px;
    }
  `)}
`;

export default StageWrapper;
