import styled from "@emotion/styled";
import { H2 } from "../../GlobalStyles/GlobalStyles";
import { pxToRem } from "@/lib/functions";

const TextWrapper = styled.div`
  flex: ${({ open }) => (open ? 3 : 5)};
  transition: flex 0.6s;

  ${({ showItems }) => (showItems ? `display: block;` : `display: none;`)}

  ${H2} {
    line-height: 1;
    text-transform: uppercase;
    text-align: left;
    font-size: ${pxToRem(30)};
    margin-bottom: ${pxToRem(20)};
    overflow-wrap: break-word;
  }
`;

export default TextWrapper;
