import React from "react";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";
import { breakpoints } from "@/lib/mediaQueries";
import Accordion from "../../../Accordion/Accordion";
import UspList from "../UspList/UspList";

const CustomerCharacteristics = props => {
  return (
    <Accordion
      triggerWidth={breakpoints.lg}
      arrow={true}
      arrowColor={"#000000"}
    >
      <div>Projektdetails</div>
      <div
        css={{
          boxShadow: theme.boxShadows.default,
          width: "100%"
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            minHeight: pxToRem(150),
            justifyContent: "center",
            padding: "2rem",
            alignItems: "center"
          }}
        >
          <embed
            css={{
              display: "flex",
              maxWidth: "100%",
              minWidth: "50%",
              minHeight: "6em",
              maxHeight: "8em",
            }}
            src={props.icon}
          />
        </div>
        <div
          css={{
            backgroundColor: theme.colors.greyLighter,
            padding: `${pxToRem(20)} ${pxToRem(40)}`
          }}
        >
          <div>
            <p css={{ fontWeight: "bold", fontSize: pxToRem(20) }}>
              {props.name}
            </p>
            <p
              as="div"
              dangerouslySetInnerHTML={{ __html: props.description }}
              css={{ marginBottom: pxToRem(32) }}
            />
          </div>
          <UspList items={props.uspList} />
        </div>
      </div>
    </Accordion>
  );
};

export default CustomerCharacteristics;
