export async function fetchData(request) {

  try {
    const response = await fetch(request.url.toString(), {
      method: request.method,
      headers: { Accept: "application/json" },
      mode: 'no-cors',
    })

    if (response.ok) {
      return response.json()
    } else {
      return Promise.reject(await response.json())
    }
  } catch (error) {
    // Implementation or Network error
    console.error(
      'You have an error in your code or there are Network issues.',
      error
    )
    return Promise.reject(error)
  }

}
