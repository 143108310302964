import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";
import theme from "@/styles";

const Overlay = styled.svg`
  fill: ${theme.colors.white};
  position: absolute;
  z-index: 2;
  top: ${pxToRem(50)};
`;

export default Overlay;
