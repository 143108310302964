import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const IconWrapper = styled.div`
  margin: ${pxToRem(20)} auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    height: ${pxToRem(40)};
    width: 100%;
    padding: 0 60px;
    object-fit: contain;
  }

  h4 {
    transition: all 0.3s ease-in-out;
    text-align: left;
    color: white;
    margin-bottom: 0;
    justify-self: flex-start;
    line-height: 1.2;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  }

  ${mq.lg(css`
    img,
    embed {
      width: auto;
      height: ${pxToRem(60)};
      padding: 0;
    }
  `)}
`;

export default IconWrapper;
