import styled from "@emotion/styled";
import mq from "@/lib/mediaQueries";
import { css } from "@emotion/react";

const NonSticky = styled.div`
  h4 {
    text-align: center;
    margin-bottom: 10px;
  }

  .btn_wrapper {
    text-align: center;
  }

  a {
    margin-top: 0;
  }

  ${mq.lg(css`
    .btn_wrapper {
      text-align: left;
    }
    h4 {
      text-align: left;
      margin-bottom: 10px;
    }
  `)}

  ${mq.xxl(css`
    max-width: 85%;
  `)}
`;

export default NonSticky;