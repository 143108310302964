import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import CustomerCharacteristics from "./components/CustomerCharacteristics/CustomerCharacteristics";
import Section from "../Section/Section";
// import FilterTags from "./components/FilterTags/FilterTags";
import ImageSlider from "../ImageSlider/ImageSlider";
import Quote from "../Quote/Quote";
import { H2, H3, P } from "../GlobalStyles/GlobalStyles";
import ReferenceDetailWrapper from "./components/ReferenceDetailWrapper";
import Slide from "../Slide/Slide";
import css from "@emotion/css";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";
import Button from "../Button/Button";
import LazyImage from "../LazyImage/LazyImage";

const ReferenceDetail = ({ ctaLink, item, isMobile }) => {
  return (
    <>
      <Slide
        imageOnly
        isMobile={isMobile}
        backgroundimage={item.backgroundImage}
        {...item}
      />
      <Section style={{ marginTop: 0 }}>
        <ReferenceDetailWrapper>
          <FormattedMessage
            id="button.back.overview"
            defaultMessage="Back to overview"
          >
            {message => (
              <>
                <Button
                  href={ctaLink}
                  type="blank"
                  iconColor={theme.colors.primary}
                  iconPosition="before"
                  light
                >
                  {message}
                </Button>
              </>
            )}
          </FormattedMessage>

          <div className="row">
            <div className="col-12 col-lg-7 col-xl-7 project-infos">
              {/* <FilterTags
                items={["Fußball & Sport", "Typo 3", "Corporate Communication"]}
              /> */}
              <H2 as="h1" style={{ textAlign: "left" }}>
                {item.name}
              </H2>
              <div>
                {item.content &&
                  item.content.map((content, index) => {
                    return (
                      <div
                        key={index}
                        css={css`
                          margin-bottom: ${pxToRem(30)};
                        `}
                      >
                        {content.image && (
                          <div
                            css={css`
                              margin-bottom: ${pxToRem(40)};
                            `}
                          >
                            <LazyImage image={content.image} />
                          </div>
                        )}
                        {content.header && (
                          <H3
                            css={css`
                              text-align: left;
                              margin-bottom: ${pxToRem(20)};
                            `}
                          >
                            {content.header}
                          </H3>
                        )}
                        {content.bodytext && (
                          <P
                            as="div"
                            dangerouslySetInnerHTML={{
                              __html: content.bodytext
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
            {item.customer && (
              <div className="col-12 col-lg-5 col-xl-4 project-details offset-xl-1">
                <CustomerCharacteristics
                  icon={item.customer.logo}
                  name={item.customer.name}
                  description={item.customer.description}
                  uspList={item.customer.additionalinformation}
                />
              </div>
            )}
          </div>
        </ReferenceDetailWrapper>
      </Section>
      <ImageSlider
        style={{ paddingTop: 250, marginTop: -350, marginBottom: 0 }}
        items={item.gallery}
      />
      {item.quote && <Quote quote={item.quote} quoteTitle={item.quoteTitle} />}
      {item.related && item.related.length > 0 && (
        <ImageSlider header="Ähnliche Projekte" items={item.related} />
      )}
    </>
  );
};

export default ReferenceDetail;
