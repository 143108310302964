import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import theme from "@/styles";
import Container from "../../Container/Container";
import mq from "@/lib/mediaQueries";

const VideoStageWrapper = styled.section`
  background: ${theme.colors.black};
  color: white;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mq.lg(css`
    margin-top: ${pxToRem(70)};
  `)}

  ${Container} {
    justify-content: center;
    position: relative;
    z-index: 5;
  }
`;

export default VideoStageWrapper;
