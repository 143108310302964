import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import { H2 } from "../../GlobalStyles/GlobalStyles";
import Panel from "../../Accordion/components/Panel";

const InfoSlideBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${mq.lg(css`
    flex-direction: row;
  `)}

  ${H2} {
    line-height: 1;
    text-transform: uppercase;
    text-align: left;
    font-size: ${pxToRem(30)};
    margin-bottom: ${pxToRem(20)};
  }

  ${Panel} {
    max-height: 100%;
  }
`;

export default InfoSlideBoxWrapper;
