import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const LogoWrapper = styled.div`
  min-width: ${pxToRem(150)};

  ${mq.xxl(css`
    min-width: ${pxToRem(200)};
  `)}
`;

export default LogoWrapper;
