import React from "react";
import SVG from "react-inlinesvg";
import Media from "react-media";
import { breakpoints } from "@/lib/mediaQueries";
import EmployeeWrapper from "./components/EmployeeWrapper";
import { P, Subheadline } from "../../../GlobalStyles/GlobalStyles";
import IconWrapper from "./components/IconWrapper";
import LazyImage from "../../../LazyImage/LazyImage";
import ContentWrapper from "./components/ContentWrapper";

const Employee = ({
  center,
  name,
  title,
  image,
  xing,
  linkedin,
  horizontal,
  phone,
  whatsapp,
  signal,
  email,
  autoSize,
  isMobile,
  index,
  ...rest
}) => {
  return (
    <EmployeeWrapper center={center} horizontal={horizontal} {...rest}>
      <LazyImage autoSize={autoSize} image={image} layout="intrinsic"/>
      <ContentWrapper>
        <Subheadline bold style={{ marginBottom: "5px" }}>
          {name}
        </Subheadline>
        {title && <P style={{ marginBottom: "5px" }}>{title}</P>}
        {email && <a href={`mailto:${email}`}>{email}</a>}
        <Media
          defaultMatches={!isMobile}
          query={{ maxWidth: breakpoints.lg }}
        >
          <div key={index}>
            {whatsapp && (
              <IconWrapper>
                <SVG width="20" src={`/static/svg/whatsapp.svg`} />
                <a href={`https://wa.me/${whatsapp}`}>Messenger Nachricht</a>
              </IconWrapper>
            )}
            {signal && (
              <IconWrapper>
                <SVG width="20" src={`/static/svg/signal.svg`} />
                <a href={`https://signal.me/#p/${signal}`}>Messenger Nachricht</a>
              </IconWrapper>
            )}
          </div>
        </Media>
        {phone && <P>{phone}</P>}
        {xing && (
          <IconWrapper>
            <SVG width="20" src={`/static/svg/xing.svg`} />
            <a href={xing}>Kontakt aufnehmen</a>
          </IconWrapper>
        )}
        {linkedin && (
          <IconWrapper>
            <SVG width="20" src={`/static/svg/linked-in.svg`} />
            <a href={linkedin}>Kontakt aufnehmen</a>
          </IconWrapper>
        )}
      </ContentWrapper>
    </EmployeeWrapper>
  );
};

Employee.defaultProps = {
  autoSize: false
};

export default Employee;
