import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";
import theme from "@/styles";
import FormWrapper from "../../Form/components/FormWrapper";

const NewsletterWrapper = styled.div`
  margin: 0 auto;
  max-width: ${pxToRem(700)};

  ${FormWrapper} {
    padding: 0;
  }

  input[type="chechbox"] {
    padding: 200px;
  }

  .input {
    flex: 2 !important;
  }

  .form-group {
    .parsley-error {
      border: none;

      .form__label {
        font-weight: 600;
      }
    }
  }

  .parsley-success {
    color: white !important;
    border: none !important;
  }

  .parsley-errors-list {
    display: none;
    margin-top: inherit !important;
    left: 0;
  }

  .form__label {
    background: none !important;
    border: none !important;
    color: ${theme.colors.white} !important;
    margin-top: 10px;
    padding: 10px;

    a {
      color: ${theme.colors.white};
    }
  }
`;

export default NewsletterWrapper;
