import React from "react";
import PropTypes from "prop-types";
import { config, animated, Trail } from "react-spring/renderprops.cjs";
import Container from "../Container/Container";
import StageWrapper from "./components/StageWrapper";
import TextWrapper from "./components/TextWrapper";
import ImageWrapper from "./components/ImageWrapper";
import Overlay from "./components/Overlay";
import IconWrapper from "./components/IconWrapper";
import { H1, H3, P, Subheadline } from "../GlobalStyles/GlobalStyles";
import Button from "../Button/Button";
import LazyImage from "../LazyImage/LazyImage";

// type StageProps = {
//   cta?: string,
//   ctaLink?: string,
//   ctaType?: string,
//   imageOverlay?: string,
//   icon?: string,
//   iconColor?: string,
//   iconBackground?: string,
//   header: string,
//   subHeader?: string,
//   bodytext: string,
//   image: string,
//   additionalheader?: string,
// }


const Stage = ({
  cta,
  ctaLink,
  ctaType,
  imageOverlay,
  icon,
  iconColor,
  iconBackground,
  header,
  subHeader,
  bodytext,
  image,
  additionalheader
}) => {
  return (
    <StageWrapper icon={icon}>
      <Container>
        <TextWrapper>
          <Trail
            config={config.slow}
            from={{ opacity: 0, x: -100 }}
            to={{ opacity: 1, x: 0 }}
            delay={600}
            native
            keys={item => item}
            items={
              subHeader
                ? [
                  <Subheadline bold key="subheadline">{subHeader}</Subheadline>,
                  <H3
                    as="h1"
                    style={{
                      textAlign: "left",
                      textTransform: "uppercase"
                    }}
                    key="headline"
                    dangerouslySetInnerHTML={{ __html: header }}
                  />,
                  <P
                    key="text"
                    as="div"
                    dangerouslySetInnerHTML={{ __html: bodytext }}
                  />
                ]
                : additionalheader
                  ? [
                    <H1
                      key="headline"
                      style={{
                        textAlign: "left",
                        textTransform: "uppercase"
                      }}
                      dangerouslySetInnerHTML={{ __html: header }}
                    />,
                    <Subheadline
                      key="subheadline"
                      as="div"
                      dangerouslySetInnerHTML={{ __html: bodytext }}
                    />,
                    <H3 key="additionalHeader" style={{ textAlign: "left" }} as="div">
                      {additionalheader}
                    </H3>,
                    <Button
                      key="button"
                      style={{ marginTop: 20 }}
                      href={ctaLink}
                      type={ctaType}
                      iconColor={"#000000"}
                    >
                      {cta}
                    </Button>
                  ]
                  : [
                    <H1
                      key="headline"
                      style={{
                        textAlign: "left",
                        textTransform: "uppercase"
                      }}
                      dangerouslySetInnerHTML={{ __html: header }}
                    />,
                    <Subheadline
                      key="text"
                      as="div"
                      dangerouslySetInnerHTML={{ __html: bodytext }}
                    />
                  ]
            }
          >
            {item => {

              const animation = ({ opacity, x }) =>
              (
                <animated.div
                  style={{
                    opacity,
                    transform: x.interpolate(newX => `translateX(${newX}px)`)
                  }}
                >
                  {item}
                </animated.div>
              )


              return animation;
            }
            }
          </Trail>
        </TextWrapper>
        <ImageWrapper image={image}>
          <LazyImage resetKey={new Date()} image={image} layout="fill" />
          {imageOverlay == 1 && (
            <Overlay x="0px" y="0px" viewBox="0 0 820 383.6">
              <path
                d="M818.7,76.2c-0.1-0.3-0.2-0.5-0.4-0.8c-0.1-0.3-0.3-0.6-0.4-0.9l-0.3-0.6l-0.6-0.1
	c-32.1-6.1-88-19-147.1-32.7C588.3,22.3,503.9,2.8,477.8,0.3c-19.3-1.9-34.1,13.9-49.5,52.6c-15.3,38.4-31.9,100.1-55.2,193.6
	C268.1,158,232.4,138.9,207.9,157.7c-20.8,16-82.2,77.4-141.6,136.8C42.5,318.3,20,340.7,0.1,360.3l-0.5,0.5l0.2,0.7
	c0.2,0.9,0.4,1.8,0.6,2.8c0.4,1.6,0.7,3.3,1.2,5l0.5,1.9l1.5-1.2c25.3-20.5,50.5-41.9,74.9-62.5c64.8-54.8,120.8-102,138.2-101.3
	c36.1,1.6,103.5,71.6,181.6,152.8c7.5,7.8,15.2,15.8,22.9,23.8c0.7,0.7,1.6,1,2.7,1c0.6,0,1.2-0.1,1.8-0.2
	c10.8-2.7,36.9-27.7,39.9-38.2c0.6-2.1,0.4-3.7-0.7-4.8c-5.6-5.8-9.6-10-13.4-14.1c-7.3-7.7-14.1-15-30.3-31.2
	c3.4-12.5,7.6-29.9,12.4-50.1c17.6-72.8,44.1-182.9,65.6-200.2c13.4-10.8,84.2,0,166.2,12.6c48.3,7.4,103.1,15.8,152,20.5l2.2,0.2
	L818.7,76.2z"
              />
            </Overlay>
          )}
          {icon && (
            <IconWrapper
              icon={icon}
              iconColor={iconColor}
              iconBackground={iconBackground}
            />
          )}
        </ImageWrapper>
      </Container>
    </StageWrapper>
  );
};

Stage.propTypes = {
  overlay: PropTypes.bool,
  icon: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

Stage.defaultProps = {
  overlay: true,
  icon: "",
  subTitle: "",
  title: "Das bieten wir<br>unseren Kunden",
  image: "/static/img/_dummy/stage-services.jpg"
};

export default Stage;
