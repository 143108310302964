import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";

/**
 * Building Grid Areas
 */
function generateGrid(columns) {
  let areas = "";
  for (let i = 0; i < columns; i += 1) {
    if (i > 0) {
      areas += " ";
    }

    areas += "gridArea" + i;
  }

  return areas;
}

const Grid = styled.div`
  display: grid;
  grid-gap: ${pxToRem(20)};
  margin: ${pxToRem(20)} 0;

  ${props =>
    props.padding &&
    css`
      padding: ${pxToRem(props.padding[0])} ${pxToRem(props.padding[1])}
        ${pxToRem(props.padding[2])} ${pxToRem(props.padding[3])};
    `}

      ${props =>
    !props.columns &&
    `grid-template-columns: repeat(auto-fit, minmax(${pxToRem(
      250
    )}, 1fr));`}


  ${props =>
    props.columns &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(${pxToRem(200)}, 1fr));
      align-items: flex-start;
      ${mq.lg(css`
        grid-template-columns: repeat(${props.columns}, 1fr);
      `)}
    `}

    ${props =>
    props.resetMargin &&
    css`
        margin-top: 0;
        margin-bottom: 0;
      `}

    ${props =>
    props.noGap &&
    css`
        grid-gap: 0;
      `}
`;

export default Grid;
