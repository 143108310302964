import React from "react";
import { injectIntl } from "react-intl";
import FlipCardWrapper from "./components/FlipCardWrapper";
import FlipCardInner from "./components/FlipCardInner";
import FlipCardBack from "./components/FlipCardBack";
import FlipCardFront from "./components/FlipCardFront";
import { P, Subheadline } from "../GlobalStyles/GlobalStyles";
import Button from "../Button/Button";

const FlipCard = ({ logo, name, topReference, description, intl }) => {
  return (
    <FlipCardWrapper>
      <FlipCardInner>
        <FlipCardFront>
          <img className="lazyload" alt={name} src={logo} />
        </FlipCardFront>
        <FlipCardBack>
          <img
            className="lazyload"
            alt={name}
            style={{ marginBottom: 10 }}
            src={logo}
          />
          <Subheadline style={{ marginBottom: 0, fontWeight: 600 }} as="span">
            {name}
          </Subheadline>
          <P
            style={{ textAlign: "center", color: "black" }}
            as="div"
            dangerouslySetInnerHTML={{
              __html: topReference ? topReference.description : description
            }}
          ></P>
          {topReference && (
            <Button type="primary" href={topReference.url}>
              {intl.messages["flipcard.more"]}
            </Button>
          )}
        </FlipCardBack>
      </FlipCardInner>
    </FlipCardWrapper>
  );
};

export default injectIntl(FlipCard);
