import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import Container from "../../Container/Container";
import { StyledButton } from "../../Button/Button";
import { H2, P } from "../../GlobalStyles/GlobalStyles";
import { between } from "polished";

const StageWrapper = styled.section`
  background: url('/static/img/stage_confirmation.jpg') #f4f4f4;
  background-size: cover;
  position: relative;
  overflow: hidden;
  color: white;

  ${H2},
  ${P} {
    color: white;
  }
  ${H2} {
    margin-bottom: ${pxToRem(10)};
    font-size: ${between("18px", "20px")};
  }
  ${P} {
    margin-bottom: ${pxToRem(30)};
  }
  ${Container} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: ${pxToRem(40)};
    padding-bottom: ${pxToRem(40)}
  }
  .bottle {
    max-width: 60%;
    width: ${pxToRem(200)};
    height: auto;
    margin-bottom: ${pxToRem(40)};
  }
  ${StyledButton} {
    margin: ${pxToRem(10)}
  }

  ${mq.lg(css`
    flex-direction: column;
    padding: ${pxToRem(10)} 20px ${pxToRem(10)} 20px;
    min-height: ${pxToRem(450)};

    ${Container} {
      margin-top: ${pxToRem(40)};
    }
  `)}

  ${mq.xxl(css`
    min-height: ${pxToRem(550)};

    ${Container} {
      padding: 0 20px;
    }
  `)}
`;

export default StageWrapper;
