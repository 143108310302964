import React from "react";
import Section from "../Section/Section";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";

const HtmlWrapper = styled.div`
.embed-survey {
min-height:880px;
${mq.sm(css`
min-height:800px;
`)}
  ${mq.md(css`
  min-height:0;
  `)}

}
`;
const EmbedHtml =({bodytext}) => {
    return (
      <Section as="div">
      <HtmlWrapper>
        <div dangerouslySetInnerHTML={{ __html: bodytext }}></div>
      </HtmlWrapper>

      </Section>
    )

  };
  
  export default EmbedHtml;