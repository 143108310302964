import Router from 'next/router'

const redirect = (params) => {
  const { res, location = "/", status = 302 } = params

  if (res) {
    // Seems to be the version used by zeit
    res.writeHead(status, {
      Location: location,
      // Add the content-type for SEO considerations
      'Content-Type': 'text/html; charset=utf-8',
    })
    res.end()
    return
  }

  Router.replace(location)
}

export default redirect