import React from "react";
import Media from "react-media";
import InfoSlideWrapper from "./components/InfoSlideWrapper";
import { H2, H3 } from "../../../GlobalStyles/GlobalStyles";
import { hexToRgba } from "@/lib/functions";
import Accordion from "../../../Accordion/Accordion";
import { breakpoints } from "@/lib/mediaQueries";
import Grid from "../../../Grid/Grid";
import Container from "../../../Container/Container";
import TextWrapper from "../../components/TextWrapper";
import FlyoutContainer from "../../../Flyout/FlyoutContainer";

const InfoSlide = ({
  gradient,
  backgroundImg,
  title,
  index,
  setIndexState,
  indexState,
  opened,
  items,
  isMobile
}) => {
  const handleClick = () => {
    opened ? setIndexState(-1) : setIndexState(index);
  };

  return (
    <Media query={{ maxWidth: breakpoints.lg }} defaultMatches={isMobile}>
      {matches =>
        matches ? (
          <Accordion triggerWidth={breakpoints.lg} arrow={false}>
            <InfoSlideWrapper
              onClick={() => handleClick()}
              gradient={gradient}
              backgroundImg={backgroundImg}
              open={opened}
              indexState={indexState}
            >
              {opened ? (
                <H2 style={{ color: "white" }}>{title}</H2>
              ) : (
                  <H3>{title}</H3>
                )}
            </InfoSlideWrapper>
            {opened && (
              <FlyoutContainer
                open={true}
                indexState={indexState}
                borderColor="#cc1e2a"
                borderWidth={10}
              >
                <Container>
                  <Grid columns={1}>
                    {indexState >= 0 &&
                      items[indexState].subItems.length > 0 &&
                      items[indexState].subItems.map((subItem, subIndex) => {
                        return (
                          <TextWrapper
                            key={subIndex}
                            showItems={index === indexState}
                          >
                            <H2 as="h4">{subItem.header}</H2>
                            <p>{subItem.bodytext}</p>
                          </TextWrapper>
                        );
                      })}
                  </Grid>
                </Container>
              </FlyoutContainer>
            )}
          </Accordion>
        ) : (
            <InfoSlideWrapper
              onClick={handleClick}
              gradient={gradient}
              backgroundImg={backgroundImg}
              open={opened}
              indexState={indexState}
            >
              {opened ? (
                <H2 style={{ color: "white" }}>{title}</H2>
              ) : (
                  indexState === -1 && <H3>{title}</H3>
                )}
            </InfoSlideWrapper>
          )
      }
    </Media>
  );
};

InfoSlide.defaultProps = {
  backgroundImg:
    "/static/img/_dummy/referenzen/John-Reed/Case-John-Reed-Screen-frei.png",
  title: "Aussagekräftiger Titel",
  gradient: hexToRgba("#00ccda")
};

export default InfoSlide;
