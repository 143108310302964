import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { P } from "../../GlobalStyles/GlobalStyles";
import { pxToRem } from "@/lib/functions";

const SocialButtonsWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  text-align: left;
  pointer-events: none;
  
  > div {
    position: sticky;
    top: 100px;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: flex-end;
    z-index: 9;
  }
  
`;



export default SocialButtonsWrapper;
