import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";

const StyledSVG = styled.svg`
  cursor: pointer;
  width: ${pxToRem(45)};
  margin: 0 20px;
  opacity: 0.2;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `};
`;

export default StyledSVG;
