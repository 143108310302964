import styled from "@emotion/styled";
import SVG from "react-inlinesvg";
import { pxToRem } from "@/lib/functions";
import theme from "@/styles";
import mq from "@/lib/mediaQueries";
import { css } from "@emotion/react";

const DownloadModuleTypeWrapper = styled.div`
padding-top:${pxToRem(5)};
width: ${pxToRem(28)};
margin-left: 10px;
svg {
  fill:${theme.colors.secondary};
}

${mq.lg(css`
margin-left: 0px;
`)}

`;

const DownloadModuleType = (props) => {
  const {
    filetype
  } = props;

  if (filetype && filetype === "pdf") {
    return <DownloadModuleTypeWrapper><SVG src={`/static/svg/download-pdf.svg`} /></DownloadModuleTypeWrapper>

  }
  else {
    return <DownloadModuleTypeWrapper><SVG src={`/static/svg/download-file.svg`} /></DownloadModuleTypeWrapper>
  }
}

export default DownloadModuleType; 