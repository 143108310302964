import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";

const SectionDescription = styled.p`
  margin: 20px 0;
  
  ${mq.lg(css`
    margin: 20px 80px;
  `)}
`;

export default SectionDescription;
