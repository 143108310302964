import styled from "@emotion/styled";
import SVG from "react-inlinesvg";
import { pxToRem } from "@/lib/functions";

const StickyLayerIcon = styled(SVG)`
  width: ${pxToRem(25)};
  height: ${pxToRem(25)};
  fill: white;
  padding: 2px;
`;

export default StickyLayerIcon;
