import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";

const Row = styled.div`
  justify-content: space-around;

  .transition {
    transition: all 0.4s ease-in-out;
  }

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: column-reverse;
      ${mq.lg(css`
        flex-direction: row;
      `)}
    `}
`;

export default Row;
