import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { fontSizeRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const CardTitle = styled.div`
  font-weight: 600;
  font-size: ${fontSizeRem(20)};
  color: white;
  min-height: 2.6em;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.08rem;

  ${mq.lg(css`
    text-align: left;
    display: flex;
    align-items: center;
  `)}
`;

export default CardTitle;
