import React from "react";
import { css } from "@emotion/react";
import theme from "@/styles";
import { Subheadline, P } from "../GlobalStyles/GlobalStyles";
import LazyImage from "../LazyImage/LazyImage";
import { pxToRem } from "@/lib/functions";
import Button from "../Button/Button";
import Link from 'next/link';

const NewsTeaser = props => {
  const { categories, falMedia, title, teaser, url, teaserImage } = props;
  return (
    <div
      css={css`
        background: ${theme.colors.white};
        box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
        margin: ${pxToRem(20)} 0;
      `}
    >
      <a target="_blank" href={url} css={css`
                  text-decoration: none;
                `} rel="noreferrer">
        {teaserImage && (
          <div
            css={css`
            height: 305px;
                  line-height: 0;
                  position: relative;
                `}
          >
            <LazyImage
              layout="fill"
              image={teaserImage}
            />
          </div>
        )}

        <div
          css={css`
                background-color: ${theme.colors.primary};
                color: ${theme.colors.white};
                display: inline-block;
                text-transform: uppercase;
                text-align: center;
                padding: ${pxToRem(3)} ${pxToRem(20)};
                font-weight: 800;

                &.cat-2 {
                  background-color: ${theme.colors.secondary};
                }

                &.cat-3 {
                  background-color: ${theme.colors.greyLight};
                }
              `}
          className={`cat${categories && categories.length > 0 ? `-${categories[0].uid}` : ""
            }`}
        >
          <span
            css={css`
                  font-size: 0.75rem;
                `}
          />
          {categories && categories.length > 0
            ? categories[0].title
            : "Allgemein"}
        </div>
        <div
          css={css`
                padding: ${pxToRem(10)} ${pxToRem(20)};
              `}
        >
          <Subheadline
            bold
            css={css`
                  margin-bottom: ${pxToRem(10)};
                `}
          >
            {title}
          </Subheadline>
          <P style={{ color: theme.colors.greyLight }}>{teaser}</P>
          <Button
            iconColor={"#E42321"}
            style={{ marginTop: 0, marginBottom: 20 }}
            href={url}
            type="blank"
          >
            Weiterlesen
          </Button>
        </div>
      </a>

    </div>
  );
};

export default NewsTeaser;
