import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import Container from "../../Container/Container";

const slideOpen = keyframes`
  0% {
    max-height: 0;
  }

  100% {
    max-height: 100%;
  }
`;

export const BorderTriangle = styled.div`
  width: 0;
  height: 0;
  ${({ borderColor, borderWidth }) =>
    css`
      border-left: ${pxToRem(borderWidth)} solid transparent;
      border-right: ${pxToRem(borderWidth)} solid transparent;
      border-top: ${pxToRem(borderWidth)} solid ${borderColor};
    `}
  display: none;
  position: relative;
  transition: left 0.6s ease;
`;

export const FlyoutBorder = styled.div`
  display: none;

  ${({ borderColor, borderWidth }) =>
    css`
      border-top: ${pxToRem(borderWidth)} solid ${borderColor};
      border-bottom: ${pxToRem(borderWidth)} solid ${borderColor};
    `}
`;

const FlyoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;

  ${Container} {
    padding: ${pxToRem(40)} 20px;
  }

  ${({ open, indexState }) =>
    open &&
    css`
      animation: ${slideOpen} 0.6s ease;

      ${BorderTriangle} {
        display: block;
        left: calc(20% * 0.7 * ${indexState > 0 ? indexState : 0.7});
      }

      ${FlyoutBorder} {
        display: block;
      }
    `}
`;

export default FlyoutWrapper;
