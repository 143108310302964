import React from "react";
import dynamic from "next/dynamic";
import { injectIntl } from "react-intl";
import Section from "../Section/Section";
import LazyImage from "../LazyImage/LazyImage";
import { breakpoints } from "@/lib/mediaQueries";
import css from "@emotion/css";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import Technology from "../Technologies/components/Technology/Technology";

const Swiper = dynamic(() => import("../Swiper/Swiper"));

const colorSettings = {
  digital: { from: "#07c1d8", to: "#038daf" },
  ecommerce: { from: "#E42321", to: "#E42321" },
  online: { from: "#000000", to: "#000000" }
};

const swiperSettings = {
  slidesPerView: 1,
  overflow: "visible",
  breakpointsInverse: true,
  showPagination: true,
  paginationSettings: {
    color: "#d8d8d8",
    position: "top",
    visibleOnDesktop: true
  },
  breakpoints: {
    [breakpoints.xs]: {
      slidesPerView: 1,
      centeredSlides: true
    },
    [breakpoints.sm]: {
      slidesPerView: 1,
      centeredSlides: false
    },
    [breakpoints.md]: {
      slidesPerView: 2,
      centeredSlides: false
    },
    [breakpoints.lg]: {
      slidesPerView: 3
    }
  }
};

const swiperSettingsFooter = {
  slidesPerView: 2,
  showNavigation: true,
  paginationSettings: {
    color: "#d8d8d8",
    position: "top"
  },
  breakpointsInverse: true,
  breakpoints: {
    [breakpoints.sm]: {
      slidesPerView: 3
    },
    [breakpoints.md]: {
      slidesPerView: 4
    },
    [breakpoints.lg]: {
      slidesPerView: 5
    },
    [breakpoints.xl]: {
      slidesPerView: 7
    }
  }
};

const Image = item => {
  return (
    <div
      css={css`
        height: 40px;
        padding: 0px 20px;
        text-align: center;

        ${mq.lg(css`
          height: ${pxToRem(60)};
          padding: 0px 40px;
          margin: 0;
        `)}

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      `}
    >
      <LazyImage isSVG image={item.image} {...item}></LazyImage>
    </div>
  );
};

const TechnologiesSlider = props => {
  if (props.ctype === "technology_sliderAction") {
    return (
      <Section
        style={{ marginTop: `-${pxToRem(50)}` }}
        header={props.intl.messages["technologies.title"]}
        backgroundImage={{ image: "/static/img/section_background1.png" }}
        gradient={colorSettings.digital}
        headerSmall
      >
        <Swiper
          resetMargin
          settings={{
            ...swiperSettings,
            showPagination: false
          }}
          items={
            props.items &&
            props.items.map(technology => {
              return {
                shadow: true,
                square: false,
                filter: false,
                resetMargin: true,
                style: { margin: "0 20px" },
                ...technology
              };
            })
          }
          component={Technology}
        />
      </Section>
    );
  }
  return (
    <Section style={{ margin: 0, padding: 0 }} backgroundColor={"#222222"}>
      <Swiper
        containerStyle={{ margin: 0 }}
        wrapperStyle={{ margin: 0 }}
        settings={swiperSettingsFooter}
        items={props.items}
        component={Image}
      />
    </Section>
  );
};

export default injectIntl(TechnologiesSlider);
