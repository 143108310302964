import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import ContentWrapper from "./ContentWrapper";

const EmployeeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: ${pxToRem(20)};

  img {
    border-radius: 50%;
    width: 100%;
    margin-bottom: ${pxToRem(20)};
  }

  ${({ horizontal }) =>
    horizontal &&
    css`
      ${mq.lg(css`
        margin: 0;
        flex-direction: row;
        align-items: center;
        margin: 0;
        text-align: left;
        white-space: nowrap;

        img {
          width: ${pxToRem(200)};
          margin-bottom: 0;
        }

        p {
          margin-bottom: 0;
        }

        ${ContentWrapper} {
          margin-left: ${pxToRem(20)};
        }
      `)}
    `}
`;

export default EmployeeWrapper;
