import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";

const StickyLayerWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  margin-right: 20px;
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  background-color: #000000;
  color: white;
  font-weight: 300;
  cursor: pointer;
  font-size: 0.875rem;
  border-left: 1px solid white;

  a,
  p,
  strong {
    margin-bottom: 0;
    font-weight: 300;
    color: white;
  }

  strong {
      font-weight: 800;
  }
`;

export default StickyLayerWrapper;
