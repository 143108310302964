import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import { StyledButton as Button } from "../../../../Button/Button";

const NotFoundWrapper = styled.div`
  background-color: ${theme.colors.secondary};
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin: ${pxToRem(80)} 0;
  position: relative;
  overflow: hidden;
  z-index: 1;

  ${Button} {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${mq.lg(css`
    padding: ${pxToRem(20)} ${pxToRem(70)};
  `)}
`;

export default NotFoundWrapper;
