import React, { useState, useEffect } from "react";
import { withRouter } from "next/router";
import { breakpoints } from "@/lib/mediaQueries";
import Media from "react-media";
import Link from 'next/link';
import NavWrapper from "./components/NavWrapper";
import NavList, { SubListWrapper } from "./components/NavList";
import NavItem from "./components/NavItem";
import NavLink from "./components/NavLink";
import Flyout from "./components/Flyout";
import Accordion from "../../../Accordion/Accordion";
import FlyoutLayer from "./components/FlyoutLayer";
import Container from "../../../Container/Container";
import Pointer from "./components/Pointer";
import SVG from "react-inlinesvg";

const Nav = ({
  navItems = [],
  router,
  routeChange,
  isMobile,
  setMainNavOpen,
  mainNavOpen
}) => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [activeSubMenuItem, setActiveSubMenuItem] = useState(null);
  const [subNavOpen, setSubNavOpen] = useState(false);
  const [flyoutHeight, setFlyoutHeight] = useState(0);
  const [accordionStatus, setAccordionStatus] = useState(false);
  const [subAccordionStatus, setSubAccordionStatus] = useState(false);

  let height;

  const setHeight = e => {
    height = e.target.offsetParent.nextSibling.scrollHeight;
    setFlyoutHeight(height)
  }

  const getHeight = () => {
    console.log(height, "getHeight")
    return height;
  }

  const filterNav = subnav => {
    if (subnav) {
      return subnav.filter(item => item.navHide === "0");
    }
    return [];
  };

  useEffect(() => {
    setActiveMenuItem(null);
  }, [routeChange]);

  useEffect(() => {
    if (activeMenuItem && window.innerWidth > breakpoints.lg) {
      setFlyoutHeight(height);
    }
  }, [activeMenuItem]);

  const isActiveRoute = route => {
    return typeof router !== "undefined" && router.asPath === route;
  };

  return (
    <Media defaultMatches={!isMobile} query={{ minWidth: breakpoints.lg }}>
      {matches =>
        matches ? (
          <NavWrapper>
            <NavList>
              {navItems
                .filter(item => item.navHide === "0")
                .map(({ navUrl, navItem, subnav }, index) => {
                  return (
                    <NavItem
                      active={activeMenuItem === navUrl}
                      key={index}
                      mainNavOpen={mainNavOpen}
                    >
                      <Link
                        key={navUrl}
                        href={navUrl}
                        passHref

                      >
                        <NavLink
                          active={
                            isActiveRoute(navUrl) || activeMenuItem === navUrl
                          }
                          onMouseEnter={(e) => {
                            if (filterNav(subnav).length > 0) {
                              setActiveMenuItem(navUrl);
                              setMainNavOpen(true);
                              setHeight(e);
                            }
                          }}
                        >
                          <span>{navItem}</span>
                          <Pointer
                            active={activeMenuItem === navUrl && mainNavOpen}
                          />
                        </NavLink>
                      </Link>

                      {subnav && (
                        <Flyout
                          className="active"
                          mainNavOpen={mainNavOpen} //mainNavOpen
                          active={activeMenuItem === navUrl && mainNavOpen}
                          onMouseLeave={() => {
                            setActiveMenuItem(null);
                            setActiveSubMenuItem(null);
                            setMainNavOpen(false);
                          }}
                        >
                          <Container>
                            <NavList>
                              {subnav
                                .filter(item => item.navHide === "0")
                                .map(({ navUrl, navItem, subnav }, index) => {
                                  return (
                                    <NavItem key={index}>
                                      <Link
                                        key={navUrl}
                                        href={navUrl}
                                        passHref

                                      >
                                        <NavLink
                                          active={
                                            isActiveRoute(navUrl) || activeMenuItem === navUrl
                                          }
                                          onMouseEnter={() => {
                                            if (filterNav(subnav).length > 0) {
                                              setActiveSubMenuItem(navUrl);
                                            }
                                          }}
                                        >
                                          {navItem}
                                          {subnav && <SVG src={`/static/svg/chevron-right.svg`} />}
                                        </NavLink>
                                      </Link>
                                      {subnav && <SubListWrapper active={activeSubMenuItem === navUrl}>
                                        <span>{navItem}</span>
                                        <NavList>
                                          {subnav.map(({ navUrl, navItem }, index) => {
                                            return (
                                              <NavItem key={index}>
                                                <Link
                                                  key={navUrl}
                                                  href={navUrl}
                                                  passHref
                                                >
                                                  <NavLink active={isActiveRoute(navUrl)}>
                                                    {navItem}
                                                  </NavLink>
                                                </Link>
                                              </NavItem>
                                            );
                                          })}
                                        </NavList>
                                      </SubListWrapper>}
                                    </NavItem>
                                  );
                                })}
                            </NavList>
                          </Container>
                        </Flyout>
                      )}
                    </NavItem>
                  );
                })}
            </NavList>
            <FlyoutLayer
              style={{
                transform:
                  activeMenuItem && mainNavOpen
                    ? `scale3d(1,${flyoutHeight},1)`
                    : "scale3d(1,0,1)"
              }}
            />
          </NavWrapper>
        ) : (
          // Mobile Navigation
          <NavWrapper>
            <NavList>
              {navItems
                .filter(item => item.navHide === "0")
                .map(({ navUrl, navItem, subnav }, index, data) => {
                  return (
                    <Accordion
                      key={index}
                      arrow={filterNav(subnav).length > 0}
                      arrowColor={"#000"}
                      onClick={open => setAccordionStatus(open ? index : null)}
                      defaultState={accordionStatus === index}
                    >
                      <NavItem
                        active={activeMenuItem === navUrl}
                        open={
                          accordionStatus === index &&
                          filterNav(subnav).length > 0
                        }
                        last={index + 1 === data.length}
                        key={index}
                      >
                        {filterNav(subnav).length > 0 ? (
                          <NavLink
                            active={
                              isActiveRoute(navUrl) || activeMenuItem === navUrl
                            }
                            onMouseEnter={() => setActiveMenuItem(navUrl)}
                          >
                            {navItem}
                          </NavLink>
                        ) : (
                          <Link
                            key={navUrl}
                            href={navUrl}
                            passHref

                          >
                            <NavLink
                              active={
                                isActiveRoute(navUrl) ||
                                activeMenuItem === navUrl
                              }
                              onMouseEnter={() => setActiveMenuItem(navUrl)}
                            >
                              {navItem}
                            </NavLink>
                          </Link>
                        )}
                      </NavItem>
                      {subnav && (
                        <NavList mobile>
                          {filterNav(subnav).length > 0 && (
                            <NavItem key={subnav.length + 1}>
                              <Link
                                key={navUrl}
                                href={navUrl}
                                passHref

                              >
                                <NavLink active={isActiveRoute(navUrl)}>
                                  {navItem}
                                </NavLink>
                              </Link>
                            </NavItem>
                          )}
                          {filterNav(subnav).map(
                            ({ navUrl, navItem, subnav }, index) => {
                              return (
                                <Accordion
                                  key={index}
                                  arrow={filterNav(subnav).length > 0}
                                  arrowColor={"#000"}
                                  onClick={open => setSubAccordionStatus(open ? index : null)}
                                  defaultState={subAccordionStatus === index}
                                >
                                  <NavItem key={index}>
                                    <Link
                                      key={navUrl}
                                      href={navUrl}
                                      passHref

                                    >
                                      <NavLink active={isActiveRoute(navUrl)}>
                                        {navItem}
                                      </NavLink>
                                    </Link>
                                  </NavItem>
                                  {subnav && <NavList>
                                    {subnav.map(({ navUrl, navItem }, index) => {
                                      return (
                                        <NavItem key={index}>
                                          <Link
                                            key={navUrl}
                                            href={navUrl}
                                            style="font-weight: 500; font-size: 1rem;"
                                            passHref
                                          >
                                            <NavLink active={isActiveRoute(navUrl)}>
                                              {navItem}
                                            </NavLink>
                                          </Link>
                                        </NavItem>
                                      );
                                    })}
                                  </NavList>}
                                </Accordion>
                              );
                            }
                          )}
                        </NavList>
                      )}
                    </Accordion>
                  );
                })}
            </NavList>
          </NavWrapper>
        )
      }
    </Media>
  );
};

export default withRouter(Nav);
