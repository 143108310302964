import styled from "@emotion/styled";
import theme from "@/styles";

const VideoOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  background: black;
  opacity: 0.3;
`;

export default VideoOverlay;
