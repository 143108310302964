import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";

const ReferencePreviewWrapper = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  ${mq.xl(css`
    flex-direction: row;
  `)};

  ${({ column }) =>
    column &&
    css`
      padding: ${pxToRem(40)} 0 0 0;
      ${mq.xl(css`
        flex-direction: column;
      `)}
    `}
`;

export default ReferencePreviewWrapper;
