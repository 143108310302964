import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";

const IconTeaserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    margin-top: ${({ header }) => (header ? 0 : pxToRem(20))};
  }
`;

export default IconTeaserWrapper;
