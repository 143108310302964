import styled from "@emotion/styled";
import theme from "@/styles";

const FlyoutLayer = styled.div`
  transition: all .2s ease .4s;
  opacity: 1;
  will-change: transform;
  transform-origin: 50% 0;
  background-color: ${theme.colors.greyLighter};
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: calc(100% + 5px);
  height: 1px;
  width: 100%;
  overflow: hidden;
`;

export default FlyoutLayer;
