import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { Subheadline } from "../../GlobalStyles/GlobalStyles";

const Title = styled(Subheadline)`
  color: white;
  font-weight: 600;
  padding: 1rem;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 0;

  ${mq.lg(css`
    padding: 1rem 0;
    margin-left: 0;
  `)}
`;

export default Title;
