import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const ImageSliderWrapper = styled.div`
  .swiper-pagination-bullets {
    display: block !important;
    bottom: 0;
  }

  .swiper-pagination {
    position: relative;
  }

  .swiper-pagination-bullet {
    ${mq.lg(css`
      margin: 0 ${pxToRem(8)};
    `)}
  }
`;

export default ImageSliderWrapper;
