import React, { useState, useEffect } from "react";
import Select from "react-select";
import CustomSelectWrapper from "./components/CustomSelectWrapper";
import CustomSelectLabel from "./components/CustomSelectLabel";

const CustomSelect = ({ onChange, options, label, value, defaultValue = 0}) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  return (
    <CustomSelectWrapper>
      {label && <CustomSelectLabel>{label}</CustomSelectLabel>}
      <Select
        id={label}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        options={options}
        className="custom-select"
        classNamePrefix="custom-select"
        isSearchable={false}
      />
    </CustomSelectWrapper>
  );
};

CustomSelect.defaultProps = {
  options: [
    {
      value: 0,
      label: "Alle Branchen"
    },
    {
      value: 1,
      label: "Online Marketing"
    }
  ],
  label: ""
};

export default CustomSelect;
