import styled from "@emotion/styled";
import theme from "@/styles";

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  a {
    color: ${theme.colors.black};
  }

  svg {
    fill: ${theme.colors.primary};
    margin-right: 10px;
  }
`;

export default IconWrapper;
