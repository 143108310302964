import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { P } from "../../GlobalStyles/GlobalStyles";
import { pxToRem } from "@/lib/functions";

const SocialLinksWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${mq.md(css`
    flex-direction: row;
    justify-content: center;

    ${P} {
      margin-right: ${pxToRem(20)};
    }
  `)}
`;

export default SocialLinksWrapper;
