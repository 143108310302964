import React from "react";
import Grid from "../Grid/Grid";
import Section from "../Section/Section";
import selectComponent from "@/lib/selectComponent";

import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";

const Gridelement = props => {
  return (
    <Section {...props}>
      {props.columns && (
        <Grid columns={props.columns}>
          {props.content &&
            props.content.map((col, index) => {
              if (col.length > 0) {
                return col.map((module, index) => {
                  return <ErrorBoundary key={index}>
                    {selectComponent(module, null, {
                      key: index,
                      columns: props.columns
                    })}
                  </ErrorBoundary>
                });
              }
            })}
        </Grid>
      )}
    </Section>
  );
};

export default Gridelement;
