import React, { useState, useCallback } from "react";
import dynamic from "next/dynamic";
import Media from "react-media";
import MasonryGallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import GalleryWrapper from "./components/GalleryWrapper";
import Section from "../Section/Section";
import Button from "../Button/Button";
import { breakpoints } from "@/lib/mediaQueries";
import ImageWrapper from "./components/ImageWrapper";
import ImageTitle from "./components/ImageTitle";
import ContentWrapper from "./components/ContentWrapper";
import LazyImage from "../LazyImage/LazyImage";

const Swiper = dynamic(() => import("../Swiper/Swiper"));

const LOAD_MORE_DEFAULT = 15;

const swiperSettings = {
  slidesPerView: 1,
  overflow: false,
  breakpointsInverse: true,
  showNavigation: true,
  showPagination: true,
  navigationSettings: {
    visibleOnDesktop: true,
    position: "inline",
    color: "#ffffff",
    width: 35,
    left: 30,
    right: 30
  },
  paginationSettings: {
    color: "#ffffff",
    visibleOnDesktop: true
  },
  breakpoints: {
    [breakpoints.xs]: {
      slidesPerView: 1
    }
  }
};

const Image = ({ image }) => {
  return (
    <ImageWrapper>
      <ContentWrapper>
        <LazyImage image={image} />
        <ImageTitle>{image.title}</ImageTitle>
      </ContentWrapper>
    </ImageWrapper>
  );
};

const Gallery = ({
  header,
  cta,
  ctaType,
  items,
  variant,
  bodytext,
  isMobile
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [showLimit, setShowLimit] = useState(LOAD_MORE_DEFAULT);

  const handleLoadMore = () => {
    setShowLimit(showLimit + LOAD_MORE_DEFAULT);
  };

  const openMasonryLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const openLightbox = (item, index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const masonryPhotos =
    items &&
    items.slice(0, showLimit).map(item => {
      return {
        src: item.image.image,
        width: item.image.width,
        height: item.image.height
      };
    });

  return (
    <Section header={header} bodytext={bodytext}>
      <GalleryWrapper>
        {variant === 0 ? (
          <Media defaultMatches={isMobile} query={{ maxWidth: breakpoints.lg }}>
            {matches =>
              matches ? (
                <MasonryGallery
                  onClick={openMasonryLightbox}
                  photos={masonryPhotos}
                  direction="column"
                  margin={10}
                />
              ) : (
                  <MasonryGallery
                    onClick={openMasonryLightbox}
                    photos={masonryPhotos}
                    direction="column"
                    margin={10}
                    columns={3}
                  />
                )
            }
          </Media>
        ) : (
            <Swiper
              onClick={openLightbox}
              settings={swiperSettings}
              items={items}
              component={Image}
            />
          )}
        {cta && <Button type={ctaType}>{cta}</Button>}
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={masonryPhotos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </GalleryWrapper>
      {variant === 0 && items.length > showLimit && (
        <Button onClick={handleLoadMore} as="button" type="secondary">
          Mehr laden
        </Button>
      )}
    </Section>
  );
};

Gallery.defaultProps = {
  masonry: false
};

export default Gallery;
