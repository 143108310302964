import React from "react";
import SVG from "react-inlinesvg";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import SealWrapper from "./components/SealWrapper";

const Seal = ({ file }) => {
  return (
    <SealWrapper>
      <SVG
        css={css`
          margin: ${pxToRem(10)} auto 0 auto;
          width:90%;
        `}
        src={`/static/svg/${file}`}
      />
    </SealWrapper>
  );
};

export default Seal;
