import { AccordionProps } from "./Accordion.types";
import React, { useState, useEffect, useRef } from "react";
import SVG from "react-inlinesvg";
import AccordionWrapper from "./components/AccordionWrapper";
import Panel from "./components/Panel";
import Toggle from "./components/Toggle";
import IconWrapper from "./components/IconWrapper";


const Accordion: React.FC<AccordionProps> = ({
  children,
  triggerWidth,
  arrow,
  arrowColor,
  plus,
  plusOpacity,
  onClick,
  defaultState,
  border
}) => {
  const [innerWidth, setInnerWidth] = useState(0);
  const [open, setOpen] = useState(defaultState || false);
  const [contentHeight, setContentHeight] = useState(0);
  const [status, setStatus] = useState(
    triggerWidth ? (innerWidth && innerWidth) < triggerWidth : true
  );

  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpen(defaultState);
  }, [defaultState]);

  useEffect(() => {
    if (panelRef && panelRef.current){
      setContentHeight(panelRef.current.scrollHeight);
    }
    
    if (triggerWidth) {
      setStatus(!!innerWidth && innerWidth < triggerWidth);
      setOpen(innerWidth > triggerWidth);
    }
  }, [innerWidth]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    setInnerWidth(window.innerWidth);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
  };

  const onToggle = () => {
    setOpen(!open);
    if (panelRef && panelRef.current){
      setContentHeight(panelRef.current.scrollHeight);
    }
    if (onClick) {
      onClick(!open);
    }
  };

  return (
    <AccordionWrapper>
      <Toggle border={border} onClick={onToggle}>
        {children[0]}
        <IconWrapper
          icon={status}
          opened={open}
          arrow={arrow}
          plus={plus}
          plusOpacity={plusOpacity}
          arrowColor={arrowColor}
        >
          {arrow && <SVG src={`/static/svg/chevron-down.svg`} />}
          {plus && <SVG src={`/static/svg/${open ? "minus" : "plus"}.svg`} />}
        </IconWrapper>
      </Toggle>
      <Panel
        ref={panelRef}
        status={status}
        opened={open}
        contentHeight={contentHeight}
      >
        {children.map((child, index) => index > 0 && child)}
      </Panel>
    </AccordionWrapper>
  );
};

Accordion.defaultProps = {
  triggerWidth: undefined,
  arrow: false,
  arrowColor: "#fff",
  border: false,
  children: [<div key="toggle">Toggle</div>, <div key="content">Content</div>]
};

export default Accordion;
