import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StyledButton as Button } from "../../Button/Button";
import { pxToRem, hexToRgba } from "@/lib/functions";
import { H2, P } from "../../GlobalStyles/GlobalStyles";
import mq from "@/lib/mediaQueries";

const TextWrapper = styled.div`
  flex: ${({ open }) => (open ? 3 : 4)};
  padding: 40px 20px 40px 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -200px 40px;
  position: relative;
  transition: flex 0.6s;
  z-index: 1;

  ${H2} {
    line-height: 1;
    margin-bottom: 20px;
  }

  ${P}{
    margin-bottom: 40px;
  }

  ${mq.lg(css`
    background-image: none;
    padding: ${pxToRem(80)} ${pxToRem(80)} ${pxToRem(80)} 20px;

    ${H2} {
      margin-bottom: ${pxToRem(40)};
    }
  `)}

  ${mq.xl(`
    padding-left: calc(100vw - 85rem - ((100vw - 85rem) / 2) + 20px);
    
    ${P} {
      padding-right: ${({ open }) => (open ? pxToRem(60) : pxToRem(110))};
      height: 5em;
    }

    ${Button} {
      margin-top: ${pxToRem(80)};
    }
  `)}
`;

export default TextWrapper;
