import React from "react";
import { InView } from "react-intersection-observer";
import Countup from "react-countup";
import Section from "../Section/Section";
import Grid from "../Grid/Grid";
import { H1, Subheadline } from "../GlobalStyles/GlobalStyles";

const Facts = props => {
  return (
    <Section backgroundColor="#f3f3f3" {...props}>
      <InView triggerOnce={true} threshold={1}>
        {({ inView, ref, entry }) => (
          <div ref={ref}>
            {inView ? (
              <Grid columns={props.items.length}>
                {props.items.map((item, index) => {
                  return (
                    <Countup
                      key={index}
                      duration={5}
                      delay={0}
                      decimals={0}
                      start={0}
                      end={item.number}
                    >
                      {({ countUpRef }) => (
                        <div>
                          <H1 as="div" ref={countUpRef} />
                          <Subheadline
                            style={{ textAlign: "center", display: "block" }}
                          >
                            {item.subheader}
                          </Subheadline>
                        </div>
                      )}
                    </Countup>
                  );
                })}
              </Grid>
            ) : null}
          </div>
        )}
      </InView>
    </Section>
  );
};

export default Facts;
