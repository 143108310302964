import React from "react";
import dynamic from "next/dynamic";
import { css } from "@emotion/react";
import { injectIntl } from "react-intl";
import Section from "../Section/Section";
import Employee from "./components/Employee/Employee";
import { H4 } from "../GlobalStyles/GlobalStyles";
import mq, { breakpoints } from "@/lib/mediaQueries";

const Swiper = dynamic(() => import("../Swiper/Swiper"));

// const LOAD_MORE_DEFAULT = 8;

const Employees = ({ items = [], intl, id }) => {
  // const [showLimit, setShowLimit] = useState(LOAD_MORE_DEFAULT);
  // const [teamItems, setTeamItems] = useState(items[3]);

  // const handleLoadMore = () => {
  //   setShowLimit(showLimit + LOAD_MORE_DEFAULT);
  // };

  return (
    <Section id={`c${id}`} header={intl.messages["employees.title"]}>
      {items[1] && (
        <>
          <H4 uppercase>{intl.messages["employees.founder.title"]}</H4>
          <div className="row">
            {items[1].length > 0 &&
              items[1].map((item, index) => {
                return (
                  <div key={index} className="col-12 col-sm-6 col-lg-4">
                    <Employee {...item} />
                  </div>
                );
              })}
          </div>
        </>
      )}

      {items[2] && (
        <>
          <H4
            css={css`
              text-transform: uppercase;
            `}
          >
            {intl.messages["employees.manager.title"]}
          </H4>
          <div className="row">
            {items[2].length > 0 &&
              items[2].map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-12 col-sm-6 col-lg-4"
                    style={{ margin: "0 auto" }}
                  >
                    <Employee {...item} />
                  </div>
                );
              })}
          </div>
        </>
      )}

      {items[3] && (
        <>
          {/* <H4 uppercase style={{ marginTop: 20 }}>
            {intl.messages["employees.team.title"]}
          </H4> */}
          <div className="row">
            {items[3].length > 0 &&
              items[3]
                .sort((a, b) => {
                  var nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();
                  if (nameA < nameB)
                    //sort string ascending
                    return -1;
                  if (nameA > nameB) return 1;
                  return 0; //default return value (no sorting)
                })
                .map((item, index) => {
                  return (
                    <div key={index} className="col-6 col-md-4 col-lg-3">
                      <Employee
                        css={css`
                          ${mq.xl(css`
                            padding: 0 20px;
                          `)}
                        `}
                        image={item.image}
                        name={item.name}
                      />
                    </div>
                  );
                })}
          </div>
        </>
      )}

      {/* {teamItems.length > showLimit && (
        <div style={{ marginTop: pxToRem(40), margin: "0 auto" }}>
          <Button type="secondary" onClick={handleLoadMore}>
            Mehr laden
          </Button>
        </div>
      )} */}
    </Section>
  );
};

const swiperSettings = {
  slidesPerView: 1,
  watchOverflow: true,
  centerInsufficientSlides: true,
  breakpointsInverse: true,
  showNavigation: true,
  showPagination: true,
  paginationSettings: {
    color: "#000000",
    position: "top",
    visibleOnDesktop: false
  },
  navigationSettings: {
    color: "#000000",
    left: 20,
    right: 20
  },
  breakpoints: {
    [breakpoints.sm]: {
      slidesPerView: 2
    },
    [breakpoints.lg]: {
      slidesPerView: 3
    },
    [breakpoints.xl]: {
      slidesPerView: 4
    }
  }
};

export const EmployeeSlider = props => {
  // TODO: backgroundColor via Typo3. 477 = dmexco
  return (
    <Section
      {...props}
      backgroundColor={props.id === 477 ? "#f3f3f3" : "#ffffff"}
    >
      <Swiper
        settings={swiperSettings}
        items={props.items.map(item => {
          if (props.type === 'contact') {
            return {
              image: item.image,
              title: item.title,
              name: item.name,
              email: item.email,
              whatsapp: item.whatsapp,
              signal: item.signal,
              phone: item.phone
            }
          } else if (props.type === 'campaign') {
            return {
              image: item.image,
              title: item.title,
              name: item.name,
              email: item.email,
              xing: item.xing,
              phone: item.phone
            }
          }
          return {
            image: item.image,
            title: item.title,
            name: item.name,
            email: "career@brandung.de",
            xing: item.xing,
            linkedin: item.linkedin,
            whatsapp: item.whatsapp,
            signal: item.signal
          };
        })}
        component={Employee}
        width={90}
      />
    </Section>
  );
};

export default injectIntl(Employees);
