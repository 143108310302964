import React, { useRef } from "react";
import SectionWrapper from "./component/SectionWrapper";
import Container from "../Container/Container";
import SectionDescription from "./component/SectionDescription";
import Button from "../Button/Button";
import Arrow from "./component/Arrow";
import { H2, H3, Subheadline } from "../GlobalStyles/GlobalStyles";
import { SectionProps } from "./Section.types";

const Section: React.FC<SectionProps> = ({
  arrow,
  arrowPosition,
  arrowColor,
  children,
  header,
  headerStyle,
  subheader,
  gradient,
  headerSmall,
  as,
  bodytext,
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  cta,
  ctaLink,
  ctaType,
  overflow,
  style,
  className,
  id,
}) => {
  const sectionRef = useRef<HTMLInputElement | null>(null);

  const renderSectionContainer = () => {
    return (
      <Container>
        {(header || bodytext) && (
          <div style={{ textAlign: "center" }}>
            {header && !headerSmall && (
              <H2
                style={headerStyle}
                as={as || "h2"}
                dangerouslySetInnerHTML={{ __html: header }}
              />
            )}
            {header && headerSmall && (
              <H3
                style={{ color: "white " }}
                as={as || "h3"}
                dangerouslySetInnerHTML={{ __html: header }}
              />
            )}
            {subheader && <Subheadline bolder>{subheader}</Subheadline>}
            {bodytext && (
              <SectionDescription
                as="div"
                dangerouslySetInnerHTML={{ __html: bodytext }}
              />
            )}
          </div>
        )}

        {children && children}

        {cta && (
          <Button href={ctaLink} type={ctaType}>
            {cta}
          </Button>
        )}
      </Container>
    );
  };

  return (
    <SectionWrapper
      id={id}
      ref={sectionRef}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage && backgroundImage.image}
      backgroundImageWebp={backgroundImage && backgroundImage.webp}
      backgroundPattern={backgroundPattern && backgroundPattern}
      gradient={gradient}
      viewOverflow={overflow}
      style={style}
      className={className}
    >
      {arrow ? (
        <>
          <Container>
            <Arrow arrowColor={arrowColor} arrowPosition={arrowPosition} />
          </Container>
          <div style={{ width: "100%", overflow: "hidden" }}>
            {renderSectionContainer()}
          </div>
        </>
      ) : (
          renderSectionContainer()
        )}
    </SectionWrapper>
  );
};

export default Section;
