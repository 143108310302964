import React, { useState, useEffect } from "react";
import NProgress from "nprogress";
import Media from "react-media";
import SVG from "react-inlinesvg";
import Div100vh from "react-div-100vh";
import { breakpoints } from "@/lib/mediaQueries";
import { useRouter } from 'next/router';
import Nav from "./components/Nav/Nav";
import HeaderWrapper from "./components/HeaderWrapper";
import Logo from "./components/Logo/Logo";
import Container from "../Container/Container";
import MobileMenu, {
  LanguageWrapper,
  MobileMenuInner
} from "./components/Nav/components/MobileMenu";
import LanguageSelect from "./components/LanguageSelect/LanguageSelect";
import Hamburger from "./components/Nav/components/Hamburger";
import Link from 'next/link';

const Header = ({ navItems, isMobile, videoStage, meta }) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [mainNavOpen, setMainNavOpen] = useState(false);
  const [transparentHeader, setTransparentHeader] = useState(videoStage);
  const [routeChange, setRouteChange] = useState(false);
  const [lastScrollPos, setLastScrollPos] = useState(0);


  function handleRouteChangeComplete(url) {
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': url
    })

    window.scrollTo(0, 0);
    NProgress.done();
    setOpen(false);
    setMainNavOpen(false);
    setRouteChange(true);
  }

  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return function cleanup() {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    if (typeof window === "object") {
      function handleScroll() {
        setLastScrollPos(window.pageYOffset);
        if (videoStage && window.pageYOffset <= 50) {
          setTransparentHeader(true);
        } else {
          setTransparentHeader(false);
        }
      }

      window.addEventListener("scroll", handleScroll, { passive: true });

      return function cleanup() {
        window.removeEventListener("scroll", handleScroll, { passive: true });
      };
    }
  }, [lastScrollPos]);

  useEffect(() => {
    setTransparentHeader(videoStage);
  }, [videoStage]);

  useEffect(() => {
    if (document.body) {
      if (open) {
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.position = "static";
      }
    }

    return () => {
      if (document.body) {
        document.body.style.overflow = "auto";
        document.body.style.position = "static";
      }
    };
  }, [open]);

  return (
    <Media defaultMatches={!isMobile} query={{ minWidth: breakpoints.lg }}>
      {matches =>
        matches ? (
          <HeaderWrapper mainNavOpen={mainNavOpen}>
            <Container>
              <Logo />
              {navItems && (
                <Nav
                  navItems={navItems.subnav}
                  routeChange={routeChange}
                  setMainNavOpen={setMainNavOpen}
                  mainNavOpen={mainNavOpen}
                  isMobile={isMobile}
                />
              )}

              <LanguageSelect meta={meta} />
            </Container>
          </HeaderWrapper>
        ) : (
          <>
            <HeaderWrapper transparent={transparentHeader} open={open}>
              <Container>
                <Link key={"/"} href={"/"} passHref>
                  <a title="Logo: Brandung GmbH">
                    <SVG
                      style={{ margin: "15px 20px 15px 0" }}
                      width="42"
                      src={`/static/svg/brandung-logo.svg`}
                    />
                  </a>
                </Link>
                <Hamburger
                  transparent={transparentHeader}
                  checked={open}
                  onClick={() => setOpen(!open)}
                >
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </Hamburger>
              </Container>
            </HeaderWrapper>
            {open && (
              /* ios scroll fix*/
              <Div100vh>
                <MobileMenu>
                  <MobileMenuInner>
                    {navItems && (
                      <Nav navItems={navItems.subnav} isMobile={isMobile} />
                    )}
                  </MobileMenuInner>
                  <LanguageWrapper>
                    <LanguageSelect  meta={meta} />
                  </LanguageWrapper>
                </MobileMenu>
              </Div100vh>
            )}
          </>
        )
      }
    </Media>
  );
};

export default Header;
