import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const LanguageSelectWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  min-width: ${pxToRem(150)};
  text-align: right;
  position: relative;
  color: #555555;

  span {
    appearance: none;
    background: transparent;
    border: 0;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2.5px;
    line-height: 1.5rem;
    margin-left: auto;
    text-transform: uppercase;
    transition: color 0.2s;
    padding-right: 1.5em;
    margin-top: 0;
    padding-top: 0;

    @media (min-width: 769px) {
      margin-bottom: 0;
    }
  }

  &.is-open {
    .language-options-wrapper {
      opacity: 1;
      display: flex;
    }
  }

  svg {
    pointer-events: none;
    margin-left: 10px;
  }

  ${mq.xxl(css`
    min-width: ${pxToRem(200)};
  `)}
`;

export default LanguageSelectWrapper;
