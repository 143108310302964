import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import { Subheadline } from "../../GlobalStyles/GlobalStyles";

const TextWrapper = styled.div`
  padding: 21px;

  ${Subheadline} {
    margin-bottom: 0;
  }

  ${mq.lg(css`
    padding: 0;
    max-width: 60%;
    padding: 0 60px 0 0;
    margin-bottom: ${pxToRem(50)};

    ${Subheadline} {
      margin-bottom: 0;
      margin-top: ${pxToRem(40)};
    }
  `)}
`;

export default TextWrapper;
