import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "@/styles";

import Container from "../../Container/Container";

const HeaderWrapper = styled.header`
  align-items: center;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.greyLighter};
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  transition: .2s ease-in-out .4s;

  .line {
    background: black;
  }

  ${({ mainNavOpen }) =>
    mainNavOpen &&
    css`
      transition: 0s ease-in-out 0.3s;
      border-bottom: 5px solid ${theme.colors.primary};
    `}

  ${({ transparent, open }) =>
    transparent &&
    css`
      background-color: ${!open ? "transparent" : theme.colors.white};
      border-bottom: ${open ? `2px solid ${theme.colors.greyLighter};` : "0px"};

      .line {
        background: white;
      }
    `}

  ${Container} {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }
`;

export default HeaderWrapper;
