import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const ImageWrapper = styled.div`
  display: block;
  position: relative;
  height: ${pxToRem(380)};
  margin-left: -60px;
  margin-right: -90px;
  left:0;
  right: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    position: absolute;
  }

  ${mq.sm(css`
    height: ${pxToRem(500)};
    margin-left: -${pxToRem(100)};
  `)}

  ${mq.lg(css`
    border-radius: 100%;
    background-position: -${pxToRem(120)} 100px;
    position: absolute;
    display: block;
    width: ${pxToRem(580)};
    height: ${pxToRem(550)};
    left: auto;
    right: -${pxToRem(100)};
    top: -${pxToRem(130)};
  `)}

  ${mq.xxl(css`
    width: ${pxToRem(640)};
    height: ${pxToRem(600)};
  `)}
`;

export default ImageWrapper;
