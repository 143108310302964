import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";

const IconWrapper = styled.div`
  position: absolute;
  height: 3rem;
  width: 13rem;
  left: 20px;
  top: 20px;
  overflow: hidden;
  
  img,
  embed {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  ${({ stage }) =>
    stage &&
    css`
      top: 0;
    `}

  ${mq.lg(css`
    height: 6rem;
    width: 19rem;
  `)}
`;

export default IconWrapper;
