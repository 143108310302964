import styled from "@emotion/styled";

const ListItem = styled.li`
  list-style-type: none;
  padding: 10px 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export default ListItem;
