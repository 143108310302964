import styled from "@emotion/styled";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const FormWrapper = styled.div`
  padding: ${({ formOnly }) =>
    formOnly ? `0 0 ${pxToRem(40)} 0` : `${pxToRem(40)} 0`};

  form {
    width: 100%;
  }

  .success-message,
  .error-message {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    padding: ${pxToRem(20)};
    margin: ${pxToRem(20)} 0;
    text-align: center;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);

    &:before {
      content: "";
      margin-right: 20px;
      background-size: 30px 30px;
      width: 30px;
      height: 30px;
      fill: white;
    }
  }

  .success-message {
    background-color: #32b000;

    &:before {
      background-image: url("/static/svg/check-white.svg");
    }
  }

  .error-message {
    background-color: #e42131;

    &:before {
      background-image: url("/static/svg/alert.svg");
    }
  }

  .form-group {
    margin: 20px 0;
    position: relative;

    &--inline {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;
      padding: ${pxToRem(10)} 0;

      & > * {
        flex: 1;
      }

      button {
        margin-left: ${pxToRem(30)};
      }

      @media (max-width: 768px) {
        flex-direction: column;

        button {
          margin-left: 0;
          width: 100%;
        }
      }
    }

    .input {
      background: none;
      border: none;
      border-bottom: 1px solid ${theme.colors.white};
      color: ${theme.colors.white};
      display: flex;
      flex: 1;
      flex-direction: column;
      outline: none;
      padding: 10px 0;
      margin-bottom: 10px;

      &::placeholder {
        color: ${theme.colors.white};
        text-transform: unset;
      }

      &--transparent {
        & ~ .parsley-errors-list {
          color: ${theme.colors.white};
        }

        &.parsley-error {
          border: none;
          border-bottom: 1px solid #e42131;
          color: ${theme.colors.white};

          &::placeholder {
            color: ${theme.colors.white};
            text-transform: unset;
          }
        }

        &.parsley-success {
          border: none;
          border-bottom: 1px solid #32b000;
        }
      }
    }

    .control-label {
      display: none;
    }
  }

  .form__field-wrapper {
    position: relative;
    margin: 20px 0;
  }

  /* TEXT INPUT STYLES */
  input[type="text"],
  input[type="date"],
  input[type="number"],
  input[type="email"],
  textarea {
    border: 1px solid #d4d4d4;
    width: 100%;
    padding: ${pxToRem(14)} 20px;
    box-sizing: border-box;

    &.parsley-success {
      color: #32b000;
      border: 1px solid #32b000;
      outline: none;
    }

    &.parsley-error {
      border: 1px solid #e42131;
      outline: none;
      color: #e42131;
      ::-webkit-input-placeholder {
        color: #e42131;
      }

      ::-moz-placeholder {
        color: #e42131;
      }

      ::-ms-placeholder {
        color: #e42131;
      }

      ::placeholder {
        color: #e42131;
      }
    }

    ::-webkit-input-placeholder {
      text-transform: uppercase;
      color: #929292;
    }

    ::-moz-placeholder {
      text-transform: uppercase;
      color: #929292;
    }

    ::-ms-placeholder {
      text-transform: uppercase;
      color: #929292;
    }

    ::placeholder {
      text-transform: uppercase;
      color: #929292;
    }
  }

  textarea {
    min-height: ${pxToRem(300)};
  }

  input[type="number"] {
    max-width: ${pxToRem(50)};
    text-align: center;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="date"] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .flatpickr {
    background: white;

    &:after {
      content: attr(placeholder) !important;
      position: relative;
      z-index: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      color: #929292;
      white-space: nowrap;
      left: -20px;
      top: 0;
      max-width: ${pxToRem(200)};
    }

    &:focus:after {
      content: "" !important;
    }
  }

  .quantity-button {
    font-size: 25px;
    width: 15px;
    text-align: center;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &--down {
      margin-right: 20px;
    }

    &--up {
      margin-left: 20px;
    }
  }

  /* SELECT STYLES */

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
  }
  select,
  .select {
    border-radius: 0px;
    display: block;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    padding: ${pxToRem(14)} 20px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    text-transform: uppercase;
    border: solid 1px #d4d4d4;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    background-image: url("/static/svg/chevron-down.svg");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    option {
      font-weight: normal;
    }

    ::-ms-expand {
      display: none;
    }

    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
    }
  }

  /* RADIO BUTTON STYLES */

  .radio-field {
    position: relative;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      padding: ${pxToRem(14)};
    }
    line-height: 1.15;

    label {
      position: relative;
      background-color: #fff;
      color: #929292;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      line-height: 1.15;
      border: 1px solid #d4d4d4;
      display: inline-block;
      cursor: pointer;
      padding: ${pxToRem(15)};

      &:hover {
        color: #038daf;
        font-weight: 800;
        border: 1px solid #038daf;
      }
    }

    input:checked + label {
      color: #038daf;
      font-weight: 800;
      border: 1px solid #038daf;
    }

    &.parsley-success {
      color: #929292;
      border: 1px solid #d4d4d4;
      outline: none;
    }
  }

  /* CHECKBOX */

  .checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    border: 1px solid #d4d4d4;
  }

  .checkbox:hover input ~ .checkmark {
    background-color: #038daf;
  }

  .checkbox input:checked ~ .checkmark {
    background-color: #038daf;
    &:after{
      content:'';
      height: 20px;
      width: 20px;
      background:url('/static/svg/check-white.svg');
      display:block;
      background-size:contain;
      position:absolute;
      top:2px;
      left:4px;
    }
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  .checkbox .checkmark:after {
    /* left: 2px;
    top: 3px;
    width: 20px;
    height: 20px;
    border: 2px solid white; */
  }

  #dataPrivacy {
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
  }

  /* UPLOAD STYLES */
  .form__upload {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
    }

    input.parsley-error ~ label {
      border: 1px solid #e42131;
    }

    .parsley-errors-list {
      bottom: -40px;
    }

    small {
      margin: 10px 0 0;
    }

    input {
      appearance: none;
      resize: none;
      position: absolute;
      opacity: 0;
      z-index: 3;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .form__description {
    position: absolute;
    top: -30px;
    color: #555555;
    font-weight: 600;
  }

  .form__label {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    padding: ${pxToRem(23)} 0;
    background: white;
    width: 100%;
    border: solid 1px #d4d4d4;
    color: #737373;
    font-weight: 200;
    letter-spacing: 0.03rem;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    order: -1;
    overflow: hidden;
  }

  .form__placeholder {
    position: absolute;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: #929292;
    white-space: nowrap;
    left: 250px;
    margin-bottom: 0px;
    top: 50%;
    transform: translateY(-50%);
    left: ${pxToRem(250)};
    max-width: ${pxToRem(200)};
  }

  .form__button {
    position: absolute;
    display: flex;
    z-index: 2;
    width: auto;
    height: 100%;
    align-self: flex-end;
    color: white;
    background: #038daf;
    letter-spacing: 0.05rem;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding: ${pxToRem(14)} ${pxToRem(25)};
    text-transform: uppercase;
    left: 0;
  }

  /* PARSLEY STYLES */

  .parsley-errors-list {
    position: absolute;
    list-style-type: none;
    font-size: 0.75rem;
    color: #e42131;
    padding-top: 0;
    padding-left: 0;
    margin-top: 0;
  }

  .parsley-success {
    color: #32b000;
    border: 1px solid #32b000;
    outline: none;
  }

  .parsley-error {
    border: 1px solid #e42131;
    outline: none;
    color: #e42131;
  }

  label.parsley-error {
    border: none;
  }

  .checkbox.parsley-success {
    border: none;
    color: black;
  }

  label ~ .parsley-errors-list {
    display: none;
  }
`;

export default FormWrapper;
