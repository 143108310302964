import React from "react";

import Script from "next/script";
import Section from "@/components/Section/Section";

declare global {
  interface Window {
    hbspt?: any;
  }
}
const EmbedHubspot = ({ src, portalId, formId }) => {
  return (
    //   @ts-ignore
    <Section as="div">
      <Script
        src={src}
        onLoad={() => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: portalId,
              formId: formId,
              target: `#hubspot`,
            });
          }
        }}
      ></Script>
      <div id="hubspot"></div>
    </Section>
  );
};

export default EmbedHubspot;
