import styled from "@emotion/styled";

const LocationWrapper = styled.div`
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  padding-bottom: 100%;
  transform: scale(1);
  margin: 20px 0;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.03);
  }

  h2 {
    position: absolute;
    bottom: 0;
    left: 20px;
    color: white;
  }
`;

export default LocationWrapper;
