import React, { useState } from "react";
import StickyLayerWrapper from "./components/StickyLayerWrapper";
import StickyLayerBox from "./components/StickyLayerBox";
import StickyLayerIcon from "./components/StickyLayerIcon";
import StickyLayerFlyout from "./components/StickyLayerFlyout";
import { P } from "../GlobalStyles/GlobalStyles";

const StickyLayer = ({ icon, title }) => {
  const [showFlyout, setShowFlyout] = useState(false);

  return (
    <StickyLayerWrapper>
      <StickyLayerBox onMouseEnter={() => setShowFlyout(true)}>
        {icon && <StickyLayerIcon src={`/static/svg/${icon}.svg`} />}
        {title}
      </StickyLayerBox>

      <StickyLayerFlyout
        open={showFlyout}
        onMouseLeave={() => setShowFlyout(false)}
      >
        <div>
          {showFlyout && (
            <>
              <P>
                <strong>Nimm Kontakt zu uns auf</strong>
              </P>
              <P>
                02213123123 <br />
                <a href="">career@brandung.de</a>
              </P>
            </>
          )}
        </div>
      </StickyLayerFlyout>
    </StickyLayerWrapper>
  );
};

StickyLayer.defaultProps = {
  icon: "contact",
  title: "Kontakt"
};

export default StickyLayer;
