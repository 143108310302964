import styled from "@emotion/styled";
import css from "@emotion/css";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const RatingIcon = styled.svg`
  fill: ${({ full }) => (full ? theme.colors.secondary : "#e6f4f7")};
  width: ${({ size }) => pxToRem(size || 14)};
  height: ${pxToRem(14)};
  margin: 0 ${({ spaceBetween }) => (spaceBetween ? spaceBetween : "5px")};

  path {
    stroke: ${theme.colors.secondary};
    stroke-width: 1px;
  }

  ${({ color, full }) =>
    color &&
    css`
      fill: ${full ? color : "transparent"};

      path {
        stroke: ${color};
      }
    `}
`;

export default RatingIcon;
