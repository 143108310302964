import React from "react";
import dynamic from "next/dynamic";
import Media from "react-media";
import Section from "../Section/Section";
import Technology from "./components/Technology/Technology";
import { breakpoints } from "@/lib/mediaQueries";
import Grid from "../Grid/Grid";

const Swiper = dynamic(() => import("../Swiper/Swiper"));

const swiperSettings = {
  slidesPerView: 2,
  centeredSlides: true,
  overflow: "visible",
  breakpointsInverse: true,
  paginationSettings: {
    color: "#d8d8d8",
    position: "top"
  },
  breakpoints: {
    [breakpoints.md]: {
      slidesPerView: 3,
      centeredSlides: false
    }
  }
};

const Technologies = props => {
  return (
    <Section {...props}>
      <Media
        defaultMatches={props.isMobile}
        query={{ maxWidth: breakpoints.lg }}
      >
        {matches =>
          matches ? (
            <Swiper
              settings={swiperSettings}
              items={props.items.map(item => {
                return {
                  square: true,
                  ...item
                };
              })}
              component={Technology}
            />
          ) : (
              <Grid columns={5}>
                {props.items.map((item, index) => {
                  return <Technology key={index} square={true} {...item} />;
                })}
              </Grid>
            )
        }
      </Media>
    </Section>
  );
};

export default Technologies;
