import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";
import { StyledButton as Button } from "../../Button/Button";

const FlipCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  padding: ${pxToRem(25)} ${pxToRem(30)} ${pxToRem(15)} ${pxToRem(30)};
  z-index: 0;

  background-color: white;
  color: white;
  transform: rotateY(180deg);
  overflow: hidden;

  ${Button} {
    font-weight: normal;
    margin: 0 auto;
  }

  img {
    display: block;
    margin: 0 auto;
    height: ${pxToRem(55)};
  }

  p {
    margin-top: 0;
    margin-bottom: ${pxToRem(5)};
    height: 3.2em;
    overflow: hidden;
  }
`;

export default FlipCardBack;
