import React from "react";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const UspList = props => {
  return props.items.map((element, index) => {
    return (
      <div
        key={index}
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
          marginBottom: pxToRem(12)
        }}
      >
        <p
          css={{
            fontWeight: "bold",
            fontSize: pxToRem(40),
            lineHeight: "100%",
            margin: 0,
            marginRight: 10
          }}
        >
          {element.title}
        </p>
        <p
          css={{
            lineHeight: "100%",
            margin: 0,
            textAlign: "right"
          }}
          dangerouslySetInnerHTML={{ __html: element.description }}
        ></p>
      </div>
    );
  });
};

UspList.defaultProps = {
  items: []
};

export default UspList;
