import React from "react";
import LocationWrapper from "./components/LocationWrapper";
import ImageWrapper from "./components/ImageWrapper";
import StyledSVG from "./components/StyledSVG";
import { H1 } from "../../../GlobalStyles/GlobalStyles";
import LazyImage from "../../../LazyImage/LazyImage";

const Location = ({ uid, icon, image, name, onClick }) => {
  return (
    <LocationWrapper onClick={() => onClick(uid)}>
      <ImageWrapper>
        <LazyImage image={image} layout="fill" objectFit="cover" />
      </ImageWrapper>
      {icon === "koeln" && (
        <StyledSVG
          id="Ebene_1"
          data-name="Ebene 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 90"
        >
          <defs />
          <title>koeln</title>
          <polygon
            points="85.94 0.17 68.56 82.37 51.18 0.17 51.18 0.17 28.63 127.61 51.18 127.93 68.56 127.93 85.94 127.93 108.48 127.61 85.94 0.17"
          />
        </StyledSVG>
      )}
      {icon === "berlin" && (
        <StyledSVG
          id="Ebene_1"
          data-name="Ebene 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 90"
        >
          <defs />
          <title>berlin</title>
          <path
            d="M103.87,81.65c-1.72-6.17-3.88-12.21-5.77-18.34A23.3,23.3,0,0,1,96.73,57c-.15-8.78.06-17.56-.1-26.34-.11-5.79-.63-11.58-1-17.36-.11-1.8-.49-3.42,1-5.12,1.63-1.86-.15-3.58-1.33-5S92.57.29,90.75,1.67c-1.62,1.23-3,.88-4.68.49C81,1,76,.71,72,5.31a3.48,3.48,0,0,1-2.16.8c-7,.83-7,.81-8.11,7.82-.44,2.66.34,4.83,2.67,6.06a25.18,25.18,0,0,0,4.76,1.7c1.91.54,3.87.94,5.68,1.38-2.64,2.88-5.44,5.27-7.4,8.26a5.71,5.71,0,0,1-6.26,2.86c-.88-.12-1.79-.11-2.65-.31C52.23,32.39,46,30.81,43.45,23.37c-1.13-3.25-3.31-4.25-6.72-3.88C33.54,19.84,31.9,21.92,33,25a34.14,34.14,0,0,0,4.11,8C41.21,39,46.74,43.34,52.71,47.08a5.2,5.2,0,0,0,3.85.48c4.22-1.24,8.35-2.8,12.52-4.24-7.17,5.44-14.49,10.5-22,15.3-.78.51-3.33-.15-3.56-.82-1.09-3.21-3.84-3.33-6.19-4.06-3.66-1.14-5.24.55-4.43,4.45,1.57,7.56,12.92,15,20.22,13.07,3-.77,5.88-1.89,8.75-2.83,1.91,3.58,3.79,7,5.59,10.52S71,86,72.76,89.5l-1.05.88-8.59-9.95c-7,4.5-8.72,6.5-9.44,12.26a61,61,0,0,0-.3,9.94c.1,2.91.61,5.8,1,9.26-1.49-.3-2.65-.45-3.76-.77s-2.32-1-3.52-1.26c-2.92-.76-4.36.07-6,3.17-1.22,2.32-.06,4,1.53,5.27a19.29,19.29,0,0,0,5,3.14c3.89,1.52,7.94,2.64,11.87,4.08a2.38,2.38,0,0,0,3.3-1.35A8.81,8.81,0,0,0,64,120.29c-.31-7.86,4.4-12.75,9.33-17.53a5.49,5.49,0,0,1,2.75-1.57,2.92,2.92,0,0,1,2.53,1,29.41,29.41,0,0,0,9.58,11.29c-1.51,1.64-2.88,3.19-4.32,4.68-2.07,2.15-1.5,4.6-.62,6.95a3.71,3.71,0,0,0,4.86,2.35,29.83,29.83,0,0,0,8.26-4.06c2.33-1.75,4-4.44,6-6.62"
          />
        </StyledSVG>
      )}
      {name && <H1 as="h2">{name}</H1>}
    </LocationWrapper>
  );
};

Location.defaultProps = {
  icon: "koeln"
};

export default Location;
