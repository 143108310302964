import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Hamburger = styled.div`
  display: block;
  width: 50px;
  height: 50px;
  position: relative;
  margin-left: auto;

  .line {
    position: absolute;
    left: 10px;
    height: 4px;
    width: 36px;
    background: #000;
    display: block;
    transition: 0.5s;
    transform-origin: center;
  }

  .line:nth-of-type(1) {
    top: 12px;
  }
  .line:nth-of-type(2) {
    top: 24px;
  }
  .line:nth-of-type(3) {
    top: 36px;
  }

  ${({ transparent }) =>
    transparent &&
    css`
      .line {
        background: #fff;
      }
    `};

  ${({ checked }) =>
    checked &&
    css`
      .line {
        background: black !important;
      }

      .line:nth-of-type(1) {
        transform: translateY(12px) rotate(-45deg);
      }

      .line:nth-of-type(2) {
        opacity: 0;
      }

      .line:nth-of-type(3) {
        transform: translateY(-12px) rotate(45deg);
      }
    `}
`;

export default Hamburger;
