import styled from "@emotion/styled";
import Container from "../../Container/Container";

const Sticky = styled.div`
    position: fixed;
    bottom: 0;
    box-shadow: 0 -20px 40px 0 rgba(0, 0, 0, 0.15);
    background: white;
    left: 0;
    margin: 0;
    padding-left: calc(100vw - 85rem - ((100vw - 85rem) / 2));
    width: 100vw;
    z-index: 99;

    ${Container} {
      margin: 0;
      padding: 0;
    }

    .row {
      padding: 0;
    }

    h4 {
      margin-bottom: 0;
    }

    a {
      margin-top: 0;
    }
`;

export default Sticky;