import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";

const ImageWrapper = styled.div`
  align-self: stretch;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${mq.xl(css`
    width: 50%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `)}
`;

export default ImageWrapper;
