import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Container from "../../Container/Container";
import { pxToRem, hexToRgba } from "@/lib/functions";
import ImageWrapper from "./ImageWrapper";
import mq from "@/lib/mediaQueries";

const SlideContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: ${pxToRem(20)} 0;
  overflow: hidden;
  min-height: 187px;

  ${mq.xl(css`
    min-height: ${pxToRem(550)};
  `)}
  
  ${({ imageOnly }) =>
    imageOnly &&
    css`
      padding: ${pxToRem(20)} 0;

      ${mq.lg(css`
        padding: ${pxToRem(40)} 0;
        min-height: 0;
      `)}
    `} 

  ${({ gradient, backgroundImage, backgroundImageWebp }) =>
    gradient &&
    backgroundImage &&
    css`
      .no-webp & {
        background-image: linear-gradient(
            to bottom,
            ${hexToRgba(gradient.from, 0.4)},
            ${hexToRgba(gradient.to, 0.9)}
          ),
          url(${backgroundImage});
      }

      .webp & {
        background-image: linear-gradient(
            to bottom,
            ${hexToRgba(gradient.from, 0.4)},
            ${hexToRgba(gradient.to, 0.9)}
          ),
          url(${backgroundImageWebp});
      }

      background-repeat: no-repeat;
      background-size: cover;
    `}

  ${({ gradient, backgroundImage, backgroundImageWebp }) =>
    !gradient &&
    backgroundImage &&
    css`
      .no-webp & {
        background-image: url(${backgroundImage});
        background-repeat: no-repeat;
        background-size: cover;
      }

      .webp & {
        background-image: url(${backgroundImageWebp});
        background-repeat: no-repeat;
        background-size: cover;
      }
    `}

  ${Container} {
    position: relative;
  }
`;

export default SlideContainer;
