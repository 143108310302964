import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { pxToRem, hexToRgba } from "@/lib/functions";
import theme from "@/styles";
import { StyledButton as Button } from "../../Button/Button";
import { H3, P } from "../../GlobalStyles/GlobalStyles";
import IconWrapper from "./IconWrapper";

const TeaserWrapper = styled.div`
  background: white;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: ${pxToRem(20)};

  ${Button} {
    align-self: flex-start;
    width: 100%;
    margin-top: ${pxToRem(10)};
  }

  ${H3} {
    min-height: ${({ horizontal }) => (horizontal ? "auto" : "2.6em")};
    text-transform: uppercase;
    padding-right: ${pxToRem(50)};
  }

  ${mq.md(css`
    margin: ${pxToRem(10)};

    ${H3} {
      padding-right: 0;
    }

    ${Button} {
      width: inherit;
      margin: ${pxToRem(20)} 0;
    }
  `)}

  ${mq.lg(css`
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  `)}

  ${({ backgroundImage, backgroundImageWebp, icon, iconColor, horizontal }) =>
    backgroundImage &&
    icon &&
    iconColor &&
    !horizontal &&
    css`
      ${H3}, ${P} {
        padding-right: ${pxToRem(15)};
      }

      ${mq.md(css`
        padding-top: ${pxToRem(100)};
      `)}

      ${mq.lg(css`
        padding-top: ${pxToRem(150)};
      `)}

      &:hover {
        .no-webp & {
          background: linear-gradient(
              ${hexToRgba(theme.colors[iconColor])},
              ${theme.colors[iconColor]}
            ),
            url(${backgroundImage});
          background-size: 100%;
        }

        .webp & {
          background: linear-gradient(
              ${hexToRgba(theme.colors[iconColor])},
              ${theme.colors[iconColor]}
            ),
            url(${backgroundImageWebp});
          background-size: 100%;
        }

        ${H3}, ${P} {
          color: ${theme.colors.white};
        }

        ${Button} {
          background: white;
          color: black;

          svg {
            fill: black;
          }
        }
      }
    `}

    ${({ active, backgroundImage, backgroundImageWebp, iconColor }) =>
    active &&
    css`
        .no-webp & {
          background: linear-gradient(
              ${hexToRgba(theme.colors[iconColor])},
              ${theme.colors[iconColor]}
            ),
            url(${backgroundImage});
          background-size: 100%;
        }

        .webp & {
          background: linear-gradient(
              ${hexToRgba(theme.colors[iconColor])},
              ${theme.colors[iconColor]}
            ),
            url(${backgroundImageWebp});
          background-size: 100%;
        }

        .webp & ${IconWrapper} {
          background: white;
          opacity: 0.5;
        }

        .no-webp & ${IconWrapper} {
          background: white;
          opacity: 0.5;
        }

        ${H3}, ${P} {
          color: ${theme.colors.white};
        }

        ${Button} {
          background: white;
          color: black;

          svg {
            fill: black;
          }
        }
      `}

    ${({ horizontal, reverse }) =>
    horizontal &&
    css`
        ${mq.xs(css`
          padding: ${reverse ? `${pxToRem(20)} ` : `${pxToRem(20)} ;`};
        `)};

        ${mq.lg(css`
          width: ${reverse
        ? `calc(40% + ${pxToRem(200)})`
        : `calc(56% + ${pxToRem(200)})`};
          padding: ${reverse
        ? `${pxToRem(70)} ${pxToRem(100)} ${pxToRem(60)} ${pxToRem(20)}`
        : `${pxToRem(70)} ${pxToRem(100)} ${pxToRem(60)} ${pxToRem(100)};`};
        `)}
        ${mq.xxl(css`
          padding: ${reverse
            ? `${pxToRem(70)} ${pxToRem(160)} ${pxToRem(
              60
            )} calc(100vw - 82rem - ((100vw - 82rem) / 2))`
            : `${pxToRem(70)} ${pxToRem(100)} ${pxToRem(60)} ${pxToRem(100)};`};
          margin: ${reverse
            ? `${pxToRem(40)} -${pxToRem(120)} ${pxToRem(70)} 0;`
            : `${pxToRem(90)} 0 ${pxToRem(60)} -${pxToRem(100)};`};

          a {
            margin-top: ${pxToRem(40)};
          }
        `)}
      `}
`;

export default TeaserWrapper;
