import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const GalleryWrapper = styled.div`
  .swiper-pagination-bullets {
    display: block !important;
    bottom: -5px !important;

    ${mq.lg(css`
      bottom: ${pxToRem(10)} !important;
    `)}
  }

  .swiper-pagination-bullet {
    ${mq.lg(css`
      margin: 0 ${pxToRem(4)};
    `)}
  }
`;

export default GalleryWrapper;
