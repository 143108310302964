import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import theme from "@/styles";

const LanguageOptionsWrapper = styled.ul`
  align-items: center;
  cursor: pointer;
  display: none;
  flex-direction: column;
  position: absolute;
  text-align: right;
  background-color: ${theme.colors.greyLighter};
  right: -10px;
  margin-right: 25px;
  top: -90px;
  padding: 10px;
  opacity: 0;
  list-style-type:none;
  z-index:999;

  ${mq.xl(css`
    top: 30px;
    background-color: ${theme.colors.white};
  `)}
`;

export default LanguageOptionsWrapper;
