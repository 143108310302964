import React, { useState, useEffect } from "react";
import {injectIntl } from "react-intl";
import Section from "../Section/Section";
import NewsTeaser from "../NewsTeaser/NewsTeaser";
import { H2, P } from "../GlobalStyles/GlobalStyles";
import Button from "../Button/Button";
import css from "@emotion/css";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";
import Pagination from "../Pagination/Pagination";
import CustomSelect from "../CustomSelect/CustomSelect";
import Grid from "../Grid/Grid";

const NewsPreview = props => {
  const { header, bodytext, items, header_layout, pagination, filter, intl } = props;
  
  let initialFilter = {};

  const [usedFilter, setUsedFilter] = useState(initialFilter);


  const renderNews = (items, className) => {
    return items.filter(item => {
      if (!usedFilter) return true;

      const tempCategories = item.categories.map(c => c.uid);
      const filters = usedFilter ? Object.keys(usedFilter).map(key => usedFilter[key]) : [];

      return filters.every(v => tempCategories.indexOf(v) !== -1);

    }).map((item, index) => {
      return (
        <div key={index} className={className}>
          <NewsTeaser {...item} />
        </div>
      );
    });
  };

  

  const onFilterChange = (key, { label, value }) => {
    let temp = { ...usedFilter };

    if (value === 0 && temp[key]) {
      delete temp[key];
    } else {
      temp[key] = value;
    }
    setUsedFilter(temp);
  }

  // header and bodytext on the left side
  if (header_layout == 0) {
    return (
      <Section
        backgroundColor="#e5f3f7"
        style={{ padding: `${pxToRem(20)} 0` }}
      >
        <div className="row">
          <div
            className="col-12 col-lg-6"
            css={css`
              align-self: center;

              ${mq.lg(css`
                padding-right: ${pxToRem(100)};
              `)}
            `}
          >
            {header && (
              <H2
                css={css`
                  text-align: left;
                `}
              >
                {header}
              </H2>
            )}
            {bodytext && (
              <P as="div" dangerouslySetInnerHTML={{ __html: bodytext }} />
            )}

            <Button type="primary" href={"/agentur/news"}>
              Alle Neuigkeiten
            </Button>
          </div>
          <div className="col-12 col-lg-6">
            <div className="row">{renderNews(items, "col-12 col-md-6")}</div>
          </div>
        </div>
      </Section>
    );
  }

  // use header and bodytext from section
  if (header_layout == 1) {
    return (
      <Section
        header={header}
        bodytext={bodytext}
        style={{ padding: `${pxToRem(20)} 0` }}
      >
        <Grid columns={2}>

            {filter && Object.keys(filter).map(key => {
            const options = filter[key];
            const selectLabel = intl.messages[`news.filter.${key}.name`];
            return (
            
                <CustomSelect
                  key={key}
                  defaultValue={{
                    label: selectLabel,
                    value: 0
                  }}
                  options={[
                    {
                      label: selectLabel,
                      value: 0
                    },
                    ...options
                  ]}
                  onChange={option => onFilterChange(key, option)}
                />
            );
            
             
          })}

        </Grid>
        
        <div className="row">
          {renderNews(items, "col-12 col-md-6 col-lg-4 col-xl-3")}
        </div>
        <Pagination {...pagination} />
      </Section>
    );
  }
};

export default injectIntl(NewsPreview);
