import React from "react";
import dynamic from "next/dynamic";

import Employees, { EmployeeSlider } from "../components/Employees/Employees";
import Teaser from "../components/Teaser/Teaser";
import Stage from "../components/Stage/Stage";
import JobPreview from "../components/JobPreview/JobPreview";
import Gridelement from "../components/Gridelement/Gridelement";
import IconTeaser from "../components/ServiceTeaser/IconTeaser";
import SocialStream from "../components/SocialStream/SocialStream";
import JobOverview from "../components/JobOverview/JobOverview";
import ReferenceOverview from "../components/ReferenceOverview/ReferenceOverview";
import Gallery from "../components/Gallery/Gallery";
import Textpic from "../components/Textpic/Textpic";
import TextAnimation from "../components/TextAnimation/TextAnimation";
import InfoSlideBoxes from "../components/InfoSlideBoxes/InfoSlideBoxes";
import VideoStage from "../components/VideoStage/VideoStage";
import ReferenceDetail from "../components/ReferenceDetail/ReferenceDetail";
import Technologies from "../components/Technologies/Technologies";
import JobDetail from "../components/JobDetail/JobDetail";
import Card from "../components/Card/Card";
import ReferencePreview from "../components/ReferencePreview/ReferencePreview";
import Services from "../components/Services/Services";
import Gmaps from "../components/Gmaps/Gmaps";
import Locations from "../components/Locations/Locations";
import Form from "../components/Form/Form";
import AccordionGrid from "../components/AccordionGrid/AccordionGrid";
import Pricing from "../components/Pricing/Pricing";
import Facts from "../components/Facts/Facts";
import StageSlider from "../components/StageSlider/StageSlider";
import TechnologiesSlider from "../components/TechnologiesSlider/TechnologiesSlider";
import Kununu from "../components/Kununu/Kununu";
import NewsList from "../components/NewsList/NewsList";
import NewsDetail from "../components/NewsDetail/NewsDetail";
import Newsletter from "../components/Newsletter/Newsletter";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import SocialButtons from "../components/SocialButtons/SocialButtons";
import Blocker from "../components/Blocker/Blocker";
import Text from "../components/Textpic/Text";
import StickyContact from "../components/StickyContact/StickyContact";
import FormConfirmation from "../components/FormConfirmation/FormConfirmation";
import DownloadModule from "../components/DownloadModule/DownloadModule";
import EmbedHtml from "../components/EmbedHtml/EmbedHtml";
import LinkTree from "../components/LinkTree/LinkTree";
import EmbedHubspot from "../components/EmbedHubspot/EmbedHubspot";

const Components = {
  header: Card,
  mask_teaser: Teaser,
  mask_stage: Stage,
  mask_iconteaser: IconTeaser,
  mask_galerie: Gallery,
  mask_blocker: Blocker,
  mask_videostage: VideoStage,
  mask_countup: Facts,
  mask_kununuteaser: Kununu,
  mask_sociallinks: SocialLinks,
  mask_socialbuttons: SocialButtons,
  mask_stickycontact: StickyContact,
  mask_textanimation: TextAnimation,
  mask_thanksmodul: FormConfirmation,
  mask_linktree: LinkTree,
  newsletter_boxAction: Newsletter,
  slider_sliderAction: StageSlider,
  textpic: Textpic,
  textmedia: Textpic,
  text: Text,
  person_listAction: Employees,
  person_sliderAction: EmployeeSlider,
  gridelements_pi1_col: Gridelement,
  gridelements_pi1_infoslider: InfoSlideBoxes,
  gridelements_pi1_accordion: AccordionGrid,
  jobManagment_teaserAction: JobPreview,
  jobManagment_listAction: JobOverview,
  jobManagment_showAction: JobDetail,
  jobManagment_applicationformAction: JobDetail,
  socialstream_listAction: SocialStream,
  reference_listAction: ReferenceOverview,
  reference_showAction: ReferenceDetail,
  reference_sliderAction: ReferencePreview,
  technology_technologyTileAction: Technologies,
  technology_sliderAction: TechnologiesSlider,
  technology_footerSliderAction: TechnologiesSlider,
  service_overviewAction: Services,
  location_mapAction: Gmaps,
  location_listAction: Locations,
  sandbox_formAction: Form,
  contactform_formAction: Form,
  appointmentform_formAction: Form,
  newsform_formAction: Form,
  mask_sandboxpricing: Pricing,
  news_listJsonAction: NewsList,
  news_detailJsonAction: NewsDetail,
  mask_downloadmodul: DownloadModule,
  whitepaperform_formAction: Form,
  html: EmbedHtml,
  mask_embedhubspot: EmbedHubspot,
};

export default function selectComponent(
  { module, CType, uid, ...props },
  isMobile,
  customProps
) {
  if (typeof Components[CType] !== "undefined") {
    return React.createElement(Components[CType], {
      ctype: CType,
      id: uid ? uid : customProps.optionalKey,
      key: uid ? uid : customProps.optionalKey,
      isMobile,
      ...customProps,
      ...props
    });
  }

  return null;
}
