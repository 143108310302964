import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const Meta = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: ${pxToRem(20)} 0 0;

  justify-content: space-between;

  ${mq.lg(css`
    margin: ${pxToRem(40)} 0 0;
  `)}

  & > p {
    margin: 0;

    &:first-of-type {
      color: ${theme.colors.blue};
      font-weight: bold;
    }
  }
`;
export default Meta;
