import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const ImageWrapper = styled.div`
  position: relative;
  transition: left 0.6s ease-in-out;
  cursor: pointer;

  img {
    width: 100%;
    padding: 20px;
  }

  ${mq.lg(css`
    position: absolute;
    width: ${pxToRem(450)};
    top: 60%;
    transform: translateY(-50%);
    left: -${pxToRem(200)};
  `)}

  ${mq.xl(css`
    width: ${pxToRem(500)};
  `)}
`;

export default ImageWrapper;
