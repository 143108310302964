import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import theme from "@/styles";

import { StyledButton as Button } from "../../Button/Button";
import { Subheadline, H1, H2 } from "../../GlobalStyles/GlobalStyles";

const Content = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding: ${pxToRem(90)} 0 0 0;

  ${Button} {
    margin: ${pxToRem(10)} auto ${pxToRem(35)} auto;
  }

  ${mq.lg(css`
    padding: ${pxToRem(100)} 0;

    ${Button} {
      margin: ${pxToRem(10)} auto;
    }
  `)}

  ${H1} {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    display: inline-block;
    padding: 10px 20px 10px 20px;
    margin-top: 0;
    margin-bottom: 20px;
    min-height: 1.6em;

    .typed-cursor {
      opacity: 0.8;
      padding-left: 10px;
    }
  }

  ${Subheadline}, ${H2} {
    color: ${theme.colors.white};
  }
`;

export default Content;
