import React, { useState, useRef } from "react";
import ReferencePreviewWrapper from "./components/ReferencePreviewWrapper";
import { P, H2, Subheadline } from "../GlobalStyles/GlobalStyles";
import Button from "../Button/Button";
import TextWrapper from "./components/TextWrapper";
import SlideWrapper from "./components/SlideWrapper";
import Slide from "./components/Slide/Slide";
import Container from "../Container/Container";
import StyledSVG from "./components/StyledSVG";
import theme from "../../theme";
import css from "@emotion/css";
import { pxToRem } from "@/lib/functions";

const ReferencePreview = ({
  header,
  subheader,
  bodytext,
  cta,
  ctaLink,
  ctaType,
  items,
  header_layout,
  isMobile
}) => {
  const [open, setOpen] = useState(null);

  if (header_layout == 0) {
    return (
      <ReferencePreviewWrapper>
        <TextWrapper open={!!open}>
          <Subheadline
            as="div"
            bold
            style={{ marginBottom: 0, textTransform: "uppercase" }}
          >
            {subheader}
          </Subheadline>
          <H2 as="div" style={{ textAlign: "left" }}>
            {header}
          </H2>
          <P as="div">{bodytext}</P>
          {cta && (
            <Button type={ctaType} href={ctaLink}>
              {cta}
            </Button>
          )}
        </TextWrapper>
        <SlideWrapper>
          {items &&
            items.map((item, index) => {
              return (
                <Slide
                  key={index}
                  gradient={{
                    from: item.gradienttop,
                    to: item.gradientbottom
                  }}
                  onMouseEnter={() => setOpen(item.uid)}
                  onMouseLeave={() => setOpen(null)}
                  isMobile={isMobile}
                  header_layout={header_layout}
                  open={open}
                  image={item.image}
                  backgroundImage={item.backgroundimage}
                  {...item}
                />
              );
            })}
        </SlideWrapper>
      </ReferencePreviewWrapper>
    );
  }

  if (header_layout == 1) {
    return (
      <ReferencePreviewWrapper column={true}>
        <Container>
          <div style={{ textAlign: "center" }}>
            <H2
              css={css`
                margin-bottom: ${pxToRem(40)};
              `}
              as="div"
              dangerouslySetInnerHTML={{ __html: header }}
            />
          </div>
        </Container>

        <SlideWrapper fullWidth>
          {items &&
            items.map((item, index) => {
              return (
                <Slide
                  key={index}
                  gradient={{ from: item.gradienttop, to: item.gradientbottom }}
                  onMouseEnter={() => setOpen(item.uid)}
                  onMouseLeave={() => setOpen(false)}
                  isMobile={isMobile}
                  header_layout={header_layout}
                  open={open}
                  image={item.image}
                  backgroundImage={item.backgroundimage}
                  {...item}
                />
              );
            })}
        </SlideWrapper>
      </ReferencePreviewWrapper>
    );
  }
};

ReferencePreview.defaultProps = {
  header_layout: 0
};

export default ReferencePreview;
