import styled from "@emotion/styled";
import theme from "@/styles";
import Title from "../../Title";

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0.5rem;

  &:first-of-type {
    margin-top: 0;
  }

  svg {
    fill: ${theme.colors.primary};
    width: 2em;
    height: 2em;
    margin-left: -10px;
  }

  ${Title} {
    align-items: center;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    padding-bottom: 0.5rem;
  }
`;

export default ContactWrapper;
