import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";

const TechnologyInnerWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: ${pxToRem(40)};

    img {
        height: 100%;
    }
`

export default TechnologyInnerWrapper;