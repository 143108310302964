import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";

const DownloadModuleInfoWrapper = styled.div`
display:flex;
width:100%;
flex-direction: row-reverse;
justify-content: space-between;
padding: 0 0 ${pxToRem(15)} 0;

${mq.lg(css`
display:flex;
flex-direction:row;
justify-content: normal;
align-items: center;
padding-bottom: 0;
  `)}
`;


export default DownloadModuleInfoWrapper;