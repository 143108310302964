import styled from "@emotion/styled";
import FlipCardInner from "./FlipCardInner";
import { pxToRem } from "@/lib/functions";

const FlipCardWrapper = styled.div`
  background-color: transparent;
  width: 100%;
  height: 1px;
  min-height: ${pxToRem(250)};
  border: 1px solid #f1f1f1;
  perspective: 1000px;

  &:hover ${FlipCardInner} {
      transform: rotateY(180deg);
  }
`;

export default FlipCardWrapper;
