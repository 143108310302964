import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";

const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: white;
  color: black;
  padding: ${pxToRem(50)};
  z-index: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

export default FlipCardFront;
