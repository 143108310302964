import React, { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import { injectIntl } from "react-intl";
import Section from "../Section/Section";
import Teaser from "../Teaser/Teaser";
import Technology from "../Technologies/components/Technology/Technology";
import { breakpoints } from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";

const Swiper = dynamic(() => import("../Swiper/Swiper"));

const swiperSettings = {
  slidesPerView: 1,
  overflow: "visible",
  breakpointsInverse: true,
  showPagination: true,
  paginationSettings: {
    color: "#d8d8d8",
    position: "top",
    visibleOnDesktop: false
  },
  breakpoints: {
    [breakpoints.xs]: {
      slidesPerView: 1,
      centeredSlides: true
    },
    [breakpoints.sm]: {
      slidesPerView: 1,
      centeredSlides: false
    },
    [breakpoints.md]: {
      slidesPerView: 2,
      centeredSlides: false
    },
    [breakpoints.lg]: {
      slidesPerView: 3
    }
  }
};

const colorSettings = {
  digital: { from: "#07c1d8", to: "#038daf" },
  ecommerce: { from: "#E42321", to: "#E42321" },
  online: { from: "#000000", to: "#000000" }
};

const Services = props => {
  const [technologies, setTechnologies] = useState(null);
  const [index, setIndex] = useState(0);
  const [showFlyout, setShowFlyout] = useState(true);
  const [arrowPosition, setArrowPosition] = useState(0);

  const handleClick = (item, index) => {
    setTechnologies(item.technologies);
    setIndex(index);
    setShowFlyout(true);
  };

  useEffect(() => {
    // init arrow for section
    const el = document.querySelector(`.slide-${index}`);
    if (el) {
      el.click();
    }
  }, []);

  useEffect(() => {
    // calculate arrow position based on visible slides and index
    let slidesVisible;
    const el = document.querySelector(`.slide-${index}`);

    //set technologies on index change
    setTechnologies(props.items[index].technologies)

    if (window.innerWidth <= breakpoints.md) {
      const visible = swiperSettings.breakpoints[breakpoints.sm].slidesPerView;
      slidesVisible = visible;
    }
    if (
      window.innerWidth > breakpoints.md &&
      window.innerWidth < breakpoints.lg
    ) {
      const visible = swiperSettings.breakpoints[breakpoints.md].slidesPerView;
      slidesVisible = visible;
    }

    if (window.innerWidth >= breakpoints.lg) {
      const visible = swiperSettings.breakpoints[breakpoints.lg].slidesPerView;
      slidesVisible = visible;
    }

    if (slidesVisible === 1) {
      const width = el && el.clientWidth / 2;
      setArrowPosition(width);
    } else if (slidesVisible === 2) {
      const width =
        el &&
        el.clientWidth * (index + 1 > 1 ? index + 1 - slidesVisible : index) +
        el.clientWidth / 2;
      setArrowPosition(width);
    } else if (slidesVisible === 3) {
      const width = el && el.clientWidth * index + el.clientWidth / 2;
      setArrowPosition(width);
    }
  }, [index]);

  return (
    <>
      <Section {...props}>
        <Swiper
          settings={swiperSettings}
          items={props.items.map((item, itemIndex) => {
            return {
              active: index === itemIndex,
              ...item
            };
          })}
          component={Teaser}
          onClick={handleClick}
          onMouseEnter={handleClick}
          onSlideChange={index => setIndex(index)}
        />
      </Section>
      {showFlyout && (
        <Section
          style={{ marginTop: `-${pxToRem(50)}` }}
          header={props.intl.messages["technologies.title"]}
          backgroundImage={{ image: "/static/img/section_background1.png" }}
          gradient={
            index === 0
              ? colorSettings.digital
              : index === 1
                ? colorSettings.ecommerce
                : colorSettings.online
          }
          headerSmall
          arrow
          arrowColor={
            index === 0
              ? colorSettings.digital.from
              : index === 1
                ? colorSettings.ecommerce.from
                : colorSettings.online.from
          }
          arrowPosition={arrowPosition}
          overflow={true}
        >
          <Swiper
            resetMargin
            settings={{
              ...swiperSettings,
              showPagination: false,
              stretch: true
            }}
            items={
              technologies &&
              technologies.map(technology => {
                return {
                  shadow: true,
                  square: false,
                  filter: false,
                  resetMargin: true,
                  style: { margin: "0 20px" },
                  ...technology
                };
              })
            }
            component={Technology}
          />
        </Section>
      )}
    </>
  );
};

export default injectIntl(Services);
