import styled from "@emotion/styled";
import { css } from "@emotion/react";

import mq from "@/lib/mediaQueries";
import theme from "@/styles";
import Toggle from "../../Accordion/components/Toggle";
import { pxToRem } from "@/lib/functions";

const FooterInnerWrapper = styled.div`
  background-color: ${theme.colors.greyDark};
  display: flex;
  flex-direction: column;

  ${Toggle} {
    border-bottom: 1px solid #333;
    border-top: 1px solid #333;
    width: calc(100% + 40px);
    margin-left: -20px;

    svg {
      display: block;
    }
  }

  ${mq.lg(css`
    padding: ${pxToRem(40)} 0 ${pxToRem(10)} 0;
    background-color: ${theme.colors.grey};

    ${Toggle} {
      border: none;
      width: 100%;
      margin-left: 0;

      svg {
        display: none;
      }
    }
  `)}

  ${props =>
    props.bottom &&
    css`
      padding: 20px 0 40px 0;
      background-color: ${theme.colors.grey};
      border-top: 1px solid ${theme.colors.grey};

      ${mq.lg(css`
        padding: 70px 0;
        background-color: ${theme.colors.grey};
      `)}
    `}
`;

export default FooterInnerWrapper;
