import styled from "@emotion/styled";
import ReactPlayer from 'react-player'
import { useState } from "react";

const StyledVideo = styled.video`
  width: 100%;
  object-fit: cover;
`;


const IframeWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;

  .youtube-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Img = styled.img`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  cursor: pointer;
  object-fit: cover;
`;


const Video = ({ assets }) => {
  const [showPoster, setShowPoster] = useState(true);
  const hideImg = (e) => {
    setShowPoster(false);
  };

  if (assets.type === 'video/youtube') {
    return (
      <IframeWrapper onClick={hideImg}>
        {assets.thumbnail && showPoster && <Img src={assets.thumbnail} />}
        <ReactPlayer width='100%' height='100%' className="youtube-player" url={assets.videoUrl} playing={!showPoster} />
      </IframeWrapper>
    )
  }
  return (
    <StyledVideo controls={true} poster={assets.thumbnail}>
      <source src={assets.video}></source>
    </StyledVideo>
  )
}

export default Video