import React from "react";
import { P, Subheadline } from "../GlobalStyles/GlobalStyles";
import SVG from "react-inlinesvg";
import IconWrapper from "./components/IconWrapper";
import IconTeaserWrapper from "./components/IconTeaserWrapper";

const IconTeaser = ({ icon, header, bodytext, circle, columns }) => {
  return (
    <IconTeaserWrapper header={header}>
      <IconWrapper header={header} circle={circle} columns={columns}>
        {icon && <SVG src={`/static/svg/${icon}.svg`} />}
      </IconWrapper>

      {header && <Subheadline bold>{header}</Subheadline>}

      <P
        as="div"
        fontWeight={300}
        dangerouslySetInnerHTML={{ __html: bodytext }}
      />
    </IconTeaserWrapper>
  );
};

IconTeaser.defaultProps = {
  icon: "creation",
  header: "Digitale Transformation",
  bodytext: "",
  circle: true
};

export default IconTeaser;
