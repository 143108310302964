import React from "react";
import dynamic from "next/dynamic";
import { css, ThemeContext } from "@emotion/react";
import Section from "../Section/Section";
import NewsletterWrapper from "./components/NewsletterWrapper";
import { P } from "../GlobalStyles/GlobalStyles";
import { pxToRem } from "@/lib/functions";

const NewsletterForm = dynamic(() => import("../Form/NewsletterForm"));

const Newsletter = props => {
  return (
    <Section
      as="div"
      headerStyle={{ color: "white", marginBottom: pxToRem(40) }}
      style={{ padding: pxToRem(20), marginBottom: 0 }}
      backgroundColor="#038daf"
      header={props.header}
    >
      <NewsletterWrapper>
        <P
          as="div"
          css={css`
            font-weight: 600;
            text-align: center;
          `}
        >
          {props.subheader}
        </P>
        <NewsletterForm
          key={new Date()}
          formOnly
          form={props.form}
          formId={props.formId}
        />
      </NewsletterWrapper>
    </Section>
  );
};

export default Newsletter;
