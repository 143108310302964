import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "@/styles";

const CardListItem = styled.li`
  color: white;
  &:last-of-type {
    &:after,
    &:before {
      content: "";
    }
  }

  ${({ arrow }) =>
    arrow &&
    css`
      svg {
        width: 15px;
        height: 15px;
        margin-left: 20px;
        fill: ${theme.colors.white};
      }
    `}

  &:after {
    color: ${theme.colors.primary};
    content: "//";
    padding: 0 5px;
  }
`;

export default CardListItem;
