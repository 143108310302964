import React, { useState } from "react";
import dynamic from "next/dynamic";
import Media from "react-media";
import { css } from "@emotion/react";
import Slide from "../Slide/Slide";
import { breakpoints } from "@/lib/mediaQueries";
import Container from "../Container/Container";
import { H2, P } from "../GlobalStyles/GlobalStyles";
import Button from "../Button/Button";

const Swiper = dynamic(() => import("../Swiper/Swiper"));

const swiperSettings = {
  slidesPerView: 1,
  breakpointsInverse: true,
  showPagination: true,
  stretch: true,
  paginationSettings: {
    color: "#d8d8d8",
    visibleOnDesktop: true,
    bottom: 1,
    bottomDesktop: 50,
    bulletSize: 10,
    bulletSpaceBetween: 10
  },
  breakpoints: {
    [breakpoints.xs]: {
      slidesPerView: 1
    }
  }
};

const StageSlider = props => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const renderMobileSection = showSubheader => {
    return (
      props.items[currentSlideIndex] && (
        <Container
          css={css`
            padding: 20px;
            background-color: #f4f4f4;

            ${H2} {
              margin-bottom: 0;
              text-align: left;
            }

            a {
              width: 100%;
              margin: 20px;
            }
          `}
        >
          <H2
            style={{
              textAlign: "left",
              marginBottom: 20
            }}
          >
            {props.items[currentSlideIndex].header}
          </H2>
          {showSubheader && (
            <P as="div">{props.items[currentSlideIndex].subheader}</P>
          )}
          {props.items[currentSlideIndex].cta && (
            <Button
              href={props.items[currentSlideIndex].ctalink}
              type={props.items[currentSlideIndex].ctatype}
            >
              {props.items[currentSlideIndex].cta}
            </Button>
          )}
        </Container>
      )
    );
  };

  return (
    <section>
      {props.items && props.items.length > 1 ? (
        <Media
          defaultMatches={!props.isMobile}
          query={{ minWidth: breakpoints.md }}
        >
          {matches =>
            matches ? (
              <Swiper
                settings={swiperSettings}
                items={props.items.map(item => {
                  return {
                    isMobile: props.isMobile,
                    ...item
                  };
                })}
                component={Slide}
                onSlideChange={index => setCurrentSlideIndex(index)}
              />
            ) : (
                <>
                  <Swiper
                    settings={swiperSettings}
                    items={props.items.map(item => {
                      return {
                        isMobile: props.isMobile,
                        ...item
                      };
                    })}
                    component={Slide}
                    onSlideChange={index => setCurrentSlideIndex(index)}
                    resetMargin
                  />
                  {renderMobileSection()}
                </>
              )
          }
        </Media>
      ) : (
          <Media
            defaultMatches={!props.isMobile}
            query={{ minWidth: breakpoints.md }}
          >
            {matches =>
              matches ? (
                <Slide {...props.items[0]} isMobile={props.isMobile} />
              ) : (
                  <>
                    <Slide {...props.items[0]} isMobile={props.isMobile} />
                    {renderMobileSection(true)}
                  </>
                )
            }
          </Media>
        )}
    </section>
  );
};

export default StageSlider;
