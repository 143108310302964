import React from "react";
import LogoWrapper from "./components/LogoWrapper";
import StyledLogo from "./components/StyledLogo";

const Logo = () => {
  return (
    <LogoWrapper>
      <StyledLogo alt="Brandung GmbH"src={"/static/logo_brandung_white.svg"} />
    </LogoWrapper>
  );
};

export default Logo;
