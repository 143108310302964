import React from "react";
import { css } from "@emotion/react";
import Head from "next/head";
import parser from "ua-parser-js";
import { NextSeo } from "next-seo";
import selectComponent from "@/lib/selectComponent";
import { pxToRem } from "@/lib/functions";
import { apiURL, baseURL } from "@/config";
import redirect from "@/lib/redirect";
import Error from "./_error";
import Script from 'next/script';

import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";

const fetchOptions = {
  mode: "cors",
  method: "GET",
  headers: { Accept: "application/json" },
  redirect: "manual",
};

const Page = ({
  nav,
  data: { content, meta },
  _url,
  isMobile,
  statusCode,
  baseURL,
  errorCode,
}) => {
  if (errorCode) {
    return <Error statusCode={errorCode} />;
  }

  const seoConfig = meta
    ? {
      title: `${meta.title}`,
      description: `${meta.description}`,
      openGraph: {
        title: meta.title,
        description: meta.og_description || meta.description,
        type: "website",
        url: `https://www.agentur-brandung.de/${_url}`,
        images: [
          {
            url: meta.og_image,
            width: 512,
            height: 300,
            alt: meta.og_description || meta.description,
          },
        ],
      },
      twitter: {
        site: "@brandung",
        cardType: "summary",
      },
      noindex: meta.no_index,
      canonical: meta.canonical,
    }
    : {
      title:
        "brandung - Internetagentur für effiziente Kommunikation und E-Business",
    };

  const videoStage =
    content &&
    content.find((module) => module && module.CType === "mask_videostage");

  return (
    <>
      <Script
        strategy="beforeInteractive"
        src="https://app.usercentrics.eu/latest/main.js"
        id="0frnhtwIA"
        data-settings-id="0frnhtwIA"
      />
      <script
        type="text/plain"
        data-usercentrics="Google Tag Manager"
        dangerouslySetInnerHTML={{
          __html: `
        // create dataLayer
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }

        // set „denied" as default for both ad and analytics storage,
        gtag("consent", "default", {
          ad_storage: "denied",
        analytics_storage: "denied",
        wait_for_update: 2000 // milliseconds to wait for update
        });

        gtag("set", "ads_data_redaction", true);`}} />

      <script
        type="text/plain"
        strategy="afterInteractive"
        defer
        data-usercentrics="Google Tag Manager"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({
                  'gtm.start': new Date().getTime(),
                  event: 'gtm.js'
              });
              var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-PQ5FQS9');
          `
        }}
      />
      <script
        type="text/plain"
        data-usercentrics="Survey Anyplace"
        src="https://s.surveyanyplace.com/js/embed.js" defer async></script>
      <NextSeo {...seoConfig} />
      {seoConfig.noindex === 0 && (
        <Head>
          {meta.translations && meta.translations[0] && (
            <>
              <link
                rel="alternate"
                href={`${baseURL}${meta.translations[0].navUrl}`}
                hrefLang="x-default"
              />
              <link
                rel="alternate"
                href={`${baseURL}${meta.translations[0].navUrl}`}
                hrefLang="de"
              />
            </>
          )}
          {meta.translations && meta.translations[1] && (
            <link
              rel="alternate"
              href={`${baseURL}${meta.translations[1].navUrl}`}
              hrefLang="en"
            />
          )}
        </Head>
      )}

      <Header
        isMobile={isMobile}
        navItems={nav.mainnav}
        videoStage={!!videoStage}
        meta={meta}
      />

      <main
        css={css`
          position: relative;
          margin-top: ${videoStage ? 0 : pxToRem(66)};
        `}
      >
        {content
          ? content.map((module, index) => {
            return <ErrorBoundary key={index}>
              {selectComponent(module, isMobile, { optionalKey: index })}
            </ErrorBoundary>
          })
          : null}
      </main>

      <Footer navItems={nav.footer} metaItems={nav.meta} isMobile={isMobile} />
    </>
  );
};

Page.getInitialProps = async ({ res, req, asPath }) => {
  const url = asPath;
  let lang = "de";
  let nav = {};
  let data = {};
  let redirected = false;
  const userAgent = req
    ? req.headers["user-agent"]
    : navigator.userAgent
      ? navigator.userAgent
      : "";
  const isMobile = parser(userAgent).device.type === "mobile";
  const languageRegex = /^\/(en|fr)/; // matches :lang/*
  const langString = languageRegex.exec(url);

  if (langString) {
    lang = langString[1];
  }

  try {
    const navResponse = await fetch(
      `https://${apiURL}/${lang === 'de' ? '' : `${lang}/`}navigation/`,
      fetchOptions
    );
    const dataResponse = await fetch(`https://${apiURL}${url}`, fetchOptions);
    if (navResponse.ok) {
      const contentType = navResponse.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        nav = await navResponse.json();
      } else {
        throw new Error("Nav response was no JSON");
      }
    } else {
      throw new Error(`Nav response was not ok. ${url}`);
    }

    if (dataResponse.ok) {
      const contentType = dataResponse.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        data = await dataResponse.json();
      } else {
        throw new Error("Data response was no JSON");
      }
    } else {
      data = dataResponse;
      throw new Error(`Data response was not ok. ${url}`);
    }
  } catch (error) {
    console.error(
      "There has been a problem with your fetch operation: ",
      error,
      error.message ? error.message : error.props
    );
    // assuming an offline request, so `return null` will start our 
    // service worker offline game
    if (error.message === "Failed to fetch") {
      return null;
    }
  }

  let errorCode = null;
  if (
    data.status === 301 ||
    data.status === 302 ||
    data.status === 307 ||
    data.status === 308
  ) {
    redirect({
      res,
      location: data.headers.get("location").replace(`https://${apiURL}`, ""),
      status: data.status,
    });
  }
  // 404 handling
  if (!data.content) {
    errorCode = data.status || 404;
    if (res) {
      res.statusCode = data.status || 404;
    }
    // const err = new Error();
    // err.statusCode = 404;
    // throw err;
  }

  return {
    nav,
    data,
    isMobile,
    _url: url,
    baseURL,
    errorCode,
  };
};

export default Page;
