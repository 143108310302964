import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import Head from "next/head";
import Media from "react-media";
import theme from "@/styles";
import Container from "../Container/Container";
import StickyContact from "../StickyContact/StickyContact";
import { injectIntl } from "react-intl";
import Button from "../Button/Button";
import JobDetailWrapper from "./components/JobDetailWrapper";
import JobDetailOuterWrapper from "./components/JobDetailInnerWrapper";
import ContactWrapper from "./components/ContactWrapper";
import Employee from "../Employees/components/Employee/Employee";
import Stage from "../Stage/Stage";
import { H3, H4, Subheadline, P } from "../GlobalStyles/GlobalStyles";
import { breakpoints } from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";
import Form from "../Form/Form";
import { setPersonioParameter, getPersonioParameter } from "@/lib/personioHelper";
import SocialButtons from "../SocialButtons/SocialButtons";
import { baseURL } from "@/config";
 
// type JobDetailProps = {
//   item: any,
//   isMobile: boolean,
//   form: any,
//   formId: any,
//   ctaLink: string
// }

const JobDetail = ({ item, isMobile, form, formId, ctaLink, meta, intl }) => {
  let formRef = useRef();
  const [personio, setPersonio] = useState();

  const currentURL = item && item.formLink ? `${baseURL}${item.formLink.replace('jetzt-bewerben/', '')}` : baseURL;

  const shareLinks = [
    {
      icon: "facebook",
      link: `https://www.facebook.com/sharer/sharer.php?u=${currentURL}`
    },
    {
      icon: "xing",
      link: `https://www.xing.com/spi/shares/new?url=${currentURL}`
    },
    {
      icon: "twitter",
      link: `https://twitter.com/intent/tweet?text=${currentURL}`
    },
    {
      icon: "linked-in",
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${currentURL}`
    },
    {
      icon: "whatsapp",
      link: `https://api.whatsapp.com/send?text=${currentURL}`
    }
  ];
  useEffect(() => {
    setPersonio(setPersonioParameter());
  }, []);

  useEffect(() => {
    if (formRef && formRef.current) {
      window.setTimeout(() => formRef.current.scrollIntoView(), 100)
    }
  }, [form])

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
          {
          "@context" : "https://schema.org/",
          "@type" : "JobPosting",
          "title" : "${item.name}",
          "description" : "${item.descriptionlong}",
          "datePosted" : "2010-09-05",
          "validThrough" : "2020-03-18T00:00",
          "employmentType" : "Vollzeit",
          "hiringOrganization" : {
            "@type" : "Organization",
            "name" : "Brandung GmbH",
            "sameAs" : "https://www.brandung.de",
            "logo" : "http://www.brandung.de/static/logo_brandung.svg"
          },
          "jobLocation": {
          "@type": "Place",
            "address": {
            "@type": "PostalAddress",
            "streetAddress": "${item.location === "Berlin"
                ? "Leuschnerdamm 13"
                : "Vogelsanger Straße 76"
              }",
            "addressLocality": "${item.location}",
            "addressRegion": "${item.location === "Berlin" ? "BB" : "NW"}",
            "postalCode": "${item.location === "Berlin" ? "10999" : "50823"}",
            "addressCountry": "DE"
            }
          }
        }
        `
          }}
        />
      </Head>
      <Stage
        subHeader={item.subheaderoverwrite ? `${item.subheaderoverwrite}`:` ${intl.messages[`job.detail.subheadline.part.one`]}  ${item.location} ${intl.messages[`job.detail.subheadline.part.two`]}`}
        header={item.name}
        bodytext={item.descriptionshort}
        image={item.image}
      />
      <JobDetailOuterWrapper>
        {item.formLink && <SocialButtons items={shareLinks} />}
        <Container>
          <div
            css={css`
              margin-top: ${pxToRem(40)};
            `}
          >

            <FormattedMessage
              id="button.back.overview"
              defaultMessage="Back to overview"
            >
              {message => (
                <>
                  <Button
                    href={ctaLink}
                    type="blank"
                    iconColor={theme.colors.primary}
                    iconPosition="before"
                    light
                  >
                    {message}
                  </Button>
                </>
              )}
            </FormattedMessage>
          </div>


          {form ? (
            <>
              <div className="row">
                <div ref={formRef} className="col-12">
                  <H3 style={{ marginTop: 100 }}>
                  {intl.messages[`job.detail.form.headline.part.one`]} {item.name} <br />
                  {intl.messages[`job.detail.form.headline.part.two`]}
                  </H3>
                  <Subheadline bold style={{ textAlign: "center" }}>
                    {intl.messages[`job.detail.form.subheadline`]}
                  </Subheadline>
                  <P style={{ textAlign: "center" }}>
                  {intl.messages[`job.detail.form.copy.one`]}<br /> 
                  {intl.messages[`job.detail.form.copy.two`]}<br />
                  {intl.messages[`job.detail.form.copy.three`]}
                  </P>
                </div>
              </div>
              <Form form={form} formId={formId} formOnly={true} personio={personio} />
            </>
          ) : (
              <JobDetailWrapper>
                <div className="row">
                  <div className="col-12">
                    <Subheadline
                      as="div"
                      dangerouslySetInnerHTML={{ __html: item.descriptionlong }}
                    />
                  </div>
                </div>
                {item.chapter &&
                  item.chapter.map((chapter, index) => {
                    return (
                      <div key={index} className="row border">
                        <div className="col-12 col-lg-4">
                          <H4>{chapter.title}</H4>
                        </div>
                        <div className="col-12 col-lg-8">
                          {chapter.description && (
                            <P
                              as="div"
                              dangerouslySetInnerHTML={{
                                __html: chapter.description
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                <StickyContact text={intl.messages[`job.detail.interest`]} buttontext={intl.messages[`job.detail.apply`]} link={item.formLink} />
              </JobDetailWrapper>
            )}
        </Container>
        {item.contactperson && (
          <ContactWrapper>
            <Container>
              <JobDetailWrapper>
                <div className="row">
                  <div
                    className="col-12 col-lg-4"
                    style={{ alignSelf: "center" }}
                  >
                    <Media
                      defaultMatches={isMobile}
                      query={{ maxWidth: breakpoints.lg }}
                    >
                      {matches =>
                        matches ? (
                          <H4>{intl.messages[`job.detail.contactperson`]}</H4>
                        ) : (
                            <H4>{intl.messages[`job.detail.contactperson.lg`]}
                            </H4>
                          )
                      }
                    </Media>
                  </div>
                  {item.contactperson && (
                    <div className="col-12 col-lg-5">
                      <Employee
                        autoSize
                        horizontal
                        name={item.contactperson.name}
                        title={item.contactperson.isHr}
                        phone={item.contactperson.phone}
                        email={item.contactperson.email}
                        image={item.contactperson.image}
                        whatsapp={item.contactperson.whatsapp}
                        signal={item.contactperson.signal}
                      />
                    </div>
                  )}
                </div>
              </JobDetailWrapper>
            </Container>
          </ContactWrapper>
        )}
      </JobDetailOuterWrapper>
    </>
  );
};
export default injectIntl(JobDetail);
