import styled from "@emotion/styled";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const QuoteWrapper = styled.section`
    text-align: center;
    overflow: hidden;
    background-color: ${theme.colors.blueLighter};
    padding: ${pxToRem(40)} 0;

    p {
        color: ${theme.colors.blueDarker};
    }

    p:first-of-type {
        font-weight: 600;
        line-height: 1.4;
    }
`

export default QuoteWrapper;