import styled from "@emotion/styled";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const IconWrapperLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${theme.colors.primary};
  padding: ${pxToRem(20)};
  border-radius: 50%;
  box-shadow: ${theme.boxShadows.default};
  margin-bottom: ${pxToRem(20)};
  border: 0;
  width: ${pxToRem(65)};
  height: ${pxToRem(65)};
  overflow: hidden;
  z-index: 9;
  pointer-events: auto;

  svg {
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
    fill: ${theme.colors.white};
  }
`;

const IconWrapperButton = styled.button`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: ${props => !props.toggled ? theme.colors.primary : theme.colors.white};
  padding: ${pxToRem(13)} ${pxToRem(15)};
  border-radius: 50%;
  ${props => !props.toggled ? `box-shadow: ${theme.boxShadows.default}` : ''};
  margin-bottom: ${pxToRem(20)};
  cursor: pointer;
  color: ${props => !props.toggled ? theme.colors.white : theme.colors.black};
  font-size: ${pxToRem(12)};
  border: 0;
  width: ${pxToRem(65)};
  height: ${pxToRem(65)};
  overflow: hidden;
  z-index: 9;
  pointer-events: auto;

  svg {
    width: ${pxToRem(15)};
    height: ${pxToRem(15)};
    fill: ${props => !props.toggled ? theme.colors.white : theme.colors.black};
  }
  span {
    margin-top: ${pxToRem(5)};
  }
`;

export { IconWrapperLink, IconWrapperButton };
