import styled from "@emotion/styled";
import theme from "@/styles";

const MobileMenu = styled.div`
  position: relative;
  align-items: center;
  background-color: white;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  left: 0px;
  position: relative;
  text-align: center;
  top: 0px;
  width: 100vw;
  height: 100%;
  z-index: 998;
  overflow: scroll;
`;

export const MobileMenuInner = styled.div`
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: calc(100% - 65px);
  padding: 80px 20px 0px 20px;
`;

export const LanguageWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: calc(100% + 40px);
  padding: 20px;
  background-color: ${theme.colors.greyLighter};
  text-align: center;

  label {
    display: inline-flex;
    min-width: auto;
  }
`;

export default MobileMenu;
