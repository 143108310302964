import styled from "@emotion/styled";

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 50%;
  overflow: hidden;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);

  &:hover {
    cursor: pointer;
  }
`;

export default ImageWrapper;
