import styled from "@emotion/styled";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const IconWrapper = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #555555;
  padding: ${pxToRem(20)};

  svg {
    width: 36px;
    height: 36px;
    fill: ${theme.colors.primary};
  }
`;

export default IconWrapper;
