import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";

const SortWrapper = styled.div`
  display: none;

  ${mq.lg(css`
    align-items: flex-end;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `)}
`;

export default SortWrapper;
