import React from "react";
import theme from "@/styles";
import css from "@emotion/css";
import ReactRating from "react-rating";
import RatingWrapper from "./RatingWrapper";
import RatingSymbol from "./RatingSymbol";
import { P } from "../../GlobalStyles/GlobalStyles";

const Rating = ({ rating }) => {
  return (
    <RatingWrapper>
      <P
        css={css`
          margin-bottom: 0;
          margin-right: 5px;
          color: ${theme.colors.secondary};
        `}
      >
        {rating}
      </P>
      <ReactRating
        emptySymbol={<RatingSymbol />}
        fullSymbol={<RatingSymbol full />}
        readonly
        quiet
        initialRating={rating}
      />
    </RatingWrapper>
  );
};

export default Rating;
