import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import theme from "@/styles";
import NavList from "./NavList";
import NavLink from "./NavLink";
import NavItem from "./NavItem";
import Container from "../../../../Container/Container";

const Flyout = styled.div`
  position: absolute;
  visibility: hidden;
  z-index: 2;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: #f3f3f3;
  
  &:before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 16%;
    bottom: 0px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-image: url("/static/svg/welle-nav.svg");
    background-repeat: repeat;
    transition: opacity .2s ease, visibility .2s ease;
    /* border-bottom: 1px solid ${theme.colors.greyLighter}; */
  }

  ${Container} {
    position: relative;
    z-index: 2;
    transition: opacity 0.4s ease;
    opacity: 0;
  }

  ${NavList} {
    flex-direction: column;
    justify-content: left;
  }

  ${NavList} > ${NavItem}{
    padding: ${pxToRem(10)} ${pxToRem(30)} ${pxToRem(10)} 20vw;
  }

  ${NavList} ${NavList} > ${NavItem}{
    padding: ${pxToRem(10)} ${pxToRem(30)};
  }

  ${NavItem}{
    display:flex;
  }

  ${NavItem} ${NavLink}{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 25%;
    flex-basis: auto;
    width: 100%;
    height: auto;
  }

  >${NavItem} >${NavList}{
    visibility: hidden;
    flex-direction: column;
    flex-flow: column wrap;
    height: 100%;
    max-height: 8.75rem;
    width: 100%;
    top: 0;
    right: 0;
    align-content: flex-start;
    position: absolute;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    left: 25%;
    background-color: #fff;
    &:before {
      position: absolute;
      content: "";
      z-index: -1;
      width: 100%;
      height: 50%;
      bottom: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      background-image: url("/static/svg/welle-nav.svg");
      transition: opacity .2s ease, visibility .2s ease;
      /* border-bottom: 1px solid ${theme.colors.greyLighter}; */
    }
  }

  

  ${NavLink} {
    color: ${theme.colors.black};
    text-transform: unset;

    &:hover {
      color: ${theme.colors.primary};
    }
  }

  ${({ active }) =>
    active &&
    css`
      ${Container} {
        position: relative;
        z-index: 10;
        transition: opacity 0.4s ease, visibility 0.4s ease;
        transition-delay: 0.4s;
        opacity: 1;
        visibility: visible;
      }
    `}

  ${({ mainNavOpen, active }) =>
    mainNavOpen &&
    css`
      &:before {
        visibility: ${active ? "visible" : "hidden"};
        transition: opacity 0.2s ease;
        transition-delay: 0.6s;
        opacity: 1;
        z-index: 100;
      }
    `}
`;

export default Flyout;
