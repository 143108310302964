import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import theme from "@/styles";
import CardListWrapper from "./CardListWrapper";
import CardList from "./CardList";
import CardTitle from "./CardTitle";
import CardListItem from "./CardListItem";

const CardWrapper = styled.a`
  background-color: ${theme.colors.secondary};
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${pxToRem(15)} ${pxToRem(35)};
  transition: transform .3s ease-in-out;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  ${({ inverted }) =>
    inverted &&
    css`
      background-color: #f4f4f4;

      ${CardTitle} {
        color: #424242;
      }

      ${CardListItem} {
        color: ${theme.colors.black};

        svg {
          fill: ${theme.colors.black};
        }
      }
    `}

    ${({ items }) =>
    !items &&
    css`
        ${CardTitle} {
          color: white;
          text-align: center;
          padding: ${pxToRem(10)};
        }
      `}

    ${({ background }) =>
    background &&
    css`
        background: ${background};
      `}

  ${({ fullWidth, inverted }) =>
    fullWidth &&
    css`
      flex-direction: row;
      align-items: center;

      ${CardTitle} {
        flex: 5;
        font-weight: 800;
      }

      ${CardListWrapper} {
        flex: 7;
      }

      ${CardList} {
        justify-content: space-between;
      }

      ${CardListItem} {
        &:after {
          display: none;
        }

        &:last-of-type {
          display: flex;
          align-items: center;

          &:before {
            display: none;
          }
        }
        &:before {
          color: ${theme.colors.primary};
          content: "//";
          padding: 0 5px;
        }
      }
    `}

  ${mq.lg(css`
    padding: ${pxToRem(15)} ${pxToRem(25)} ${pxToRem(20)} ${pxToRem(25)};
  `)}

  ${mq.xxl(css`
    font-size: 1rem;
  `)}
`;

export default CardWrapper;
