import React, { useRef } from "react";
import Media from "react-media";
import { useRouter } from 'next/router'
import SlideWrapper from "./components/SlideWrapper";
import ImageWrapper from "./components/ImageWrapper";
import IconWrapper from "./components/IconWrapper";
import { breakpoints } from "@/lib/mediaQueries";
import Accordion from "../../../Accordion/Accordion";
import { H4 } from "../../../GlobalStyles/GlobalStyles";
import LazyImage from "../../../LazyImage/LazyImage";

const Slide = ({
  uid,
  open,
  onMouseEnter,
  onMouseLeave,
  gradient,
  logoslide,
  image,
  backgroundImage,
  header_layout,
  isMobile,
  name,
  link
}) => {
  const HeadlineRef = useRef();
  const router = useRouter();
  const handleClick = () => {
    if (link) {
      router.push(link);
    }
  };

  return (
    <SlideWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      gradient={gradient}
      backgroundImage={backgroundImage && backgroundImage.image}
      backgroundImageWebp={backgroundImage && backgroundImage.webp}
    >
      <Media defaultMatches={isMobile} query={{ maxWidth: breakpoints.lg }}>
        {matches =>
          matches ? (
            <>
              <Accordion triggerWidth={breakpoints.lg} plus>
                <IconWrapper>
                  <img alt={name} src={logoslide} />
                </IconWrapper>
                <ImageWrapper onClick={handleClick}>
                  <LazyImage image={image} />
                </ImageWrapper>
              </Accordion>
            </>
          ) : (
              <>
                <IconWrapper layout={header_layout} open={open}>
                  {header_layout == 1 && (
                    <H4
                      ref={HeadlineRef}
                      style={{
                        transitionProperty:
                          "opacity, visibility, min-width, width, height, max-width, padding",
                        transitionDelay:
                          open === uid
                            ? "400ms, 400ms, 0ms, 0ms, 0ms"
                            : "0ms, 0ms, 400ms, 400ms, 400ms",
                        color: "white",
                        overflow: "hidden",
                        margin: "0",
                        opacity: open === uid ? "1" : "0",
                        paddingRight: open === uid ? "20px" : "0",
                        visibility: open === uid ? "visible" : "hidden",
                        minWidth: open === uid ? "50%" : "0",
                        maxWidth: open === uid ? "50%" : "0",
                        width:
                          open === uid && HeadlineRef.current
                            ? HeadlineRef.current.scrollWidth
                            : "0",
                        height:
                          open === uid && HeadlineRef.current
                            ? HeadlineRef.current.scrollHeight
                            : "0"
                      }}
                    >
                      {name}
                    </H4>
                  )}
                  <img alt={name} src={logoslide} />
                </IconWrapper>

                <ImageWrapper onClick={handleClick}>
                  <LazyImage image={image} />
                </ImageWrapper>
              </>
            )
        }
      </Media>
    </SlideWrapper>
  );
};

Slide.defaultProps = {};

export default Slide;
