import React from "react";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import Section from "../Section/Section";
import { H3, Subheadline } from "../GlobalStyles/GlobalStyles";
import Accordion from "../Accordion/Accordion";
import LazyImage from "../LazyImage/LazyImage";
import Button from "../Button/Button";
import { pxToRem } from "@/lib/functions";
import { AccordionGridProps } from "./AccordionGrid.types"

const AccordionGrid: React.FC<AccordionGridProps> = ({
  header,
  content,
  id
}) => {
  return (
    <Section id={`c${id}`}>
      <div className="row">
        <div className="col-12 col-md-4">
          <H3
            as="h2"
            css={css`
              text-align: left;
              padding-right: ${pxToRem(20)};
            `}
          >
            {header}
          </H3>
        </div>

        <div className="col-12 col-md-8">
          {content &&
            content.length > 0 &&
            content[0].map((item, index) => {
              if (item.CType === "image") {
                return (
                  <div
                    key={item.uid}
                    style={{
                      position: "relative",
                      textAlign: "center"
                    }}
                  >
                    <LazyImage key={index} image={item.image[0]} />
                    <div
                      key={index}
                      css={css`
                        ${mq.lg(css`
                          position: absolute;
                          left: 0;
                          right: 0;
                          bottom: 40px;
                        `)}
                      `}
                    >
                      {item.header && item.header_link && (
                        <Button href={item.header_link} type="secondary">
                          {item.header}
                        </Button>
                      )}
                    </div>
                  </div>
                );
              }

              if (item.CType === "textmedia") {
                return (
                  <Accordion
                    key={item.uid}
                    plus={true}
                    border={true}
                    plusOpacity={1}
                  >
                    <Subheadline
                      bold
                      css={css`
                        margin-bottom: ${pxToRem(10)};
                        padding: ${pxToRem(10)} 0 ${pxToRem(20)} 0;
                      `}
                    >
                      {item.header}
                    </Subheadline>
                    <div dangerouslySetInnerHTML={{ __html: item.bodytext }} />
                  </Accordion>
                );
              }
            })}
        </div>
      </div>
    </Section>
  );
};

export default AccordionGrid;
