import React from "react";
import { injectIntl } from "react-intl";
import SocialTeaserWrapper from "./components/SocialTeaserWrapper";
import { P } from "../GlobalStyles/GlobalStyles";
import SocialTeaserContent from "./components/SocialTeaserContent";
import Meta from "./components/Meta";
import ImageWrapper from "./components/ImageWrapper";
import LazyImage from "../LazyImage/LazyImage";
import Link from 'next/link';

const SocialTeaser = ({ bodytext, type, dateTime, image, intl, url }) => {
  const now = new Date();
  const date = new Date(dateTime);

  function createDateString(now, date) {
    const diffTime = Math.abs(now.getTime() - date.getTime());
    const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (days === 1) {
      const hours = Math.round(diffTime / 1000 / 60 / 60);
      const minutes = Math.round(diffTime / 1000 / 60);

      if (minutes < 60) {
        return `${intl.messages["date.before"]} ${minutes} ${minutes > 1
          ? intl.messages["date.minutes.plural"]
          : intl.messages["date.minutes.singular"]
          }`;
      } else if (hours < 24) {
        return `${intl.messages["date.before"]} ${hours} ${hours > 1
          ? intl.messages["date.hours.plural"]
          : intl.messages["date.hours.singular"]
          }`;
      } else {
        return `${intl.messages["date.before"]} ${days} ${days > 1
          ? intl.messages["date.days.plural"]
          : intl.messages["date.days.singular"]
          }`;
      }
    } else {
      return `${intl.messages["date.before"]} ${days} ${days > 1
        ? intl.messages["date.days.plural"]
        : intl.messages["date.days.singular"]
        }`;
    }
  }

  return (
    <SocialTeaserWrapper>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <ImageWrapper>
          <LazyImage
            singleImage
            image={image}
            alt={bodytext}
          />
        </ImageWrapper>

        <SocialTeaserContent>
          <P>{bodytext}</P>
          <Meta>
            <P>{type}</P>
            <P>{createDateString(now, date)}</P>
          </Meta>
        </SocialTeaserContent>
      </a>
    </SocialTeaserWrapper>
  );
};

export default injectIntl(SocialTeaser);
