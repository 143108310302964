import React from "react";
import ReactPaginate from "react-paginate";
import PaginationWrapper from "./components/PaginationWrapper";
import { useRouter } from 'next/router';

const Pagination = ({ next, prev, currentPage, pages }) => {
  const router = useRouter();

  return (
    <PaginationWrapper>
      <ReactPaginate
        forcePage={currentPage - 1}
        previousLabel={"Neuere News"}
        nextLabel={"Ältere News"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pages.length}
        marginPagesDisplayed={4}
        pageRangeDisplayed={5}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        onPageChange={data => {
          router.push(pages[data.selected]);
        }}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
