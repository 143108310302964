import styled from "@emotion/styled";

const ContentWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /* &:after {
        position: absolute;
        content: "";
        height: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0));
    } */
`

export default ContentWrapper;