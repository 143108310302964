import styled from "@emotion/styled";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const LinkWrapper = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(0, 0, 0);
  padding: 0;

  &:hover {
    color: ${theme.colors.primary};
  }

  picture {
    position: relative;
    width: 100%;
  }

  picture:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  picture img {
    position: absolute;
    width: auto;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  span {
    font-size: ${pxToRem(12)};
    font-weight: bold;
    padding-bottom: 10px;
  }
`;

export const LinkTreeContainer = styled.div`
  width: 50%;
  padding: 0 5px;
`;

export default LinkWrapper;
