import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import theme from "@/styles";

const NavLink = styled.a`
  color: #141517;
  opacity: 0.7;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;
  font-size: 1.5rem;
  position: relative;

  svg {
    width: 7px;
    height: 11px;
    margin-left: 5px;
    fill: #141517;
    margin-top: .2rem;
  }

  ${mq.lg(css`
    font-size: 1rem;
    line-height: 1;
  `)}

  ${({ active }) =>
    active &&
    css`
    transition: all 0.2s;
      color: ${theme.colors.primary} !important;
      font-weight: 500 !important;
      opacity: 1;
      svg {
        fill: ${theme.colors.primary};
      }
    `}

  &:hover {
    color: ${theme.colors.primary};
    font-weight: 500;
    opacity: 1;

    svg {
      fill: ${theme.colors.primary};
    }
  }
`;

export default NavLink;
