import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "@/styles";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";
import Container from "../../Container/Container";
import { H4 } from "../../GlobalStyles/GlobalStyles";

const ContactWrapper = styled.div`
  background-color: ${theme.colors.greyLighter};
  margin-top: ${pxToRem(20)};

  ${H4} {
    text-align: center;
  }

  .row {
    padding: 1rem 0;
  }

  ${mq.lg(css`
    ${H4} {
      text-align: left;
    }

    .row {
      padding: 0;
    }
  `)}

  ${Container} {
    flex-direction: row;
  }
`;

export default ContactWrapper;
