import React from "react";
import dynamic from "next/dynamic";
import Section from "../Section/Section";
import SocialTeaser from "../SocialTeaser/SocialTeaser";
import { breakpoints } from "@/lib/mediaQueries";

const Swiper = dynamic(() => import("../Swiper/Swiper"));

const swiperSettings = {
  slidesPerView: 1,
  overflow: "visible",
  breakpointsInverse: true,
  showNavigation: true,
  paginationSettings: {
    color: "#d8d8d8"
  },
  navigationSettings: {
    color: "#000000",
    left: -20,
    right: -20
  },
  breakpoints: {
    [breakpoints.sm]: {
      slidesPerView: 2
    },
    [breakpoints.lg]: {
      slidesPerView: 3
    }
  }
};

const SocialStream = props => {
  return (
    <Section {...props}>
      <Swiper
        width={95}
        left
        items={
          props.items &&
          props.items.sort((a, b) => a - b).filter(item => item.image !== "")
        }
        settings={swiperSettings}
        component={SocialTeaser}
      />
    </Section>
  );
};

export default SocialStream;
