
import React from "react";
import { FormattedMessage } from "react-intl";
import { H3, P } from "../../../GlobalStyles/GlobalStyles";
import Button from "../../../Button/Button";
import NotFoundWrapper from "./components/NotFoundWrapper";
import ButtonGroup from "../../../ButtonGroup/ButtonGroup";

const NotFound = ({ ctaBerlinButton, ctaBerlinLink, ctaKoelnLink, ctaKoelnButton }) => {
	return (
		<NotFoundWrapper>
			<div style={{ position: "relative", zIndex: 10, marginBottom: 20 }}>
				<FormattedMessage
					defaultMessage="You couldn't find anything suitable?"
					id="job.application.notfound.title"
				>
					{message => (
						<H3 style={{ color: "white", textAlign: "left", marginBottom: 10 }}>
							{message}
						</H3>
					)}
				</FormattedMessage>
				<FormattedMessage
					defaultMessage="But you would be a good match at brandung? You could fill a position that is currently not being advertised? Or simply offer more? Then we look forward to receiving your speculative application!"
					id="job.application.notfound.text"
				>
					{message => (
						<P style={{ fontWeight: 300, color: "white" }}>{message}</P>
					)}
				</FormattedMessage>
				<ButtonGroup>
					<FormattedMessage
						defaultMessage="Unsolicited application"
						id="job.application.notfound.button"
					>
						{message => (
							<>
								<Button href={ctaKoelnLink} type="primary">{ctaKoelnButton}</Button>
								<Button href={ctaBerlinLink} type="primary">{ctaBerlinButton}</Button>
							</>
						)}
					</FormattedMessage>
				</ButtonGroup>
			</div>
			<svg
				version="1.1"
				id="Ebene_1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				viewBox="0 0 1451.1 818.7"
				xmlSpace="preserve"
				style={{
					transform: "scaleX(-1)",
					position: "absolute",
					zIndex: 2,
					top: -20,
					bottom: 0,
					right: -40,
					height: "120%",
					opacity: 0.2
				}}
			>
				<g>
					<path
						style={{ fill: "#020203" }}
						d="M736.4,764.8c-2.6-1.6-3-1.9-6-3.8c-4.1-2.7-12.5-8.3-37-24.1l-1.1-0.7
		c-12.1-7.9-18.2-11.8-26.7-18.1c-5.8-4.3-13.3-10.6-26.9-21.9c-10.1-8.4-20.6-17.3-32-27c-7-6-14.1-12-21-18l-2.3-2l-2.3,2
		c-32.8,28.8-64.7,55.3-94.8,78.9c-20.7,16.2-36.6,27.9-56.6,39.8c-9.4,5.6-19.8,11.3-31.9,17.9c-14.3,7.9-49.8,24.1-65.8,30.9h17.6
		c17.4-7.8,40.7-18.8,51.6-24.8c12.1-6.7,22.6-12.4,32.1-18c20.3-12.1,36.4-23.9,57.4-40.2c29.5-23.1,60.8-49,92.9-77.1
		c1,0.8,2,1.7,2.9,2.5c-16.1,16.4-33,32.3-50.2,47.2c-15.5,13.4-32.7,27.2-49.8,39.8c-18.9,14-37.3,26.4-51.7,34.8
		c-8.5,5-15.5,8.7-19.8,10.9c-11.3,5.8-36.4,17.8-53.1,25h17.2c14.6-6.6,30.5-14.4,39-18.7c6.3-3.2,13.3-7.1,20.2-11.1
		c14.6-8.5,33.2-21,52.3-35.2c17.2-12.8,34.6-26.7,50.2-40.2c17.5-15.2,34.7-31.3,51-47.9c3.5,3,7,6,10.5,8.9
		c11.4,9.7,21.9,18.6,32,27c13.7,11.4,21.2,17.7,27.1,22.1c8.6,6.4,14.8,10.4,27,18.4l1.1,0.7c24.4,15.9,32.9,21.4,36.9,24.1
		c3,2,3.5,2.3,6.1,3.9c23.1,14.3,52.5,34.7,76,47.9h14.5c-2.4-1.3-5.5-3-9-4.9C790.6,800.7,760.2,779.5,736.4,764.8z"
					/>
					<path
						style={{ fill: "#020203" }}
						d="M1302,627.7l-2.9-4.1l-136.6,195h8.6l128.2-182.7c2.2,3,4.4,6,6.7,9L1191,818.6h8.4l111-167.8
		c16.5,21,34.7,40.6,54.3,58.6c26.4,24.1,55.4,45.3,86.4,63.1v-8.1c-29.3-17.1-56.7-37.3-81.7-60.1
		C1344.3,681.2,1321.6,655.5,1302,627.7z"
					/>
					<path
						style={{ fill: "#020203" }}
						d="M1296.9,567.1l-2.7-2.3l-210.5,253.8h9.1L1295,574.8c1.1,0.9,2.1,1.8,3.2,2.7l-185,241.2h8.8
		l168.2-218.9c1,1.2,1.9,2.4,2.9,3.7l-155.4,215.2h8.6l151.3-209.5c19.1,23.3,40.6,44.6,64.1,63.4c27.4,22,57.4,40.6,89.3,55.4v-7.7
		c-30.3-14.3-58.8-32.2-84.9-53.1c-26.7-21.4-50.8-46-71.6-73.1l9-12.1c27.9,22.4,58.2,41.3,90.5,56.1c18.4,8.5,37.6,15.7,57,21.4
		v-7.3c-18.5-5.6-36.7-12.5-54.1-20.5C1361,615,1327.3,593.3,1296.9,567.1z"
					/>
					<path
						style={{ fill: "#020203" }}
						d="M602.2,752.2l-1.9-1.9l-2.3,1.4c-12.4,7.5-24.8,14.9-37,22c-3.4,2-6.5,3.8-9.2,5.4
		c-10.7,6.2-17.2,10-25.9,15.7c-7.5,4.9-24.6,15.5-36.8,23.8h12.7c10.6-6.9,22.3-14.1,28-17.9c8.6-5.6,15-9.4,25.6-15.5
		c2.8-1.6,5.8-3.4,9.2-5.4c11.4-6.7,23.1-13.6,34.8-20.6c3.1,3,6.3,6,9.4,9c-11.8,8.7-23.9,17.4-35.9,25.7
		c-7.3,5.1-24.1,18-33.7,24.7h11.9c9.2-6.8,20.2-15.1,25.8-19c12.4-8.6,24.9-17.6,37-26.6c2.8,2.7,5.7,5.5,8.5,8.2
		c7.3,7.1,24.9,24.3,38.1,37.3h10c-13.3-13.1-34.9-34.3-43.2-42.4C618.9,768.2,610.6,760.2,602.2,752.2z"
					/>
					<path
						style={{ fill: "#020203" }}
						d="M691.2,762.7c-10-7.6-18.7-14.1-31.3-24.7c-6.5-5.4-13.2-11-21.9-18.9c-4.9-4.5-8.5-7.9-12-11.2
		c-4.5-4.3-8.7-8.3-15-13.8c-5.9-5.2-12.1-10.3-18.1-15.1l-2.3-1.8l-2.2,2c-15,13.6-30.5,27.3-46,41c-21.8,19.1-25.7,22.2-27.8,23.9
		c-25,19.5-47.8,32.3-57.6,37.7c-3.2,1.8-4.7,2.6-7.5,4.1c-3.4,1.8-8.5,4.5-20.4,10.9c-8.3,4.5-26.7,14.6-40,21.9h14.5
		c11-6.1,22.7-12.5,28.8-15.8c11.9-6.4,17-9.1,20.3-10.9c2.8-1.5,4.4-2.3,7.7-4.2c9.9-5.5,33-18.4,58.4-38.3
		c2.2-1.7,6.2-4.8,28.2-24.1c14.8-13,29.5-26.1,43.8-39c1.6,1.3,3.2,2.6,4.8,3.9c-12.3,13.8-25.4,26.9-39.2,38.9
		c-16.1,14.1-29.9,24.1-36.7,28.8c-11.1,7.7-20.2,13.1-29.8,18.9c-7.5,4.5-12.6,7.3-18.9,10.9c-4.3,2.4-9.2,5.2-16.1,9.1
		c-7.6,4.4-26,14.8-38.3,21.9h14c10.6-6.1,22.1-12.6,27.7-15.8c6.8-3.9,11.7-6.6,16-9.1c6.4-3.6,11.4-6.4,19.1-11
		c9.7-5.8,18.9-11.3,30.2-19.1c6.9-4.8,21-15,37.3-29.2c9.4-8.2,18.5-16.9,27.3-26c2.3,1.6,4.5,3.2,6.8,4.9
		c-10.3,9.5-20.8,18.9-31.6,27.8c-19.7,16.4-29.6,24.6-44.6,33.7c-6.5,4-10.1,5.7-16.1,8.7c-5.1,2.5-12,5.9-23.8,12.2
		c-8.7,4.7-27.8,15.3-41.4,22.9h14.3c11.5-6.4,23.9-13.3,30.4-16.8c11.7-6.3,18.6-9.7,23.6-12.1c5.9-2.9,9.8-4.9,16.6-9
		c15.5-9.4,25.5-17.7,45.4-34.3c7.2-6,14.3-12.1,21.3-18.4c1.5,1.4,3,2.7,4.5,4.1c-10.3,8-20.5,16-30.2,23.8l-2.8,2.3
		c-5.9,4.8-9.5,7.7-15,11.6c-9.2,6.6-17,11.3-26.7,16.8c-13.3,7.5-39.5,21.8-58.9,31.9H475c17.3-9.1,36.5-19.7,47.3-25.8
		c9.9-5.6,17.9-10.5,27.3-17.2c5.7-4.1,9.3-7,15.4-11.9l2.8-2.3c10-8,20.4-16.2,31-24.4c1.1,1.1,2.3,2.2,3.4,3.2
		c5.6,5.4,9.2,9.1,13,12.9c1.5,1.6,3.1,3.2,4.9,5c8.1,8.2,12.5,12.3,16.7,16.2c3.6,3.3,6.9,6.4,12.3,11.8
		c5.5,5.5,20.2,20.5,31.4,32.4h9.6c-11.6-12.3-29.7-30.9-36.1-37.3c-5.4-5.5-8.8-8.6-12.5-12c-4.2-3.9-8.5-7.9-16.5-16
		c-1.8-1.8-3.4-3.4-4.9-5c-3.8-3.9-7.5-7.6-13.2-13.1c-4.2-4-8.4-8-12.7-11.9c2.1-1.9,4.2-3.8,6.2-5.8c3.3,2.6,6.6,5.2,9.7,7.8
		c4.7,3.9,7.1,6.2,19,17.3l3.9,3.6c7.2,6.7,10.9,10.1,13.9,13c4,3.7,6.8,6.3,15.1,14c16.2,15.1,21,19.7,27.9,26.9
		c2.2,2.3,10.4,10.6,18,18.4h9.8c-8.6-8.8-20-20.4-22.7-23.3c-7.4-7.7-13-13-28.1-27.1c-8.3-7.7-11.1-10.4-15.1-14.1
		c-3-2.8-6.7-6.2-13.9-12.9l-3.9-3.6c-12-11.1-14.5-13.5-19.2-17.5c-7-5.9-14.4-11.5-21.8-16.8c2.6-2.8,5.3-5.7,7.8-8.6
		c1.8,1.5,3.6,3.1,5.4,4.7c6.2,5.4,10.4,9.4,14.8,13.6c3.5,3.4,7.2,6.8,12.1,11.3c8.8,8,15.6,13.6,22.1,19.1
		c12.8,10.6,21.5,17.2,31.6,24.9c3,2.3,6.2,4.7,9.5,7.2c11,8.4,33.2,28.3,50,43.1h10.6c-17.2-14.9-43.9-39.1-56.4-48.6
		C697.3,767.4,694.2,765,691.2,762.7z"
					/>
					<path
						style={{ fill: "#020203" }}
						d="M1370.2,601.7c-30.5-15.9-58.8-35.9-84-59.4l7.1-7.3l-16.2-18.9l-181.8,136.3c-0.6,0-1.2,0-1.8,0
		c-86.2,0-172.4-10.1-256.1-29.9c-91.4-21.6-179.8-54.9-262.9-98.8L552,491.3c-2.9-31-5.2-61.9-6.9-91.7c-0.9-16.7-1.4-27.9,0-42.5
		c2.7-29,10.4-50.7,14.7-61c5.9-14,12-23.9,19.8-36.5c8.1-13.2,15.1-22.6,21.8-31.8c10-13.5,20.7-26.9,31.8-39.8l1.8-2.1l-20.8-27.8
		c4.3-4.1,8.8-8,13.5-11.6c15.3-12,32.5-21.5,51.1-28.5l2.9-1.1l-6.2-26.7c20.7-9,42.4-16,65-20.9c25.7,7.1,50.8,16.7,74.5,28.5
		c32.9,16.3,63.6,37,91.2,61.5l2.5,2.2l21.7-21.7l-2.4-2.5c-27.8-29.2-58.6-55.6-91.5-78.5c-19.9-13.8-40.9-26.4-62.4-37.7
		L760.1,0.8l-2.3,0.6C738.4,5.9,719,10,700,13.3c-8.1,1.4-13.6,2.3-18.4,3c-7.2,1.1-12.5,2-21.7,4c-23.9,5.2-41.6,11.2-53.4,15.1
		c-16.1,5.4-30,10.1-47.4,18.1c-10.8,5-36.1,16.8-63.7,38.4c-11.7,9.2-23.9,21.6-48.3,46.3c-18.6,18.9-28.8,29.1-35.5,37.2
		l-37.7-17.3l-1.5,3.2c-5.5,12.2-10.3,24.8-14.2,37.4c-1.1,3.4-3.5,11.5-10.1,41.3c-0.4,2-0.9,3.9-1.3,5.7c-20.9,4.1-42,8-62.9,11.6
		c-13.9,2.4-28,4.8-42,7l-3.4,0.5l0.5,3.4c0.5,3.6,1.4,9.1,2.4,15.9c-3.6,0.7-7.1,1.5-10.6,2.2l-3,0.6l0.2,3.1
		c0.8,10.6,1.7,21,2.7,31l-19.1-1l0.4,4.1c2.3,21,5.1,42.2,8.4,63.1H202l0.1,3.6c0.3,10.5,1.3,21.1,3,31.5
		c1.3,7.9,2.7,13.2,4.5,20.5c0.2,1,0.5,2,0.8,3c-9.5,1.6-19.1,3.4-28.5,5.4c-18.2,3.9-36.5,8.6-54.3,14.1l-3.8,1.1l1.6,3.6
		c1.3,3,2.6,6.1,3.9,9.2c-2.9,0.6-5.7,1.3-8.5,2l-3.8,0.9l1.3,3.7c3.7,10.5,7.4,21.3,11,32c7.1,21,11.4,33.7,16,48.9
		c1.6,5.2,2.8,9.4,3.8,12.9l-54,19l1.3,3.4c5.4,13.6,11.2,27.3,17.4,40.6c-11.3,3.8-22.8,7.4-34.2,10.7c-8.2,2.4-16.6,4.8-24.9,7
		l-3.2,0.8l9.4,46.9c-19.9,2.3-40,4.1-60.1,5.5v7c28.2-2,56.6-4.9,84.3-8.5c27.9-3.6,56.8-7.4,94.3-16.1c8.2-1.9,25.1-6,46.2-12
		c26.3-7.6,45.7-14.4,70.2-23.1c12-4.2,32-11.3,57.1-21c24.8-9.6,62.2-24.2,105.2-45.1c38.8-18.9,77.2-40.6,114.2-64.6
		c83.3,43.9,172,77.2,263.5,98.8c81.9,19.4,166,29.5,250.3,30.1l-112.6,84.4c-10.2-3.6-20.5-7.4-30.7-11.2
		c-38.1-14.2-109.1-40.7-189.1-81.8c-61-31.3-120.9-67.9-178.1-108.7l-1.9-1.4l-2,1.3c-143.9,91.3-298,150.7-458.2,176.5
		c-37.2,6-75.1,10.2-112.8,12.5v7c38.1-2.4,76.4-6.6,113.9-12.6C275,699.4,429.4,640.1,573.5,549c2.7,2,5.5,3.9,8.3,5.8
		c-107.6,72.3-224.9,126-348.6,159.6c-75.6,20.5-153.8,33.4-232.4,38.3v7c79.2-5,158-17.9,234.3-38.6c125.3-34,244-88.5,352.9-162
		c52.5,36.4,107.1,69.3,162.6,97.8c80.3,41.3,151.6,67.9,189.8,82.1c8.7,3.3,17.6,6.5,26.4,9.7l-37,27.7
		c-27.4-12.6-48.6-23-64.1-30.6l-6.5-3.2c-4.1-2-7.8-3.8-11.4-5.6c-14.3-7-25.5-12.5-42.7-20.4c-12.5-5.8-18.7-8.4-23.6-10.5
		c-5-2.1-8.6-3.7-16.4-7.5c-18.8-9.1-40-20.8-59.7-32.8c-13.4-8.2-22.6-14.5-40.9-26.9c-18.9-12.8-30.2-20.6-44.8-31.9
		c-12.9-10-25.6-20.7-37.8-31.8l-2-1.8l-2.3,1.5c-120.4,79.5-251.1,136.6-388.4,169.5c-61.6,14.8-125,24.7-188.5,29.6v7
		c64.1-4.9,128-14.9,190.2-29.8c137.2-32.9,267.9-89.8,388.3-169c11.7,10.6,23.8,20.8,36.2,30.3c14.8,11.4,26.2,19.2,45.2,32.1
		c18.4,12.5,27.6,18.8,41.1,27.1c19.8,12.2,41.3,24,60.3,33.2c7.9,3.8,11.6,5.4,16.6,7.6c4.9,2.1,11,4.7,23.4,10.4
		c17.1,7.8,28.3,13.4,42.5,20.4c3.6,1.8,7.3,3.6,11.4,5.6l6.5,3.2c14.9,7.3,35.1,17.2,60.8,29.1l-16.5,12.4l0.5-0.8
		c-22.2-12.9-44.1-25-65.1-36.1c-2.5-1.3-4.9-2.6-7.4-3.9c-10.1-5.3-20.5-10.7-35.5-19.1c-7.6-4.2-11.8-6.7-15.5-8.8
		c-4.1-2.4-7.7-4.5-14.5-8.2c-10.8-5.9-16.7-8.8-22.9-11.8c-5.7-2.8-11.6-5.7-22.1-11.2c-14.1-7.4-27.8-15.1-40.8-22.9
		c-6.6-3.9-17.7-10.5-31.9-19.9c-9.6-6.3-20.4-13.5-33.8-23.9c-12.3-9.5-24.3-19.9-35.7-30.8l-2-1.9l-2.3,1.4
		c-30.5,18.8-61.4,37.3-91.9,55c-35.7,20.7-52.5,30.4-76.8,42.9c-25.1,12.9-51.1,26.2-86.7,39.8c-36.3,13.9-65.8,21.7-81.6,25.9
		c-11.6,3.1-33.1,8.8-61.7,13.9c-29.5,5.3-53.1,7.7-65.7,9c-10.8,1.1-16.9,1.5-28,2.3c-6,0.4-13.5,0.9-23.9,1.7
		c-20.3,1.5-40.7,3.2-60.6,5v5.3l0.2,1.7c20-1.8,40.5-3.5,60.9-5c10.4-0.8,17.9-1.3,23.9-1.7c11.1-0.8,17.3-1.2,28.2-2.3
		c12.7-1.3,36.5-3.6,66.3-9c28.9-5.2,50.6-11,62.3-14.1c16-4.2,45.7-12.1,82.4-26.1c35.9-13.7,62-27.2,87.3-40.2
		c25.5-13.1,43.8-23.7,77.2-43.1c29.8-17.3,60-35.3,89.8-53.6c1.6,1.5,3.2,3,4.8,4.4c-45.3,33.4-85.5,63.8-119.5,90.4l-0.6,0.5
		c-7.6,6-18.1,14.2-33.2,24.4c-34.6,23.4-64,37.5-83.5,46.7c-7.7,3.7-14.9,6.5-23.9,10.2c-10.5,4.2-23.6,9.4-42,17.8
		c-3.2,1.5-39.1,13.8-59.4,21h21c18.6-6.5,38.9-13.6,41.3-14.7c18.3-8.3,31.3-13.5,41.7-17.7c8.8-3.5,16.4-6.6,24.3-10.3
		c19.7-9.4,49.4-23.5,84.5-47.3c15.3-10.3,25.8-18.6,33.6-24.6l0.6-0.5c30.1-23.5,65-50,104-78.9c1.5,1.4,3,2.7,4.5,4.1
		c-16.4,13.6-32.5,27.6-48,41.5c-8.1,7.3-14.8,13.5-20.7,19c-13.4,12.4-23.9,22.2-41.1,35.8c-11.2,8.9-22.9,17.6-34.8,25.9
		c-14.5,10.1-31,21.5-53.5,32.7c-10.2,4.4-20.5,8.8-30.8,12.9c-6.6,2.7-38.3,13.6-62.7,22.1h21.3c19.7-6.8,39-13.6,44-15.6
		c10.4-4.2,20.8-8.6,31.1-13l0.2-0.1c23-11.4,39.7-23,54.4-33.3c12.1-8.4,23.9-17.2,35.2-26.1c17.4-13.8,28-23.7,41.5-36.2
		c5.9-5.5,12.6-11.7,20.7-19c15.6-14.1,31.9-28.2,48.6-42c4.6,4.1,9.2,8.1,13.8,12c17.1,14.6,30.4,24.9,41.1,33.1
		c16.5,12.7,24.8,19.1,34.2,25.2c3.7,2.4,12.8,7.6,31,18l0.1,0.1c19.5,11.2,27.2,15.4,32.1,18c10.1,5.5,17.5,9.2,23.4,12.2l1.7,0.9
		c17.9,9.1,28.9,15.1,40.5,21.4c10.5,5.7,21.3,11.6,38.4,20.5c14.5,7.6,29.5,15.1,44.6,22.5l1.9,0.9l390.6-292.8l7.8,9.1l-277.3,284
		h9.8l264.9-271.4c1,1,2.1,1.9,3.1,2.9l-238.7,268.4h9.4l234.6-263.8c23.5,20.8,49.5,38.6,77.3,53c26.7,13.9,55,24.6,84.2,31.9v-7.2
		C1423.1,625.4,1395.9,615,1370.2,601.7z M626.1,185.6c-10.6,12.4-20.8,25.2-30.3,38.1c-6.8,9.2-13.9,18.8-22.2,32.3
		c-7.6,12.3-14.1,23-20.2,37.5c-4.5,10.7-12.4,33.1-15.3,63c-1.4,15.1-1,26.5,0,43.5c1.3,24.2,3.1,49.1,5.3,74.1
		c-8.8-24.8-14.6-49.9-17.3-74.7c-0.5-4.3-1.6-15.9-2-30.7c-0.5-19-1-38.5,3.9-63.2c4.3-21.6,10.9-37.7,14.8-47.4
		c3.5-8.6,7.9-19.3,15.8-32.5c9.3-15.7,18.7-26.5,31.6-41.5c6.2-7.2,12.6-14.2,19-20.9L626.1,185.6z M623.4,141
		c-5.7,4.4-11.1,9.2-16.4,14.3c-7.5,7.7-15,15.9-22.2,24.2c-13.2,15.3-22.7,26.3-32.3,42.5c-8.1,13.6-12.6,24.6-16.2,33.5
		c-4,9.9-10.8,26.4-15.2,48.6c-5,25.4-4.5,45.4-4.1,64.8c0.4,15.1,1.5,26.9,2,31.3c2.3,20.9,6.8,42.1,13.3,63.1L510,431.1
		c-2.8-37.2-3.1-74.2-0.9-110.1c1.4-23.1,2.3-34.9,5.9-51.5c9.6-43.2,28.8-74.1,39.4-88.7c26.1-35.9,56.1-54.5,78-68.1
		c11.8-7.3,24-13.8,36.6-19.6l4.5,19.4C655.3,119.7,638.5,129.2,623.4,141z M832.6,64.6c31.7,21.9,61.3,47.2,88.1,75.1L908.4,152
		c-27.5-23.9-57.8-44.2-90.3-60.3c-24.4-12.1-50.2-22-76.6-29.2l-0.8-0.2l-0.8,0.2c-39.9,8.5-77.3,23.5-111.1,44.4
		c-22.4,13.9-53.1,32.9-80,69.9c-10.9,15-30.7,46.9-40.6,91.3c-4,17.8-4.8,30.9-6.1,52.5c-2,32.4-2,65.8,0.1,99.4l-3-4.3l-18.7-43.3
		l-20.8-106.7c5.4-16.3,12-32.3,19.5-47.7c21-42.8,50-81.4,86.1-114.8c20.2-14.1,41.8-26.4,64.2-36.5c27.5-12.5,56.5-21.8,86.3-27.8
		l55.3-11.3C792.3,38.5,813,51.1,832.6,64.6z M285.2,264.2c20-3.5,40.1-7.2,60.1-11.1c-0.6,2.8-1.2,5.4-1.7,7.7
		c-11,2.5-22.1,5-32.8,7.5c-7.9,1.9-14.5,3.4-20.4,4.8c-12.2,2.9-21.8,5.2-35.5,8.2c-2.2,0.5-4.3,0.9-6.5,1.4
		c-0.7-4.7-1.3-8.8-1.8-12.1C259.4,268.6,272.4,266.4,285.2,264.2z M183.3,458.2c9.5-2,19.1-3.8,28.7-5.4c0.4,1.5,0.7,3.1,1.1,4.8
		c0.5,2,0.9,3.8,1.2,5.4c-11.6,2.1-21.6,3.9-29.4,5.4c-23.5,4.3-29.6,5.5-37.1,7c-3.9,0.8-7.8,1.6-11.7,2.5
		c-0.9-2.3-1.9-4.6-2.9-6.9C149.8,466.1,166.6,461.8,183.3,458.2z M84.1,705.3c-5.4,0.7-10.8,1.4-16.3,2l-8.2-41
		c7.3-2,14.7-4,21.9-6.2c11.7-3.5,23.6-7.2,35.2-11.1c7.7,16.1,16,32.1,24.9,47.7C120.7,700.5,102.2,702.9,84.1,705.3z M177.8,689.4
		c-10.2,2.4-19.8,4.3-28.9,6.1c-9.7-17-18.9-34.5-27.2-52.1c-6.2-13.2-12.1-26.8-17.6-40.4l46.6-16.4c0.6,2.4,1.2,4.6,1.7,6.7
		c1.7,6.8,3.3,13.2,7.7,27.6c5.7,18.6,11.9,35.1,15.2,43.9l0.9,2.3c2.6,6.9,5.3,13.9,8,20.8C181.8,688.4,179.6,688.9,177.8,689.4z
		 M191.2,686.1c-2.8-7.1-5.6-14.4-8.3-21.6l-0.9-2.3c-3.3-8.8-9.4-25.1-15.1-43.5c-4.4-14.2-5.9-20.5-7.6-27.2
		c-1.6-6.4-3.2-13-7.4-26.7c-4.6-15.3-8.9-28-16-49.1c-3.2-9.5-6.5-19-9.7-28.4c1.9-0.4,3.8-0.9,5.8-1.3c2.2,5.6,4.3,11.3,6.3,17
		c7.9,22.4,12.3,40.2,13.7,46l0.2,0.7c6.6,26.8,20.8,72.4,42.2,135.7C193.3,685.6,192.2,685.9,191.2,686.1z M223.6,677.4
		c-8.2,2.4-15.8,4.4-22.4,6.2c-21.4-63.3-35.6-109-42.2-135.6l-0.2-0.7c-1.5-5.9-5.9-23.9-13.9-46.6c-1.9-5.4-3.9-10.8-6-16.2
		c3.5-0.8,7-1.5,10.4-2.2c7.5-1.5,13.5-2.7,36.9-7c7.9-1.4,17.9-3.3,29.6-5.4c0.4,2.2,0.9,4.4,1.4,7c0.7,3.8,1.6,8.3,2.9,14.7
		c1.9,9.4,2.8,13.5,11.4,52.4l0.6,2.7c13.6,61.7,14.7,66.2,17,75.1c3.8,14.7,8,29.5,12.4,43.9C249.7,669.5,237.6,673.4,223.6,677.4z
		 M268.2,663.3c-4.4-14.2-8.5-28.8-12.2-43.4c-2.3-8.9-3.3-13.3-17-74.9l-0.6-2.7c-8.6-38.9-9.5-42.9-11.4-52.3
		c-1.3-6.3-2.2-10.9-2.9-14.6c-1.4-7-2.2-11.3-4.1-19.5c-1.4-6.1-2.6-10.7-3.6-14.8c-1.8-7.2-3.1-12.4-4.4-20
		c-1.5-8.9-2.5-18-2.8-26.9h12.4c3.2,20,6.9,42.6,13.6,71.3c1.3,5.8,2.2,9.1,3.5,14.2c1.5,5.9,3.5,14,7.6,30.8
		c4.3,18,6.1,25.8,7.2,30.5c1,4.5,1.4,6.3,2.8,11.6c4.1,15.9,7.2,25.3,10.1,34.4c2.8,8.8,5.5,17.1,8.9,30.5
		c3.4,13.1,6.3,26.6,8.9,40.1C278.6,659.7,273.4,661.5,268.2,663.3z M293.4,654.5c-0.9,0.3-1.8,0.6-2.7,0.9
		c-2.5-13.3-5.4-26.6-8.8-39.5c-3.5-13.6-6.2-22-9-30.9c-2.9-9.1-5.9-18.4-10-34.1c-1.3-5.2-1.8-7-2.8-11.4
		c-1.1-4.7-2.9-12.5-7.2-30.6c-4-16.8-6.1-24.9-7.6-30.9c-1.3-5.1-2.1-8.4-3.4-14.1c-6.9-29.8-10.7-53.1-14-73.8
		c-3.3-20.7-6.2-41.8-8.5-62.7l11.9,0.6c3.8,38.5,8.4,72.5,13.7,101.3l1,5.1c2.1,11.2,6.5,34.5,12,65.8c2.8,15.9,4.5,25.5,5.6,32.5
		c1,5.7,1.6,9.5,2.4,13.5c6.3,34.2,17.2,70,32.5,106.2C296.8,653.3,295,653.9,293.4,654.5z M305.2,650.3
		c-15.1-35.9-25.9-71.3-32.2-105.2c-0.7-3.9-1.4-7.7-2.3-13.4c-1.2-7-2.8-16.6-5.7-32.6c-5.6-31.4-9.9-54.7-12-65.9l-1-5.1
		c-6.9-37-12.5-82.6-16.7-135.5c2.4-0.5,4.8-1,7.2-1.5c7.2,48.6,11.7,80.8,15,104.4c5.2,37.7,7.6,54.9,13.6,83.9
		c7,34.1,14.2,61.9,20,84.2c7.2,27.8,15.1,55.8,23.5,83.2C311.3,648.1,308.2,649.2,305.2,650.3z M350.3,633.5
		c-10.7,4.1-20.4,7.8-29,11c-8.3-27.2-16.1-55-23.3-82.6c-5.8-22.2-13-49.9-20-83.8c-5.9-28.8-8.3-46-13.5-83.5
		c-3.3-23.7-7.8-56.1-15.1-104.9c2.3-0.5,4.6-1,6.9-1.5c13.8-3,23.4-5.3,35.7-8.2c5.9-1.4,12.5-3,20.4-4.8
		c9.7-2.3,19.7-4.5,29.6-6.8c-0.9,4.2-1.5,7.4-1.9,9.8c-5.2,32.8-3.2,59.5-2,73.8c1.3,16.5,3.9,30.1,9.1,57.4
		c4.3,22.6,8.7,46,18.1,75.4c3.9,12.4,8.6,25.3,13.1,36.3c11.6,28.4,26.5,56.1,44.4,82.6C393.7,616.7,368.6,626.4,350.3,633.5z
		 M429.3,600.8c-17.9-26.4-32.9-54-44.4-82.3c-4.4-10.8-9-23.5-12.9-35.7c-9.2-29-13.6-52.2-17.9-74.6c-5.1-27-7.7-40.5-8.9-56.6
		c-1.1-14.1-3.1-40.2,2-72.2c1-6,3.3-16.6,8-37.8c6.5-29.5,8.9-37.4,9.9-40.7c3.4-11.2,7.6-22.4,12.4-33.4l29.7,13.6
		c-12,15.1-29.9,38.8-40.8,72.6c-8.2,25.6-9.3,46.6-10.2,61.9c-1,19.6,0.2,33.8,2,53.5c2.4,26.8,4.7,52.2,13.1,85.5
		c9.2,36.7,21.1,63.5,28.2,79.6c9.8,22.2,21.2,44,33.8,64.8C431.9,599.6,430.6,600.2,429.3,600.8z M455.1,588.6
		c-5.2,2.6-10.4,5-15.5,7.4c-12.6-20.7-24-42.4-33.8-64.6c-7-15.8-18.7-42.3-27.8-78.4c-8.3-32.8-10.5-57.9-12.9-84.5
		c-1.7-19.4-3-33.4-2-52.5c0.8-14.9,1.9-35.4,9.8-60.1c11.4-35.4,30.8-59.5,42.4-73.9c6.5-8.1,16.6-18.3,36.8-38.7
		c24.2-24.5,36.3-36.8,47.7-45.7c27-21.2,51.7-32.7,62.3-37.6c17-7.9,30.8-12.5,46.6-17.9c11.6-3.9,29.1-9.8,52.6-14.9
		c9.1-2,14.2-2.8,21.3-3.9c4.6-0.7,10.3-1.6,18.5-3.1c18.4-3.2,37.2-7.1,55.9-11.5l8.8,12.6l-51.4,10.5
		c-30.2,6.1-59.7,15.6-87.8,28.2c-22.9,10.4-44.9,22.9-65.6,37.3l-0.4,0.3c-36.8,34-66.3,73.4-87.8,117
		c-7.8,15.9-14.6,32.5-20.2,49.4l-0.3,0.9l21.2,109.3L493,419l74.1,106.3C530.8,548.8,493.1,570.1,455.1,588.6z M884.7,810.2
		c-14.3-7.1-28.6-14.3-42.5-21.5c-17-8.9-27.9-14.8-38.3-20.5c-11.7-6.4-22.8-12.4-40.7-21.5l-1.7-0.9c-5.9-3-13.2-6.7-23.2-12.1
		c-2.9-1.6-9.8-5.3-31.9-18l-0.1-0.1c-18.1-10.4-27.2-15.6-30.7-17.8c-9.2-5.9-17.4-12.2-33.8-24.8c-10.6-8.2-23.9-18.4-40.9-32.9
		c-7.6-6.4-15.1-13.1-22.4-19.7c3.6-2.6,7.1-5.3,10.8-8c7.9,7,16,13.8,24.2,20.1c13.5,10.5,24.5,17.7,34.2,24.1
		c14.3,9.5,25.5,16.1,32.1,20.1c13.1,7.8,27,15.6,41.2,23.1c10.6,5.6,16.8,8.6,22.3,11.3c6.1,3,11.9,5.8,22.6,11.7
		c6.8,3.7,10.1,5.6,14.4,8.1c3.7,2.2,8,4.6,15.6,8.9c15.1,8.4,25.5,13.9,35.7,19.2c2.5,1.3,4.9,2.6,7.4,3.9
		c20.2,10.6,41.3,22.3,62.6,34.6L884.7,810.2z"
					/>
				</g>
			</svg>
		</NotFoundWrapper>
	);
};

export default NotFound;
