import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { css } from "@emotion/react";
import Grid from "../Grid/Grid";
import CustomSelect from "../CustomSelect/CustomSelect";
import FlipCard from "../FlipCard/FlipCard";
import Section from "../Section/Section";
import { Subheadline } from "../GlobalStyles/GlobalStyles";
import { pxToRem } from "@/lib/functions";
import { useRouter } from 'next/router';

const ReferenceOverview = ({ items, filters, header, bodytext, intl }) => {

  const router = useRouter();
  const [business, setBusiness] = useState({
    label: intl.messages[`reference.filter.business.name`],
    value: 0
  });
  const [technology, setTechnology] = useState({
    label: intl.messages[`reference.filter.technology.name`],
    value: 0
  });
  const [service, setService] = useState({
    label: intl.messages[`reference.filter.service.name`],
    value: 0
  });

  const onFilterChange = (option, label) => {
    if (label === "business") setBusiness(option);
    if (label === "technology") setTechnology(option);
    if (label === "service") setService(option);
    updateFilterUrl(label, option.label)
  };
  const updateFilterUrl = (label, value) => {
    const url = window.location.href.split('?')[0]
    const urlParams = new URLSearchParams(window.location.search);
    let business = urlParams.get("business") ? urlParams.get("business") : 0;
    let technology = urlParams.get("technology") ? urlParams.get("technology") : 0;
    let service = urlParams.get("service") ? urlParams.get("service") : 0;
    if(label == 'business') business = value
    if(label == 'technology') technology = value
    if(label == 'service') service = value
    let path = `?business=${business}&technology=${technology}&service=${service}`;
    router.replace(url + path, undefined, { shallow: true });
  }

  const filterItems = item => {
    if (item.topReference) {
      const {
        business: itemBusiness,
        technology: itemTechnology,
        service: itemService
      } = item.topReference;
      return (
        (itemBusiness.includes(business.label) || business.value === 0) &&
        (itemTechnology.includes(technology.label) || technology.value === 0) &&
        (itemService.includes(service.label) || service.value === 0)
      );
    } else {
      const {
        business: itemBusiness,
        technology: itemTechnology,
        service: itemService
      } = item;
      if (item.uid === 5) {
        // console.log("business includes", itemBusiness.includes(business.label));
        // console.log("tech includes", itemTechnology.includes(technology.label));
      }

      return (
        (itemBusiness.includes(business.label) || business.value === 0) &&
        (itemTechnology.includes(technology.label) || technology.value === 0) &&
        (itemService.includes(service.label) || service.value === 0)
      );
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const business = urlParams.get("business");
    const technology = urlParams.get("technology");
    const service = urlParams.get("service");

    if (business) {
      const match = filters.business.filter(item => item.label == business
      );
      if (match.length > 0) {
        setBusiness(match[0]);
      }
    }

    if (technology) {
      const match = filters.technology.filter(item => item.label == technology
      );
      if (match.length > 0) {
        setTechnology(match[0]);
      }
    }

    if (service) {

      const cleanService=service.replace(/\/$/, "")
      const match = filters.service.filter(item => item.label == cleanService
      );
      if (match.length > 0) {
        setService(match[0]);
      }
    }
  }, []);

  return (
    <>
      <Section
        as="h1"
        style={{ marginBottom: 0, paddingBottom: pxToRem(20) }}
        header={header}
        bodytext={bodytext}
        overflow
      >
        <Grid columns={3}>
          {Object.keys(filters).map((filter, index) => {
            const label = filter;
            const selectLabel = intl.messages[`reference.filter.${label}.name`];
            const options = filters[filter];

            return (
              <CustomSelect
                key={index}
                value={
                  label === "business"
                    ? business
                    : label === "technology"
                      ? technology
                      : label === "service"
                        ? service
                        : null
                }
                options={[
                  {
                    label: selectLabel,
                    value: 0
                  },
                  ...options
                ]}
                onChange={option => onFilterChange(option, label)}
              />
            );
          })}
        </Grid>
      </Section>
      <Section style={{ zIndex: 0 }} backgroundColor="#f3f3f3" overflow>
        {items && items.filter(item => filterItems(item)).length === 0 && (
          <div>
            <Subheadline
              css={css`
                text-align: center;
              `}
              bold
            >
              <FormattedMessage
                id="reference.filter.empty"
                defaultMessage="No references found"
              >
                {message => message && <>{message}</>}
              </FormattedMessage>
            </Subheadline>
          </div>
        )}
        <Grid resetMargin columns={3}>
          {items &&
            items
              .filter(item => filterItems(item))
              .map((item, index, data) => {
                return (
                  <FlipCard
                    key={index}
                    name={item.name}
                    logo={item.logo}
                    description={item.description}
                    topReference={item.topReference}
                  />
                );
              })}
        </Grid>
      </Section>
    </>
  );
};

export default injectIntl(ReferenceOverview);
