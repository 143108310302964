import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import Toggle from "../../Accordion/components/Toggle";
import AccordionWrapper from "../../Accordion/components/AccordionWrapper";

const ReferenceDetailWrapper = styled.div`
  .project-infos {
    order: 2;
  }
  .project-details {
    padding: 0;
    margin-bottom: 20px;
  }

  ${AccordionWrapper} {
    width: calc(100vw + 20px);
    margin-left: -20px;
  }

  ${Toggle} {
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding: 12px 30px;
    text-transform: uppercase;
    font-weight: 600;
  }

  ${mq.lg(css`
    ${AccordionWrapper} {
      width: 100%;
      margin-left: 0;
    }

    ${Toggle} {
      display: none;
    }

    .project-infos {
      order: 0;
    }
  `)}
`;

export default ReferenceDetailWrapper;
