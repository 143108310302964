import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";

const ItemWrapper = styled.div`
  ${mq.lg(css`
    margin-top: ${pxToRem(60)};
  `)}
`;

export default ItemWrapper;
