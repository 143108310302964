import styled from "@emotion/styled";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const Card = styled.div`
  background: white;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  display: block;
  padding: ${pxToRem(20)};
  margin-bottom: ${pxToRem(10)};
  margin-top: ${pxToRem(10)};
  min-height: ${pxToRem(250)};
  text-align: center;
  width: 100%;

  li {
    &:before {
      content: "\\2022";
      color: ${theme.colors.primary};
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: 1em;
    }
  }
`;

export default Card;
