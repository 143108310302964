import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "@/styles";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";

const ImageWrapper = styled.div`
  margin: 0 auto;
  max-width: ${pxToRem(750)};
  margin-left: 60px;
  height: 100%;
  display: flex;
  align-items: center;

  ${mq.lg(css`
    margin: 0 auto;
    padding: ${pxToRem(40)} ${pxToRem(40)} 0 ${pxToRem(40)};
  `)};
`;

export default ImageWrapper;
