import React, { useState, useEffect } from "react";
import { fetchData } from "@/lib/fetch";
import Media from "react-media";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq, { breakpoints } from "@/lib/mediaQueries";
import FooterWrappper from "./components/FooterWrapper";
import FooterInnerWrapper from "./components/FooterInnerWrapper";
import List from "./components/List/List";
import Title from "./components/Title";
import Logo from "./components/Logo/Logo";
import Contact from "./components/Contact/Contact";
import Container from "../Container/Container";
import Accordion from "../Accordion/Accordion";
import Grid from "../Grid/Grid";
import Rating from "./components/Rating/Rating";
import { injectIntl } from "react-intl";
import Seal from "./components/Seal/Seal";

const FooterRow = styled.div`
  grid-template-areas:
    "1"
    "2"
    "3"
    "4";
  display: grid;

  ${mq.lg(css`
    grid-template-areas: "1 2 3 4";
    grid-auto-columns: 1fr;
    grid-gap: 30px;
  `)}
`;

const Footer = ({ navItems, metaItems, isMobile, intl }) => {
  const [kununuStars, setKununuStars] = useState(4.3);
  const [kununuCount, setKununuCount] = useState(160);

  const filterNav = nav => {
    if (nav) {
      return nav.filter(item => item.navHide === "0");
    }
    return [];
  };

  useEffect(() => {
    fetchData({
      url: "/api/kununu",
      method: "GET",
    }).then((res) => {
      setKununuStars(res.stars);
      setKununuCount(res.count);
    }, (res) => {
      console.log("error fetch", res.message);
    });
  }, []);
  return (
    <FooterWrappper>
      <FooterInnerWrapper>
        <Container>
          <FooterRow>
            <Accordion triggerWidth={breakpoints.lg} arrow>
              <Title>Sitemap</Title>
              {navItems && navItems.length > 0 && (
                <List items={filterNav(navItems[0].subnav)} />
              )}
            </Accordion>
            <Accordion triggerWidth={breakpoints.lg} arrow>
              <Title>{intl.messages["footer.nav.services"]}</Title>
              {navItems && navItems.length > 0 && (
                <List items={filterNav(navItems[1].subnav)} />
              )}
            </Accordion>

            <Media
              defaultMatches={!isMobile}
              query={`(min-width: ${breakpoints.lg}px)`}
              render={() => (
                <div>
                  <Contact
                    location={intl.messages["footer.location.cologne"]}
                    phone={"+49 (0) 221 91 39 20-0"}
                    email={"info(at)brandung.de"}
                    icon={"koeln"}
                  />
                  <Contact
                    location={intl.messages["footer.location.berlin"]}
                    phone={"+49 (0) 30 61 67 146-10"}
                    email={"info(at)brandung.de"}
                    icon={"berlin"}
                  />
                </div>
              )}
            />
            <Media
              defaultMatches={!isMobile}
              query={{ minWidth: breakpoints.lg }}
            >
              <div>
                <Rating
                  ratingType="kununu"
                  rating={kununuStars}
                  count={kununuCount}
                  ratingsText = {intl.messages["footer.nav.reviews"]}
                />
                  <Seal
                  file="greenpeace.svg"></Seal>
                  <Seal
                  file="top-kununu-logo.svg"></Seal>
              </div>
            </Media>
            <Media
              defaultMatches={!isMobile}
              query={{ maxWidth: breakpoints.lg }}
            >
              <Accordion triggerWidth={breakpoints.lg} arrow>
                <Media
                  defaultMatches={isMobile}
                  query={`(max-width: ${breakpoints.lg}px)`}
                  render={() => (
                    <Title>{intl.messages["footer.nav.ratings"]}</Title>
                  )}
                />
                <div>
                  <Rating
                    ratingType="kununu"
                    rating={kununuStars}
                    count={kununuCount}
                    ratingsText = {intl.messages["footer.nav.reviews"]}
                  />
                  <Seal
                  file="greenpeace.svg"></Seal>
                  <Seal
                  file="top-kununu-logo.svg"></Seal>
                </div>
              </Accordion>
            </Media>
          </FooterRow>
        </Container>
      </FooterInnerWrapper>
      <FooterInnerWrapper bottom>
        <Container>
          <Grid resetMargin>
            <Logo />
          </Grid>
          <Grid resetMargin>
            {metaItems && <List horizontal items={filterNav(metaItems)} />}
          </Grid>
        </Container>
      </FooterInnerWrapper>
    </FooterWrappper>
  );
};

export default injectIntl(Footer);
