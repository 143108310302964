import styled from "@emotion/styled";
import css from "@emotion/css";
// import theme from "@/styles";

type BorderProps = {
  border?: Boolean
}


const Toggle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ border }: BorderProps) =>
    border &&
    css`
      border-bottom: 1px solid #efefef;
    `}
`;

export default Toggle;
