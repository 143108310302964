import React from "react";
import { injectIntl } from "react-intl";
import Link from "next/link";
import LogoWrapper from "./components/LogoWrapper";
import StyledLogo from "./components/StyledLogo";

const Logo = ({ intl }) => {
  return (
    <LogoWrapper>
      <Link href={intl.locale === "de" ? "/" : "/en/"}>
        <a>
          <StyledLogo
            alt="Logo brandung GmbH"
            src={"/static/logo_brandung.svg"}
          />
        </a>
      </Link>
    </LogoWrapper>
  );
};

export default injectIntl(Logo);
