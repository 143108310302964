import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";

const VideoWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
`;

export default VideoWrapper;
