import React, { useState, useEffect, useRef } from "react";
import Media from "react-media";
import Typed from "typed.js";
import VideoStageWrapper from "./components/VideoStageWrapper";
import Video from "./components/Video";
import Container from "../Container/Container";
import Content from "./components/Content";
import VideoOverlay from "./components/VideoOverlay";
import Button from "../Button/Button";
import { Subheadline, H1, H2 } from "../GlobalStyles/GlobalStyles";
import VideoWrapper from "./components/VideoWrapper";
import { breakpoints } from "@/lib/mediaQueries";

const VideoStage = ({
  subheader,
  bodytext,
  cta,
  ctaLink,
  ctaType,
  items,
  isMobile,
  video,
  videoWebm,
  mobileVideo,
  mobileVideoWebm
}) => {
  const textRef = useRef(null);
  const [videoIndex, setVideoIndex] = useState(0);

  useEffect(() => {
    if (!textRef.current) {
      return;
    }

    const typed = new Typed(textRef.current, {
      strings: items.map(item => item.header),
      loop: true,
      showCursor: true,
      cursorChar: "|",
      typeSpeed: 100,
      backSpeed: 20,
      backDelay: 3 * 1000,
      preStringTyped: (index, self) => {
        // set next item
        setVideoIndex(index);
      }
    });

    return () => {
      typed.destroy();
    };
  }, [textRef]);

  return (
    <VideoStageWrapper>
      {video && (
        <VideoWrapper>
          <Video autoPlay muted loop playsInline>
            <Media
              defaultMatches={isMobile}
              query={{ maxWidth: breakpoints.lg }}
            >
              {matches =>
                matches ? (
                  <>
                    {mobileVideoWebm && (
                      <source src={mobileVideoWebm} type="video/webm" />
                    )}
                    {/* <source src={mobileVideo} type="video/mp4" /> */}
                  </>
                ) : (
                    <>
                      {/* {videoWebm && <source src={videoWebm} type="video/webm" />} */}
                      <source src={video} type="video/mp4" />
                    </>
                  )
              }
            </Media>
          </Video>
          <VideoOverlay />
        </VideoWrapper>
      )}

      <Container>
        <Content>
          <Subheadline bold>
            {items[videoIndex] ? items[videoIndex].subheader : subheader}
          </Subheadline>
          <H1 as="div">
            <span ref={textRef} />
          </H1>
          <H2>{items[videoIndex] ? items[videoIndex].bodytext : bodytext}</H2>
          {(items[videoIndex].cta || cta) && (
            <Button
              // type={items[videoIndex] ? items[videoIndex].ctaType : ctaType}
              type="secondary"
              href={items[videoIndex] ? items[videoIndex].ctaLink : ctaLink}
            >
              {items[videoIndex] ? items[videoIndex].cta : cta}
            </Button>
          )}
        </Content>
      </Container>
    </VideoStageWrapper>
  );
};

VideoStage.defaultProps = {
  subTitle: "Einfach. Agil. Individuell. Das ist",
  bodytext: "MADE BY brandung",
  cta: "Zu unseren Leistungen",
  ctaType: "primary"
};

export default VideoStage;
