import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import FormWrapper from "./components/FormWrapper";
import Container from "../Container/Container";
import { P, H3 } from "../GlobalStyles/GlobalStyles";
import { useRouter } from "next/router";
import { apiURL } from "@/config";
import NProgress from "nprogress";

const Form = ({
  form,
  html,
  formId,
  formOnly,
  header,
  subheader,
  bodytext,
  intl,
  useRecaptcha,
  id,
  personio = null,
}) => {
  const router = useRouter();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [personioParameter, setPersonioParameter] = useState(personio);

  useEffect(() => {
    setPersonioParameter(personio);
  }, [personio]);

  useEffect(() => {
    if (useRecaptcha) {
      // append recaptcha to head
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://www.google.com/recaptcha/api.js";
      document.getElementsByTagName("head")[0].appendChild(s);
    }
  }, [useRecaptcha]);

  useEffect(() => {
    // close messages after 10 seconds
    if (error) {
      setTimeout(() => {
        setError("");
      }, 10000);
    }

    if (success) {
      setTimeout(() => {
        setSuccess("");
      }, 10000);
    }
  }, [error, success]);

  useEffect(() => {
    /* load and init parsleyjs, load language file */
    import("jquery").then((jquery) => {
      window.jQuery = jquery.default;
      window.$ = jquery.default;

      import("parsleyjs").then((parsley) => {
        let lang = "";
        const languageRegex = /^\/(en)/;
        const langString = languageRegex.exec(window.location.pathname);

        if (langString) {
          lang = langString[1];
        }

        if (!lang) {
          import("parsleyjs/dist/i18n/de.js");
        } else {
          import(`parsleyjs/dist/i18n/${lang}.js`);
        }

        window.Parsley.addValidator("maxFileSize", {
          validateString: function (_value, maxSize, parsleyInstance) {
            if (!window.FormData) {
              alert("Your Browser does not support file uploads");
              return true;
            }
            var files = parsleyInstance.$element[0].files;
            return files.length != 1 || files[0].size <= maxSize * 1024;
          },
          requirementType: "integer",
          messages: {
            en: "Max %s Kb",
            de: "Maximal %s Kb.",
          },
        });

        if (formId) {
          $("#" + formId)
            .parsley()
            .on("field:validated", function () {
              var ok = $(".parsley-error").length === 0;
              $(".bs-callout-info").toggleClass("hidden", !ok);
              $(".bs-callout-warning").toggleClass("hidden", ok);
            });

          $("#" + formId).on("submit", function (e) {
            e.preventDefault();
            var form = $(this);
            var action = form.attr("action");
            var captcha_el = form.find(".g-recaptcha-response")[0];
            var errorContainer = form.find("#captchaError")[0];
            var captcha = captcha_el && captcha_el.value;

            if (window.dataLayer) {
              window.dataLayer.push({
                event: "itemClick",
                itemClick: "NACHRICHT SENDEN",
              });
            }

            form.parsley().validate();
            NProgress.start();

            console.log(captcha_el, errorContainer, captcha_el.value);

            if (!captcha && useRecaptcha) {
              if (errorContainer) {
                errorContainer.innerHTML = `<span style='color: #e42131'>${intl.messages["form.default.message.captchaError"]}</span>`;
              }
            }

            if (
              (form.parsley().isValid() && captcha) ||
              (form.parsley().isValid() && !useRecaptcha)
            ) {
              const formData = new FormData(form[0]);

              if (!captcha && useRecaptcha) {
                // reset captcha error
                errorContainer.innerHTML = "";
              }

              $.ajax({
                type: "POST",
                cache: false,
                processData: false,
                contentType: false,
                url: `https://${apiURL}${action}`,
                data: formData,
                success: function (response) {
                  const res = JSON.parse(response);
                  const message = res && res.message;
                  const redirectPath = res && res.thanksPage;

                  if (redirectPath) {
                    router.push(redirectPath);
                  }

                  if (message === "formComplete" && !redirectPath) {
                    setSuccess(intl.messages["form.default.message.success"]);
                  }

                  if (message === "sandbox applied" && !redirectPath) {
                    setSuccess(intl.messages["form.default.message.success"]);
                  }

                  if (message === "User applied" && !redirectPath) {
                    setSuccess(
                      intl.messages["form.default.message.success.job"]
                    );
                  }

                  if (
                    message === "newsletter registration applied" &&
                    !redirectPath
                  ) {
                    setSuccess(
                      intl.messages["form.default.message.success.newsletter"]
                    );
                  }

                  // reset form and validation
                  form[0].reset();
                  form.parsley().reset();
                  NProgress.done();

                  // add captcha back to form after reset to keep captcha valid
                  if (useRecaptcha) {
                    captcha_el.value = captcha;
                  }
                },
                error: function (error) {
                  if (error.responseText) {
                    const message = JSON.parse(error.responseText).message;
                    const code = JSON.parse(error.responseText).code;
                    if (message === "validationError") {
                      setError(
                        intl.messages["form.default.message.validationError"]
                      );
                    }

                    if (code === "personioError") {
                      setError(
                        intl.messages["form.default.message.personioError"]
                      );
                    }
                  } else {
                    setError(
                      intl.messages["form.default.message.defaultError"]
                    );
                  }
                },
              });
            } else {
            }
          });
        }
      });
    });
  }, []);

  /* init fileupload */
  useEffect(() => {
    const componentSelector = ".custom-fileupload";
    const textSelector = ".form__placeholder";

    const onChange = (e) => {
      let elem = e.target;
      let currentElement = elem.closest(componentSelector);
      let changedText = elem.value.replace(/^.*[\\\/]/, "");
      currentElement.querySelector(textSelector).textContent = changedText;
    };

    if (document.querySelector(componentSelector)) {
      const components = document.querySelectorAll(componentSelector);
      for (let index = 0; index < components.length; index += 1) {
        let compClassList = components[index].classList;
        let compClass = `custom-fileupload-${index}`;
        compClassList.add(compClass);
        components[index].addEventListener("change", onChange);
      }
    }
  }, []);

  // increase / decrease input value on button press
  useEffect(() => {
    const componentSelector = ".quantity-button";
    const inputWrapper = ".form__field-wrapper";
    const upClass = "quantity-button--up";
    const downClass = "quantity-button--down";

    const onClick = (e) => {
      let elem = e.target;
      let input = elem.closest(inputWrapper).querySelector("input");
      if (input && elem.classList.contains(upClass)) {
        input.value++;
      }

      if (input && elem.classList.contains(downClass)) {
        if (input.value > 1) {
          input.value--;
        }
      }
    };

    if (document.querySelector(componentSelector)) {
      const components = document.querySelectorAll(componentSelector);
      for (let index = 0; index < components.length; index += 1) {
        components[index].addEventListener("click", onClick);
      }
    }
  }, []);

  // set Personio campaign param value
  useEffect(() => {
    const personioField = document.getElementById("pc-id");
    if (personioField && personioParameter) {
      personioField.value = personioParameter;
    }
  }, [personioParameter]);

  // init additional fields
  useEffect(() => {
    const componentSelector = ".checkbox";
    const inputWrapper = ".form__field-wrapper";

    const onClick = (e) => {
      let elem = e.target;

      if (elem.dataset && elem.dataset.dependency) {
        const dependency = elem.dataset.dependency;

        const el = document.getElementById(dependency);
        el.closest(inputWrapper).classList.toggle("hidden");
      }
    };

    if (document.querySelector(componentSelector)) {
      const components = document.querySelectorAll(componentSelector);
      for (let index = 0; index < components.length; index += 1) {
        components[index].addEventListener("click", onClick);
      }
    }
  }, []);

  /* init flatpickr */
  useEffect(() => {
    const componentSelector = ".flatpickr";
    if (document.querySelector(componentSelector)) {
      import("flatpickr").then((module) => {
        const flatpickr = module.default;

        import("flatpickr/dist/flatpickr.min.css").then((module) => {
          // create multiple instances
          flatpickr(componentSelector, {
            allowInput: true,
            minDate: new Date(),
            altFormat: "F j, Y",
            dateFormat: "d.m.Y",
          });
        });
      });
    }
  }, []);

  if (formOnly) {
    return (
      <>
        <FormWrapper id={`c${id}`} formOnly>
          <div
            dangerouslySetInnerHTML={{
              __html: form || html,
            }}
          />
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}
        </FormWrapper>
      </>
    );
  } else {
    return (
      <FormWrapper id={`c${id}`}>
        <Container>
          {header && <H3>{header}</H3>}
          {subheader && (
            <P as="div" dangerouslySetInnerHTML={{ __html: subheader }}></P>
          )}
          {bodytext && (
            <P as="div" dangerouslySetInnerHTML={{ __html: bodytext }}></P>
          )}
          <div
            className="row"
            dangerouslySetInnerHTML={{
              __html: form || html,
            }}
          />
          {error && (
            <div
              className="error-message"
              dangerouslySetInnerHTML={{ __html: error }}
            ></div>
          )}
          {success && (
            <div
              dangerouslySetInnerHTML={{ __html: success }}
              className="success-message"
            ></div>
          )}
        </Container>
      </FormWrapper>
    );
  }
};

Form.defaultProps = {
  formOnly: false,
  useRecaptcha: true,
};

export default injectIntl(Form);
