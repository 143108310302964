import React from "react";
import SVG from "react-inlinesvg";
import Link from 'next/link';
import CardWrapper from "./components/CardWrapper";
import CardTitle from "./components/CardTitle";
import CardList from "./components/CardList";
import CardListItem from "./components/CardListItem";
import CardListWrapper from "./components/CardListWrapper";

const JobCard = ({ 
  background,
  fullWidth,
  name,
  inverted,
  items,
  area,
  location,
  entrydate,
  level,
  url,
  jobcta
}) => {
  return (
    <Link key={url} href={url} passHref  >
      <CardWrapper
        fullWidth={fullWidth}
        inverted={inverted}
        items={items}
        background={background}
      >
        <CardTitle dangerouslySetInnerHTML={{ __html: name }} />
        {items && (
          <CardListWrapper>
            <CardList>
              <CardListItem>{area}</CardListItem>
              <CardListItem>{level}</CardListItem>
              <CardListItem>{location}</CardListItem>
              <CardListItem>{entrydate}</CardListItem>
              {fullWidth && (
                <CardListItem arrow>
                  <span>{jobcta || "Zum Angebot"}</span>
                  <SVG src="/static/svg/chevron-right.svg" />
                </CardListItem>
              )}
            </CardList>
          </CardListWrapper>
        )}
      </CardWrapper>
    </Link>
  );
};

JobCard.defaultProps = {
  fullWidth: false,
  items: true
};

export default JobCard;
