import styled from "@emotion/styled";
import theme from "@/styles";

const StyledSelect = styled.a`
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2.5px;
  line-height: 1.5rem;
  margin-left: 0;
  text-transform: uppercase;
  transition: color 0.2s;
  padding-right: 1.5em;
  margin-top: 0;
  padding-top: 0;
  opacity: 0.7;
  color: ${theme.colors.greyDark};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.primary};
  }
  &.is-current{
    color: ${theme.colors.greyLight};

  }

  @media (min-width: 769px) {
    margin-bottom: 0;
  }
`;

export default StyledSelect;
