import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import theme from "@/styles";
import Section from "../Section/Section";
import Grid from "../Grid/Grid";
import Location from "./components/Location/Location";
import Button from "../Button/Button";
import LocationDescription from "./components/Location/components/LocationDescription";
import IconWrapper from "./components/Location/components/IconWrapper";
import Row from "./components/Location/components/Row";

const Locations = props => {
  const [activeLocation, setActiveLocation] = useState(null);

  return (
    <Section {...props}>
      <Row reverse={activeLocation === 2} className="row">
        {props.items.map((item, index) => {
          if (item.uid === activeLocation) {
            return (
              <div key={index} className="col-12 col-md-5 transition">
                <Location key={item.id} {...item} onClick={setActiveLocation} />
              </div>
            );
          } else if (activeLocation === null) {
            return (
              <div key={index} className="col-12 col-md-6 transition">
                <Location key={item.id} {...item} onClick={setActiveLocation} />
              </div>
            );
          } else {
            const item =
              props.items.filter(item => item.uid === activeLocation) &&
              props.items.filter(item => item.uid === activeLocation).length >
              0 &&
              props.items.filter(item => item.uid === activeLocation)[0];
            return (
              <LocationDescription key={index} className="col-12 col-md-6">
                <IconWrapper
                  location={activeLocation}
                  onClick={() => setActiveLocation(null)}
                >
                  <SVG
                    style={{
                      transform: "rotate(45deg)",
                      padding: 10,
                      borderRadius: "50%",
                      background: `${theme.colors.secondary}`,
                      fill: `${theme.colors.white}`
                    }}
                    width="40"
                    src="/static/svg/plus.svg"
                  />
                </IconWrapper>
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                <Grid resetMargin colums={2}>
                  <p>
                    <strong>{item.companyname}</strong>
                    <br />
                    {item.street}
                    <br />
                    {item.zip} {item.city}
                    <br />
                  </p>
                  <p>
                    <strong>Tel.:</strong> {item.phone}
                    <br />
                    <strong>E-Mail.: </strong>
                    <a href={`mailto:${item.email}`}>{item.email}</a>
                    <br />
                  </p>
                </Grid>
                {props.cta && <Button type={props.ctaType}>{props.cta}</Button>}
              </LocationDescription>
            );
          }
        })}
      </Row>
    </Section>
  );
};

export default Locations;
