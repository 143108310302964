import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { CSSProperties } from "react"
import theme from "@/styles";
import mq from "@/lib/mediaQueries";
import {
  pxToRem,
  hexToRgba,
  pickTextColorBasedOnBackground
} from "@/lib/functions";
import Container from "@/components/Container/Container";
import { StyledButton as Button } from "@/components/Button/Button";
import { Gradient } from "@/types/global.types";

type SectionWrapperProps = {
  backgroundColor?: string
  backgroundImage?: string
  backgroundImageWebp?: string
  backgroundPattern?: string
  gradient?: Gradient
  viewOverflow?: string
  style?: CSSProperties
  className?: string
}

const SectionWrapper = styled.section <SectionWrapperProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
  position: relative;
  overflow: ${({ viewOverflow }) => (viewOverflow ? "visible" : "hidden")};

  &.u-reset-padding {
    padding: 0;
    margin: 0;
  }

  &:before,
  &:after {
        width: 100%;
      }

  ${Container}{
    position: relative;
    z-index: 1;
  }

  ${Button}{
    display: flex;
    width: 100%;
  }

  ${mq.sm(css`
    ${Button} {
      /* margin-top: ${pxToRem(40)}; */
      display: inline-flex;
      width: auto;
    }
  `)}

  ${mq.lg(css`
    padding: ${pxToRem(20)} 0;
    margin: ${pxToRem(20)} 0;

    &.u-reset-padding {
      padding: 0;
      margin: 0;
    }
  
  `)}

  ${({ backgroundImage, backgroundImageWebp, backgroundColor, gradient }) =>
    backgroundImage &&
    !backgroundColor &&
    !gradient &&
    css`
      .no-webp & {
        background-image: url(${backgroundImage});
        background-position: bottom left;
        background-repeat: no-repeat;
      }

      .webp & {
        background-image: url(${backgroundImageWebp});
        background-position: bottom left;
        background-repeat: no-repeat;
      }
    `}

  h2 {
    margin-top: 0;
    text-transform: uppercase;
  }

  ${({ backgroundColor, gradient }) =>
    backgroundColor &&
    !gradient &&
    css`
      margin: 0;
      padding: ${pxToRem(60)} 0;
      background-color: ${backgroundColor};
      &,
      h2 {
        color: ${pickTextColorBasedOnBackground(
      backgroundColor,
      theme.colors.white,
      theme.colors.black
    )};
      }
    `}

  ${({ backgroundPattern }) =>
    backgroundPattern &&
    css`
      ${mq.md(css`
        &:before {
          content: "";
          position: absolute;
          background-image: url(/static/svg/${backgroundPattern}.svg);
          z-index: 1;
          height: 50%;
          bottom: 0;
          opacity: 0.2;
        }
      `)}
    `}

  ${({ backgroundImage, backgroundImageWebp, backgroundColor }) =>
    backgroundImage &&
    backgroundColor &&
    css`
      &:after {
        position: absolute;
        content: "";
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;

        .no-webp & {
          background-image: ${`linear-gradient(${hexToRgba(
      backgroundColor,
      0.9
    )},${hexToRgba(backgroundColor, 0.9)})`},
            url(${backgroundImage});
        }

        .webp & {
          background-image: ${`linear-gradient(${hexToRgba(
      backgroundColor,
      0.9
    )},${hexToRgba(backgroundColor, 0.9)})`},
            url(${backgroundImageWebp});
        }
      }
    `}

  ${({ backgroundImage, backgroundImageWebp, gradient }) =>
    backgroundImage &&
    gradient &&
    css`
      &:after {
        position: absolute;
        content: "";
        height: 100%;
        background-repeat: no-repeat;
        background-position: bottom;

        .no-webp &{
          background-image: url(${backgroundImage}),
            ${`linear-gradient(${hexToRgba(gradient.from, 0.73)},${hexToRgba(
      gradient.to,
      1
    )})`};
        }

        .webp &{
          background-image: url(${backgroundImageWebp}),
            ${`linear-gradient(${hexToRgba(gradient.from, 0.73)},${hexToRgba(
      gradient.to,
      1
    )})`};
        }

        ${mq.xl(css`
          background-size: 100%;
        `)}
      }
    `}
`;

export default SectionWrapper;
