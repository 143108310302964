import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import {
  pxToRem,
  hexToRgba,
  pickTextColorBasedOnBackground
} from "@/lib/functions";
import { H2, H3 } from "../../../../GlobalStyles/GlobalStyles";

const fade = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const InfoSlideWrapper = styled.div`
  transition: flex 0.6s ease;
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  height: ${pxToRem(140)};
  justify-content: center;
  cursor: pointer;

  ${mq.lg(css`
    height: ${pxToRem(420)};
    flex: 1;
    align-items: flex-end;
  `)}

  ${H2} {
    font-size: ${pxToRem(20)};
    text-transform: ${({ open }) => open && "none"};
    max-width: 100%;
    font-weight: 300;
    line-height: 120%;
    overflow-wrap: break-word;
    text-align: center;
    word-wrap: break-word;
    transition: opacity 0.6s ease;

    ${mq.lg(`
      font-weight: 700;
      font-size: ${pxToRem(40)};
      text-transform: uppercase;
      max-width: ${({ open }) => (open ? pxToRem(250) : pxToRem(100))};
      text-align: left;
      display: block;
    `)}

      ${({ open }) =>
    open &&
    css`
          animation: ${fade} 1s ease;
        `};
    }

  ${H3} {
    transition: opacity 0.6s ease;
    ${({ open }) =>
    !open &&
    css`
        animation: ${fade} 1s ease;
      `};
    font-size: ${pxToRem(20)};
    font-weight: 300;
    max-width: 100%;
    margin: 0;

    ${mq.lg(`
      margin-bottom: ${pxToRem(40)};
      padding: 20px;
      text-align: center;
      display: ${({ open }) => (open ? "block" : "none")};
    `)}

    color: ${({ gradient }) =>
    pickTextColorBasedOnBackground(gradient, "#ffffff", "#333333")};

    text-align: center;
  }

  background: linear-gradient(
      to bottom,
      ${({ gradient }) => hexToRgba(gradient)},
      ${({ gradient }) => hexToRgba(gradient)}
    ),
    url(${({ backgroundImg }) => backgroundImg});
  background-repeat: no-repeat;
  background-position: center;

  ${props =>
    props.open &&
    css`
      font-size: ${pxToRem(48)};

      background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.41)
        ),
        url(${props.backgroundImg});
      background-repeat: no-repeat;
      background-position: center;
      flex: 1;

      ${mq.lg(`
        flex: 8;
        justify-content: flex-start;
        padding-left: 3%;
      `)}

      padding-left: 0;
      justify-content: center;
    `}
`;

export default InfoSlideWrapper;
