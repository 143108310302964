import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
`;

export default FlipCardInner;
