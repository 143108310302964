import React, { useState, useRef, useEffect } from "react";
import Container from "../Container/Container";
import Media from "react-media";
import { breakpoints } from "@/lib/mediaQueries";
import Sticky from "./components/Sticky";
import StickyContent from "./components/StickyContent";
import { P, H4 } from "../GlobalStyles/GlobalStyles";
import Button from "../Button/Button";
import NonSticky from "./components/NonSticky";

const StickyContact = ({ isMobile, text = 'Interesse ?', buttontext = 'Jetzt bewerben', link = '#' }) => {
  const [isSticky, setIsSticky] = useState(true);
  const [scrollListener, setScrollListener] = useState(false);
  const stickyRef = useRef();

  const handleScroll = () => {
    if (stickyRef && stickyRef.current) {
      const position = stickyRef.current.offsetTop;
      if (position < window.scrollY + window.innerHeight) {
        setIsSticky(false);
      } else {
        setIsSticky(true);
      }
    }
  };

  useEffect(() => {
    if (window && !scrollListener) {
      window.addEventListener("scroll", handleScroll, true);
      setScrollListener(true);
    }
    () => {
      window.removeEventListener("scroll");
    };
  });

  return (<>
    {isSticky && (
      <Sticky>
        <Container>
          <Media
            defaultMatches={isMobile}
            query={{ maxWidth: breakpoints.lg }}
          >
            {matches =>
              matches ? (
                <StickyContent>
                  <P>{text}</P>
                  <Button type="primary" href={link}>{buttontext}</Button>
                </StickyContent>
              ) : (
                <StickyContent className="row">
                  <div className="col-12 col-lg-4">
                    <H4>{text}</H4>
                  </div>
                  <div className="col-12 col-lg-8">
                    <Button type="primary" href={link}>{buttontext}</Button>
                  </div>
                </StickyContent>
              )
            }
          </Media>
        </Container>
      </Sticky>
    )
    }
    <Container>
      <NonSticky ref={stickyRef} className={`row border`}>
        <div className="col-12 col-lg-4">
          <H4>{text}</H4>
        </div>
        <div className="col-12 col-lg-8">
          <div className="btn_wrapper">
            <Button type="primary" href={link}>{buttontext}</Button>
          </div>
        </div>
      </NonSticky>
    </Container>
  </>
  );
}

export default StickyContact;