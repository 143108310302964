import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";
import theme from "@/styles";

const SocialTeaserWrapper = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  margin: 0 ${pxToRem(10)};

  a {
    text-decoration: none;
    color: ${theme.colors.black}
  }
`;

export default SocialTeaserWrapper;
