import React, { useState, useRef, useEffect } from "react";
import SocialButtonsWrapper from "./components/SocialButtonsWrapper";
import { IconWrapperLink, IconWrapperButton } from "./components/IconWrapper";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";

const SocialButtons = ({ items = [], intl }) => {
  const [toggled, setToggled] = useState(false);

  return (
    <SocialButtonsWrapper itemsCount={toggled ? items.length : 0}>
      <div>
        <IconWrapperButton toggled={toggled} onClick={e => setToggled(!toggled)} title={intl.messages["social.share"]}>
          {!toggled ? <><SVG src={`/static/svg/share.svg`} /><span>{intl.messages["social.share"]}</span></> : <><SVG src={`/static/svg/close.svg`} /></>}
        </IconWrapperButton>
        {toggled && items.map((item, index) => (<IconWrapperLink target="_blank" rel="noopener noreferrer" href={item.link} title={item.icon} key={index}>
          <SVG src={`/static/svg/${item.icon}.svg`} />
        </IconWrapperLink>))}
      </div>
    </SocialButtonsWrapper>
  );
};

export default injectIntl(SocialButtons);
