import React from "react";
import LinkWrapper, { LinkTreeContainer } from "./components/LinkWrapper";
import LazyImage from "../LazyImage/LazyImage";
import Section from "../Section/Section";
import { P } from "../GlobalStyles/GlobalStyles";

const LinkTree = ({ items }) => {
  return (
    <Section>
      <div className="row">
        {items &&
          items.map((item, index) => {
            return (
              <LinkTreeContainer key={index}>
                <LinkWrapper target="_blank" rel="noopener noreferrer" href={item.link} title={item.title} key={index}>
                  <LazyImage image={item.image} />
                  <P as="span">{item.title}</P>
                </LinkWrapper>
              </LinkTreeContainer>
            );
          })}
      </div>
    </Section>
  );
};

export default LinkTree;
