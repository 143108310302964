import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { pxToRem } from "@/lib/functions";
import theme from "@/styles";
import mq from "@/lib/mediaQueries";
import NavItem from "./NavItem";
import NavLink from "./NavLink";


const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  flex: auto;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

${({ mobile }) =>
    mobile &&
    css`
      justify-content: space-around;

      ${NavItem} {
        border-bottom: 0;

        ${NavLink} {
          opacity: 1;
          font-weight: 300;
          font-size: 1.25rem;
        }
      }

      > div {
        border-bottom: 1px solid #efefef;
      }
      
      ${NavList}{
        ${NavItem} {
          padding: 10px 0;

          &:last-child {
            padding-bottom: 30px;
          }

          &:first-of-type {
            padding-top: 0;
          }

          ${NavLink} {
            opacity: 1;
            font-weight: 400;
            font-size: 1rem;
          }
        }
      }

      ${NavLink} {
        text-transform: none;
        color: black;
      }
    `}

${mq.lg(css`
    justify-content: center;
    flex-direction: row;
    margin: 1rem 0;
  `)
  }
`;


export const SubListWrapper = styled.div`
  height: 100%;
  top: 0;
  bottom: -1rem;
  left: 40%;
  width: 60vw;
  position: absolute;
  background-color: white;
  flex-direction: column;
  align-items: flex-start;
  visibility: hidden;
  display: none;
  
${mq.lg(css`
    overflow: hidden;
  `)
  }


  &:before {
    position: absolute;
    content: "";
    z-index: 0;
    width: 100%;
    height: 37%;
    bottom: 0;
    left: 13px;
    opacity: 1;
    visibility: visible;
    background-image: url("/static/svg/welle-nav.svg");
    transition: opacity .2s ease;
    transition-delay: 0.6s;
    transform: scale(1.65);
    display:none;
    /* border-bottom: 1px solid ${theme.colors.greyLighter}; */
  }

  span {
    margin-top: 1rem;
    font-size: .8rem;
    padding: ${pxToRem(10)} ${pxToRem(30)} 0;
  }

  ${NavList} {
    margin-top: 0;
    flex-wrap: wrap;
    max-height: calc(100% - 65px);
    align-content: flex-start;
  }

  ${({ active }) =>
    active &&
    css`
    visibility: visible;
    display: flex;
    ${NavList} {
    visibility: visible;
    }

      `}

  ${NavItem} {
    color: black;
    a {
      max-width: unset !important;
    }
  }

`;

export default NavList;
