import React from "react";
import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";
import { Subheadline } from "../GlobalStyles/GlobalStyles";

const CardWrapper = styled.div`
  background-color: #015d74;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(30)} ${pxToRem(20)};
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  }

  p {
    color: white;
    margin-bottom: 0;
  }
`;

const Card = props => {
  return (
    <CardWrapper>
      <Subheadline style={{ textAlign: "center" }} bold>
        {props.header}
      </Subheadline>
    </CardWrapper>
  );
};

export default Card;
