import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import Media from "react-media";
import Grid from "../Grid/Grid";
import CustomSelect from "../CustomSelect/CustomSelect";
import Card from "../JobCard/JobCard";
import NotFound from "./components/NotFound/NotFound";
import SortWrapper from "./components/SortWrapper";
import StyledSVG from "./components/StyledSVG";
import Section from "../Section/Section";
import { breakpoints } from "@/lib/mediaQueries";
import ItemWrapper from "./components/ItemWrapper";
import { setPersonioParameter, getPersonioParameter } from "../../lib/personioHelper";
import { useRouter } from 'next/router';

const JobOverview = (props) => {
  const { header, bodytext, items, filters, intl, ctaBerlinLink = "mailto:career@brandung.de", ctaKoelnLink = "mailto:career@brandung.de", ctaBerlinButton = "Initiativ Bewerben Berlin", ctaKoelnButton = "Initiativ Bewerben Köln" } = props;
  
  const {messages} = intl;
  const defaultOption = { label: messages[`job.filter.default`], value: 0 };

  const [layout, setLayout] = useState("list");
  const [area, setArea] = useState(defaultOption);
  const [level, setLevel] = useState(defaultOption);
  const [location, setLocation] = useState(defaultOption);
  const listLayout = layout === "list";
  const router = useRouter();


  const onFilterChange = (option, label) => {
    if (label === "area") setArea(option);
    if (label === "level") setLevel(option);
    if (label === "location") setLocation(option);
    // console.log(option, "alle locations", "label", location);
  };

  const updateFilterUrl = (label, value) => {
    const url = window.location.href.split('?')[0]
    const urlParams = new URLSearchParams(window.location.search);
    let area = urlParams.get("area") ? urlParams.get("area") : 0;
    let level = urlParams.get("level") ? urlParams.get("level") : 0;
    let location = urlParams.get("location") ? urlParams.get("location") : 0;
    if(label == 'area') area = value
    if(label == 'level') level = value
    if(label == 'location') location = value
    let path = `?area=${area}&level=${level}&location=${location}`;
    router.replace(url + path, undefined, { shallow: true });
  }

  const filterItems = item => {
    let { area: itemArea, level: itemLevel, location: itemLocation } = item;

    location.label === "Köln" && itemLocation.includes("Köln") ? itemLocation = "Köln" : location.label === "Berlin" && itemLocation.includes("Berlin") ? itemLocation = "Berlin" : null;

    return (
      (itemArea === area.label || area.value === 0) &&
      (itemLevel === level.label || level.value === 0) &&
      (itemLocation === location.label || location.value === 0)
    );
  };

  

  useEffect(() => {
    setPersonioParameter();
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const area = urlParams.get("area");
    const level = urlParams.get("level");
    const location = urlParams.get("location");

    if (area) {
      const match = filters.area.filter(item => item.label == area);
      if (match.length > 0) {
        setArea(match[0]);
      }
    }

    if (level) {
      const match = filters.level.filter(
        item => item.label == level
      );
      if (match.length > 0) {
        setLevel(match[0]);
      }
    }

    if (location) {
      const cleanLocation=location.replace(/\/$/, "")
      const match = filters.location.filter(
        item => item.label == cleanLocation
      );
      if (match.length > 0) {
        setLocation(match[0]);
      }
    }
  }, []);

  useEffect(() => {
    setArea(defaultOption);
    setLevel(defaultOption);
    setLocation(defaultOption);
  }, [intl])

  return (
    <Section
      header={header}
      bodytext={bodytext}
    >
      <Media
        query={{ maxWidth: breakpoints.lg - 1 }}
        onChange={matches => (matches ? setLayout("tiles") : setLayout("list"))}
      />
      <Grid columns={4}>
        {Object.keys(filters).map((filter, index) => {
          const label = filter;
          const options = filter && filters[filter];
          return (
            <CustomSelect
              key={index}
              value={
                label === "area"
                  ? area
                  : label === "level"
                    ? level
                    : label === "location"
                      ? location
                      : null
              }
              label={intl.messages[`job.filter.${label}.name`]}
              options={[defaultOption, ...options]}
              onChange={option => onFilterChange(option, label)}
            />
          );
        })}
        <SortWrapper>
          <StyledSVG
            onClick={() => setLayout("list")}
            active={layout === "list"}
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 50 50"
            xmlSpace="preserve"
          >
            <g>
              <rect y="2" width="50" height="4" />
              <rect y="16" width="50" height="4" />
              <rect y="30" width="50" height="4" />
              <rect y="44" width="50" height="4" />
            </g>
          </StyledSVG>
          <StyledSVG
            onClick={() => setLayout("tiles")}
            active={layout === "tiles"}
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 25.945 25.945"
            xmlSpace="preserve"
          >
            <g>
              <path
                style={{ fill: "#010002" }}
                d="M6.333,0.209H0.991C0.443,0.209,0,0.652,0,1.2v5.343c0,0.546,0.443,0.99,0.991,0.99h5.342
			c0.547,0,0.99-0.444,0.99-0.99V1.2C7.323,0.652,6.88,0.209,6.333,0.209z"
              />
              <path
                style={{ fill: "#010002" }}
                d="M15.539,0.209h-5.343c-0.546,0-0.99,0.443-0.99,0.991v5.343c0,0.546,0.444,0.99,0.99,0.99h5.343
			c0.548,0,0.991-0.444,0.991-0.99V1.2C16.53,0.652,16.087,0.209,15.539,0.209z"
              />
              <path
                style={{ fill: "#010002" }}
                d="M24.955,0.209h-5.342c-0.548,0-0.991,0.443-0.991,0.991v5.343c0,0.546,0.443,0.99,0.991,0.99h5.342
			c0.547,0,0.99-0.444,0.99-0.99V1.2C25.945,0.653,25.502,0.209,24.955,0.209z"
              />
              <path
                style={{ fill: "#010002" }}
                d="M6.333,9.206H0.991C0.443,9.207,0,9.649,0,10.196v5.343c0,0.547,0.443,0.99,0.991,0.99h5.342
			c0.547,0,0.99-0.443,0.99-0.99v-5.343C7.323,9.649,6.88,9.206,6.333,9.206z"
              />
              <path
                style={{ fill: "#010002" }}
                d="M15.539,9.206h-5.343c-0.546,0-0.99,0.443-0.99,0.99v5.343c0,0.547,0.444,0.99,0.99,0.99h5.343
			c0.548,0,0.991-0.443,0.991-0.99v-5.343C16.53,9.649,16.087,9.206,15.539,9.206z"
              />
              <path
                style={{ fill: "#010002" }}
                d="M24.955,9.206h-5.342c-0.548,0-0.991,0.443-0.991,0.99v5.343c0,0.547,0.443,0.99,0.991,0.99h5.342
			c0.547,0,0.99-0.443,0.99-0.99v-5.343C25.945,9.649,25.502,9.206,24.955,9.206z"
              />
              <path
                style={{ fill: "#010002" }}
                d="M6.333,18.412H0.991C0.443,18.412,0,18.856,0,19.402v5.343c0,0.547,0.443,0.991,0.991,0.991h5.342
			c0.547,0,0.99-0.444,0.99-0.991v-5.343C7.323,18.856,6.88,18.412,6.333,18.412z"
              />
              <path
                style={{ fill: "#010002" }}
                d="M15.539,18.412h-5.343c-0.546,0-0.99,0.444-0.99,0.99v5.343c0,0.547,0.444,0.991,0.99,0.991h5.343
			c0.548,0,0.991-0.444,0.991-0.991v-5.343C16.53,18.856,16.087,18.412,15.539,18.412z"
              />
              <path
                style={{ fill: "#010002" }}
                d="M24.955,18.412h-5.342c-0.548,0-0.991,0.444-0.991,0.99v5.343c0,0.547,0.443,0.991,0.991,0.991
			h5.342c0.547,0,0.99-0.444,0.99-0.991v-5.343C25.945,18.856,25.502,18.412,24.955,18.412z"
              />
            </g>
          </StyledSVG>
        </SortWrapper>
      </Grid>
      <ItemWrapper>
        <Grid
          noGap={listLayout}
          resetMargin={listLayout}
          columns={listLayout ? 1 : 3}
        >
          {items &&
            items
              .filter(item => filterItems(item))
              .map((item, index) => {
                console.log("Filter Items",items.filter(item => filterItems(item)))
                return (
                  <Card
                    key={index}
                    inverted={listLayout && index % 2 === 0}
                    fullWidth={listLayout || false}
                    {...item}
                    cta={intl.messages[`job.filter.cta`]}
                  />
                );
              })}
        </Grid>
      </ItemWrapper>
      <NotFound ctaBerlinButton={ctaBerlinButton} ctaBerlinLink={ctaBerlinLink} ctaKoelnLink={ctaKoelnLink} ctaKoelnButton={ctaKoelnButton} />
    </Section>
  );
};

export default injectIntl(JobOverview);
