import React from "react";
import { css } from "@emotion/react";
import Section from "../Section/Section";
import mq from "@/lib/mediaQueries";
import { P } from "../GlobalStyles/GlobalStyles";
import Button from "../Button/Button";

const Blocker = (props) => {
  const onButtonClick = () => {
    const formPosition = document.querySelector("form")?.offsetTop;
    window.scrollTo({
      top: formPosition ? formPosition - 350 : 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <Section
      style={{ marginTop: 0, marginBottom: 0 }}
      backgroundImage={props.backgroundImage}
      backgroundColor="#038daf"
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: space-between;

          p {
            font-size: 1.5rem;
            margin: 0;
          }

          ${mq.lg(css`
            flex-direction: row;
            flex-wrap: nowrap;
            button {
              margin: 0 0 0 5rem;
              white-space: nowrap;
            }
          `)}
        `}
      >
        {props.header && <P>{props.header}</P>}
        {props.cta && (
          <Button as="button" onClick={onButtonClick} href={props.ctaLink}>
            {props.cta}
          </Button>
        )}
      </div>
    </Section>
  );
};

export default Blocker;
