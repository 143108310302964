import React from "react";
import ListItem from "./components/ListItem";
import ListWrapper from "./components/ListWrapper";
import ListLink from "./components/ListLink";
import Link from 'next/link'

const List = ({ items, horizontal }) => {
  return (
    <ListWrapper horizontal={horizontal}>
      {items &&
        items.length > 0 &&
        items.map((item, index) => (
          <ListItem key={index}>
            <Link key={item.navUrl} href={item.navUrl} passHref>
              <ListLink>{item.navItem}</ListLink>
            </Link>
          </ListItem>
        ))}
    </ListWrapper>
  );
};

export default List;
