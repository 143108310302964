import React from "react";
import Section from "./Section";

const withSectionHOC = <T extends {colPos: string, id: string}>(BaseComponent: React.ComponentType<T>) => {
  const withSection = (props: T) => {
    const { colPos = "0", id } = props;
    if (parseInt(colPos) === 0) {
      return <Section id={`c${id}`}>
        <BaseComponent {...props} />
      </Section>
    }
    return <BaseComponent {...props} />
  }

  withSection.displayName = 'withSection';

  return withSection;
}


export default withSectionHOC;