import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { pxToRem, hexToRgba } from "@/lib/functions";

type ArrowProps = {
  arrowPosition?: string | number
  arrowColor?: string
}

const Arrow = styled.div <ArrowProps>`
  position: absolute;
  top: 16px;
  left: ${({ arrowPosition }) =>
    arrowPosition
      ? `calc(20px + ${arrowPosition}px - ${pxToRem(20)})`
      : "20px"};
  width: 0;
  height: 0;
  border-left: ${pxToRem(20)} solid transparent;
  border-right: ${pxToRem(20)} solid transparent;
  border-bottom: ${pxToRem(20)} solid ${({ arrowColor }) => arrowColor ? hexToRgba(arrowColor, 0.73) : "#038daf"};
  margin-top: -${pxToRem(40)};
  transition: left 0.4s ease-in-out;

  ${mq.lg(css`
    top: 0;
  `)}
`;

export default Arrow;
