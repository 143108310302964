import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";

const StickyLayerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(10)};
`;

export default StickyLayerBox;
