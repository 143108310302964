import styled from "@emotion/styled";
import theme from "@/styles";

const CustomSelectLabel = styled.div`
  font-size: 0.875rem;
  color: ${theme.colors.greyLight};
  margin-bottom: 10px;
`;

export default CustomSelectLabel;
