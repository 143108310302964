import styled from "@emotion/styled";

const ImageWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        transition: width 1s ease-in-out;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export default ImageWrapper;