import styled from "@emotion/styled";
import { pxToRem } from "@/lib/functions";
import mq from "@/lib/mediaQueries";
import css from "@emotion/css";

const RatingWrapper = styled.div`
  margin: ${pxToRem(20)} 0;
  max-width: ${pxToRem(150)};

  ${mq.lg(css`
    margin: 0 0 ${pxToRem(20)} auto;
    width: ${pxToRem(120)};
  `)}
`;

export default RatingWrapper;
