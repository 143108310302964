import styled from "@emotion/styled";
import { css } from "@emotion/react";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";

const SlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${pxToRem(450)};

  ${({ fullWidth }) => fullWidth && css`
    min-height: ${pxToRem(550)};
  `}

  ${mq.lg(css`
    flex-direction: row;
    flex: 7;
  `)}
`;

export default SlideWrapper;
