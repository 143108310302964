import React from "react";
import { FormattedMessage } from "react-intl";
import theme from "@/styles";
import Section from "../Section/Section";
import Card from "./components/Card";
import { H3, H4, Subheadline, P } from "../GlobalStyles/GlobalStyles";
import Price from "./components/Price";
import { pxToRem } from "@/lib/functions";
import PricingWrapper from "./components/PricingWrapper";

const Pricing = props => {
  const { areas, header, options, contact } = props;
  return (
    <Section header={header}>
      <PricingWrapper>
        <div className="row">
          {areas &&
            areas.map((area, index) => {
              const { items, title } = area;
              return (
                <div key={index} className="col-12 col-lg-6">
                  <H4 as="h3">{title}</H4>
                  <div className="row">
                    {items &&
                      items.map((item, index) => {
                        return (
                          <div key={index} className="col-12 col-md-6">
                            <Card>
                              <Subheadline
                                as="p"
                                style={{ color: theme.colors.secondary }}
                              >
                                {item.title}
                              </Subheadline>
                              <Price as="span">{item.price} €</Price>
                              <div
                                style={{ marginTop: pxToRem(10) }}
                                dangerouslySetInnerHTML={{
                                  __html: item.bodytext
                                }}
                              />
                            </Card>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <FormattedMessage
              id="sandbox.food.options.title"
              defaultMessage="Board Options"
            >
              {message => (
                <>
                  <Subheadline
                    style={{ textAlign: "left", marginTop: pxToRem(20) }}
                    as="h3"
                  >
                    {message}
                  </Subheadline>
                </>
              )}
            </FormattedMessage>

            <ul className="price__list">
              {options &&
                options.map((option, index) => {
                  return (
                    <li key={index}>
                      {option.subheader ? (
                        <>
                          <span>
                            <b>{option.header}</b> <br />
                            {option.subheader}
                          </span>
                          <span className="price">
                            <span className="price__text">{option.price}€</span>{" "}
                            p.P.
                          </span>
                        </>
                      ) : (
                          <>
                            <b>{option.header}</b> <br />
                            <span className="price">
                              <span className="price__text">{option.price}€</span>{" "}
                            p.P.
                          </span>
                          </>
                        )}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <FormattedMessage
              id="sandbox.contact.title"
              defaultMessage="We will be pleased to receive your inquiry"
            >
              {message => (
                <>
                  <Subheadline
                    style={{ textAlign: "left", marginTop: pxToRem(20) }}
                    as="h3"
                  >
                    {message}
                  </Subheadline>
                </>
              )}
            </FormattedMessage>

            <P as="div" dangerouslySetInnerHTML={{ __html: contact }}></P>
          </div>
        </div>
      </PricingWrapper>
    </Section>
  );
};

export default Pricing;
