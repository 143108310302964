import React, { Component } from "react";
import { Gmaps, Marker } from "react-gmaps";
import { pxToRem } from "@/lib/functions";
import Section from "../Section/Section";

const coords = {
  lat: 50.945592,
  lng: 6.922011
};

const params = { v: "3.exp", key: "AIzaSyDppBchurrOLPhNWo5qG30ck8Ug_yetEz0" };

class CustomGmaps extends Component {
  onMapCreated(map) {
    map.setOptions({
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true
    });
  }

  onDragEnd(e) { }

  onCloseClick() { }

  onClick(e) { }

  render() {
    return (
      <Section
        header={this.props.header}
        bodytext={this.props.item.description}
        cta={this.props.cta}
        ctaType={this.props.ctaType}
        ctaLink={this.props.ctaLink}
      >
        <Gmaps
          width={"100%"}
          height={pxToRem(500)}
          lat={parseFloat(this.props.item.lat)}
          lng={parseFloat(this.props.item.lng)}
          zoom={16}
          params={params}
          onMapCreated={this.onMapCreated}
        >
          <Marker
            lat={parseFloat(this.props.item.lat)}
            lng={parseFloat(this.props.item.lng)}
            icon={"/static/img/gmaps-marker.png"}
            draggable={false}
            onDragEnd={this.onDragEnd}
          />
        </Gmaps>
        {this.props.description && (
          <div
            style={{ marginTop: pxToRem(20) }}
            dangerouslySetInnerHTML={{ __html: this.props.description }}
          />
        )}
      </Section>
    );
  }
}

export default CustomGmaps;
