import styled from "@emotion/styled";
import theme from "@/styles";
import { pxToRem } from "@/lib/functions";

const CustomSelectWrapper = styled.div`
  .custom-select {
    &__control {
      border-radius: 0;
      border: 1px solid #d4d4d4;
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding: ${pxToRem(18)} ${pxToRem(15)};
    }

    &__single-value,
    &__placeholder {
      color: black;
      font-weight: 600;
      text-transform: uppercase;
    }

    &__placeholder {
      opacity: 0.7;
    }

    &__dropdown-indicator svg {
      fill: black;
      width: ${pxToRem(20)};
      height: ${pxToRem(20)};
    }
    
    &__menu {
      position: absolute;
      z-index: 999;
    }

    &__option--is-selected,
    &__option--is-focused {
      background: ${theme.colors.secondary};
      color: ${theme.colors.white};
    }
  }
`;

export default CustomSelectWrapper;
