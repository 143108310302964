import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "@/styles";
import mq from "@/lib/mediaQueries";
import { pxToRem } from "@/lib/functions";

const JobDetailWrapper = styled.div`
  width: 100%;

  a {
    margin-top: 0;
  }

  .row {
    padding: ${pxToRem(35)} 0;

    &.border {
      border-top: 1px solid #d4d4d4;
    }
  }

  ul {
    padding-left: 5px;
    margin-top: 0;

    li {
      line-height: 1.6;
      list-style: none;
      padding: 10px;

      &:first-of-type {
        padding-top: 5px;
      }

      &:before {
        content: "\\2022";
        color: ${theme.colors.primary};
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  h4 {
    text-align: center;
    margin-bottom: 10px;
  }

  .btn_wrapper {
    text-align: center;
  }

  ${mq.lg(css`
    .btn_wrapper {
      text-align: left;
    }
    h4 {
      text-align: left;
      margin-bottom: 10px;
    }
  `)}

  ${mq.xxl(css`
    max-width: 85%;
  `)}
`;

export default JobDetailWrapper;
