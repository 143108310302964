import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import {
  LazyImageProps,
  ImageType,
  ImageLayoutType,
} from "./LazyImageProps.types";

const LazyImage: React.FC<LazyImageProps> = ({
  singleImage,
  image,
  alt,
  lazyload,
  blur,
  placeholder,
  isSVG,
  width,
  height,
  autoSize = true,
  style,
  resetKey,
  layout = "responsive",
  objectFit = layout === "fill" ? "cover" : undefined,
  ...rest
}) => {
  if (!image) {
    return null;
  }

  if (typeof image === "string") {
    const { hostname } = new URL(image);

    if (
      hostname !== "stage.be.brandung.de" &&
      hostname !== "typo3.brandung.de"
    ) {
      return (
        <img
          key={resetKey ? resetKey : ""}
          className="lazyload"
          src={image?.replace(/([^:]\/)\/+/g, "$1")}
          alt={alt ? alt : ""}
        />
      );
    }
  }

  if (singleImage) {
    return (
      <Image
        key={resetKey ? resetKey : ""}
        className="lazyload"
        src={(image as ImageType)?.image?.replace(/([^:]\/)\/+/g, "$1") as any}
        alt={alt ? alt : ""}
        width={
          layout !== "fill" ? (image as ImageType).width || width : undefined
        }
        height={
          layout !== "fill" ? (image as ImageType).height || height : undefined
        }
        layout={layout as ImageLayoutType}
        objectFit="cover"
      />
    );
  }

  if (isSVG && typeof image == "string") {
    return (
      <img
        key={resetKey ? resetKey : ""}
        className="lazyload"
        style={style}
        data-src={image?.replace(/([^:]\/)\/+/g, "$1")}
        alt={alt ? alt : ""}
        width={width}
        height={height}
      />
    );
  }
  return (
    <Image
      quality={90}
      placeholder={(image as ImageType).placeholder ? "blur" : undefined}
      blurDataURL={(image as ImageType).placeholder as any}
      src={(image as ImageType)?.image?.replace(/([^:]\/)\/+/g, "$1") as any}
      alt={(image as ImageType).title}
      width={
        layout !== "fill" ? (image as ImageType).width || width : undefined
      }
      height={
        layout !== "fill" ? (image as ImageType).height || height : undefined
      }
      layout={layout as ImageLayoutType}
      objectFit={objectFit}
    />
  );
};

LazyImage.defaultProps = {
  singleImage: false,
  blur: false,
  resetKey: "",
  autoSize: true,
};

export default LazyImage;
