const queryString = require("query-string");

export const setPersonioParameter = () => {
  const personioParameter = hasPersonioParameter();
  if (personioParameter) {
    sessionStorage.setItem('_pc', personioParameter);
    return personioParameter;
  }
  return null;
}

export const getPersonioParameter = async () => {
  if (sessionStorage && sessionStorage.getItem('_pc')) {
    return await sessionStorage.getItem('_pc');
  }
  return null;
}

export const hasPersonioParameter = () => {
  const params = queryString.parse(location.search);
  if (Object.prototype.hasOwnProperty.call(params, '_pc') && validatePersonioParameter(params._pc)) {
    return params._pc;
  }
  return false;
}

const validatePersonioParameter = (param) => {
  const regEx = new RegExp("^\s*-?[0-9]{1,10}\s*$");
  return regEx.test(param);
}